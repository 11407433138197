import "./chart.scss";
import { useEffect, useState, useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import {
  ref,
  query,
  orderByChild,
  equalTo,
  onValue,
  limitToLast,
  push
} from "firebase/database";
import { database } from "../../firebase";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import mapIconUrl from "../../Images/mapIcon.png";
import warningIconUrl from "../../Images/warningIcon.png";
import redWatchIconUrl from "../../Images/1.png";
import orangeWatchIconUrl from "../../Images/2.png";
import greenIconUrl from "../../Images/3.png";
import cancelledIconUrl from "../../Images/4.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";


// Helper component to change map view
const ChangeMapView = ({ coords, bounds }) => {
  const map = useMap();

  const [mapInitialized, setMapInitialized] = useState(false); // Track if map adjustment has been done

  useEffect(() => {
    if (coords) {
      map.setView(coords, 15); // Set zoom level to 15 for the selected marker
    } else if (bounds) {
      map.fitBounds(bounds); // Fit bounds for all markers
    }
  }, [coords, bounds, map]);

  return null;
};

const LocationWatchesMap = ({ companyId, selectedWatch }) => {
  const [data, setData] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedCoords, setSelectedCoords] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [guidelines, setGuidelines] = useState(null); // Store guidelines based on alarmCode
  const [latestNotes, setLatestNotes] = useState([]); // Store the latest notes for the location


  const [note, setNote] = useState("");

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchWatches = async () => {
      try {
        const watchRef = ref(database, "Watches");
        const q = query(
          watchRef,
          orderByChild("locationId"),
          equalTo(companyId)
        );
  
        onValue(q, (snapshot) => {
          let list = [];
          snapshot.forEach((childSnapshot) => {
            const watch = { id: childSnapshot.key, ...childSnapshot.val() };
            list.push(watch);
          });
  
          setData(list);
  
          if (list.length > 0) {
            // Extract positions for the watches
            const positions = list
              .filter(
                (watch) =>
                  typeof watch.watchLatitude === "number" &&
                  typeof watch.watchLongitude === "number"
              )
              .map((watch) => [watch.watchLatitude, watch.watchLongitude]);
  
            setPositions(positions);
  
            // Calculate bounds if positions are available
            if (positions.length > 0) {
              const newBounds = L.latLngBounds(positions);
              setBounds(newBounds);
            }
          } else {
            setBounds(null); // Reset bounds if no watches found
          }
        });
      } catch (error) {
        console.error("Error fetching watches:", error);
      }
    };
  
    if (companyId) {
      fetchWatches();
    }
  }, [companyId]);
  

  // Fetch guidelines and notes when the selected watch changes

  const [locationData, setLocationData] = useState({});

  const fetchLocationData = (locationId) => {
    const locationRef = ref(database, `locations/${companyId}/guidelines`);
    onValue(locationRef, (snapshot) => {
      if (snapshot.exists()) {
        setLocationData((prev) => ({
          ...prev,
          [companyId]: snapshot.val(),
        }));
      }
    });

    // Fetch notes
    const notesRef = ref(database, `locations/${companyId}/notes`);
    onValue(notesRef, (snapshot) => {
      let notesList = [];
      snapshot.forEach((childSnapshot) => {
        notesList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      // Store the latest 3 notes
      setLocationData((prev) => ({
        ...prev,
        [locationId]: {
          ...prev[companyId],
          latestNotes: notesList.slice(-3).reverse(),
        },
      }));
    });
  };

  useEffect(() => {
    data.forEach((watch) => {
      fetchLocationData(watch.locationId);
    });
  }, [data]);

  useEffect(() => {
    if (selectedWatch) {
      const { locationId, alarmCode } = selectedWatch;

      // Fetch location guidelines based on alarmCode
      const locationRef = ref(database, `locations/${locationId}/guidelines`);
      onValue(locationRef, (locationSnapshot) => {
        const locationData = locationSnapshot.val();
        if (locationData && locationData[alarmCode]) {
          const instruction =
            locationData[alarmCode]?.instructions ||
            "No specific instructions.";
          setGuidelines(instruction);
        } else {
          console.error(
            "Guidelines not found for this location or alarm code."
          );
        }
      });

      // Fetch the latest 3 notes for the given locationId
      const notesRef = ref(database, `locations/${locationId}/notes`);
      const latestNotesQuery = query(
        notesRef,
        orderByChild("timestamp"),
        limitToLast(3)
      );
      onValue(latestNotesQuery, (notesSnapshot) => {
        const notesData = notesSnapshot.val();
        if (notesData) {
          const notesArray = Object.keys(notesData).map((key) => ({
            id: key,
            ...notesData[key],
          }));
          // Reverse the array to get the most recent notes first
          setLatestNotes(notesArray.reverse());
        } else {
          console.log("No notes found.");
        }
      });
    }
  }, [selectedWatch]);

  const handleSendNote = async (watch) => {
    if (!note.trim()) {
      alert("Please enter a note.");
      return;
    }

    try {
      const noteRef = ref(database, `locations/${companyId}/notes`);
      await push(noteRef, {
        note,
        timestamp: new Date().toISOString(),
        createdBy: currentUser.email,
      });

      setNote(""); // Clear the note input after sending
      alert("Note added successfully!");
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };


  const mapIcon = L.icon({
    iconUrl: mapIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const warningIcon = L.icon({
    iconUrl: warningIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const getIcon = (alarmCode) => {
    const iconUrl =
      {
        C: cancelledIconUrl,
        G: greenIconUrl,
        O: orangeWatchIconUrl,
        R: redWatchIconUrl,
      }[alarmCode] || ""; // Default to empty string if alarmCode doesn't match

    return L.icon({
      iconUrl,
      iconSize: [45, 45], // Adjust icon size if necessary
      iconAnchor: [22.5, 45], // Adjust anchor if necessary
      popupAnchor: [0, -45], // Adjust popup anchor if necessary
    });
  };

  const handleWatchClick = (watchId) => {
    navigate(`/watches/${watchId}`); // Navigate to the watch details page
  };

  return (
    <div className="chart">
      <h3 style={{ fontWeight: "400" }}>Watch Locations</h3>
      {data.length === 0 ? (
        <p>No watches to display</p> // Display message when no watches are found
      ) : (
        <MapContainer
          center={selectedCoords || [0, 0]} // Default to selectedCoords or [0, 0]
          zoom={selectedCoords ? 15 : 2} // Default zoom for selected or global view
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {data.map((watch) => {
            if (
              typeof watch.watchLatitude !== "number" ||
              typeof watch.watchLongitude !== "number"
            ) {
              console.warn(`Invalid coordinates for watch ID ${watch.id}`);
              return null; // Skip rendering for invalid coordinates
            }

            const icon =
              watch.alarmCode && watch.alarmCode !== "C"
                ? warningIcon
                : mapIcon;

            return (
              <Marker
                key={watch.id}
                position={[watch.watchLatitude, watch.watchLongitude]}
                icon={getIcon(watch.alarmCode)}
              >
                <Popup>
                  <b>{watch.displayName}</b> <br />
                  WatchId:{" "}
                  <a
                    onClick={() => handleWatchClick(watch.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {watch.id}
                  </a>{" "}
                  <br />
                  Alarm Code: {watch.alarmCode} <br />
                  Company: {watch.companyName} <br />
                  Location: {watch.locationName} <br />
                  Latitude: {watch.watchLatitude} <br />
                  Longitude: {watch.watchLongitude} <br />
                  <br />
                  <strong>Location Guidelines:</strong>
                  <p>
                    {locationData[watch.locationId]?.guidelines ||
                      "No guidelines available"}
                  </p>
                  <br />
                  <div>
                    <strong>Send a Note:</strong>
                    <textarea
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="Enter your note"
                      rows="3"
                      style={{ width: "100%" }}
                    />
                    <button
                      onClick={() => handleSendNote(watch)}
                      style={{ marginTop: "10px", background: "black",
                        cursor: "pointer",
                        color: "white",  }}
                    >
                      Send Note
                    </button>
                    <br />
                    <br />
                    <div
                    style={{
                      maxHeight: "100px",
                      overflowY: "auto",
                    }}
                  >
                    <strong>Recent Notes:</strong>
                    {locationData[watch.locationId]?.latestNotes?.length ? (
                      locationData[watch.locationId]?.latestNotes.map(
                        (note) => (
                          <div
                            key={note.id}
                            style={{
                              padding: "1px",
                              border: "0.5px solid black",
                              borderRight: "none",
                              borderLeft: "none",
                              borderBottom: "none",
                            }}
                          >
                            <p>{note.note}</p>
                            <small>
                              By: {note.createdBy} at{" "}
                              {new Date(note.timestamp).toLocaleString()}
                            </small>
                          </div>
                        )
                      )
                    ) : (
                      <p>No notes available</p>
                    )}
                  </div>
                  </div>
                </Popup>
              </Marker>
            );
          })}
          <ChangeMapView coords={selectedCoords} bounds={bounds} />{" "}
          {/* Center map based on coordinates */}
        </MapContainer>
      )}
    </div>
  );
};

export default LocationWatchesMap;
