import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import BillingDataTable from "../../components/datatable/BillingDataTable";

const BillingList = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <BillingDataTable/>
      </div>
    </div>
  );
};

export default BillingList;
