import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { watchColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { ref, onValue, remove, update, push } from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
} from "@mui/material";
import { getToken } from "firebase/messaging";
import { messaging } from "../../firebase"; // Adjust the import based on your file structure

const WatchDatatable = () => {
  const [data, setData] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  useEffect(() => {
    const companyRef = ref(database, "Companies");
    const locationRef = ref(database, "locations");

    // Fetch companies
    const unsubCompanies = onValue(companyRef, (snapshot) => {
      let companyList = [];
      snapshot.forEach((childSnapshot) => {
        companyList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setCompanies(companyList);
    });

    // Fetch locations
    const unsubLocations = onValue(locationRef, (snapshot) => {
      let locationList = [];
      snapshot.forEach((childSnapshot) => {
        locationList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setLocations(locationList);
    });

    return () => {
      unsubCompanies();
      unsubLocations();
    };
  }, []);

  useEffect(() => {
    // Set selectedCompany based on user role
    if (currentUser) {
      if (currentUser.role === 2 || currentUser.role === 3) {
        setSelectedCompany(currentUser.companyId);
        fetchWatches(currentUser.companyId);
      } else if (currentUser.role === 1) {
        fetchAllWatches();
      }
    }
  }, [currentUser]);

  const fetchWatches = (companyId) => {
    const watchRef = ref(database, "Watches");
    onValue(watchRef, (snapshot) => {
      let list = [];
      snapshot.forEach((childSnapshot) => {
        const watch = { id: childSnapshot.key, ...childSnapshot.val() };
        // Fetch watches only matching the companyId
        if (watch.companyId === companyId) {
          list.push(watch);
        }
      });

      // Sort the list: watches without companyId will be at the bottom
      list.sort((a, b) => {
        const hasCompanyIdA = a.companyId !== undefined && a.companyId !== "";
        const hasCompanyIdB = b.companyId !== undefined && b.companyId !== "";

        if (!hasCompanyIdA && hasCompanyIdB) {
          return 1; // Move a (without companyId) to the bottom
        }
        if (hasCompanyIdA && !hasCompanyIdB) {
          return -1; // Move b (without companyId) to the bottom
        }
        return 0; // Keep the order the same if both have or don't have companyId
      });

      setData(list);
    });
  };

  const fetchAllWatches = () => {
    const watchRef = ref(database, "Watches");
    onValue(watchRef, (snapshot) => {
      let list = [];
      snapshot.forEach((childSnapshot) => {
        const watch = { id: childSnapshot.key, ...childSnapshot.val() };
        list.push(watch);
      });

      // Sort the list: watches without companyId will be at the bottom
      list.sort((a, b) => {
        const hasCompanyIdA = a.companyId !== undefined && a.companyId !== "";
        const hasCompanyIdB = b.companyId !== undefined && b.companyId !== "";

        if (!hasCompanyIdA && hasCompanyIdB) {
          return 1; // Move a (without companyId) to the bottom
        }
        if (hasCompanyIdA && !hasCompanyIdB) {
          return -1; // Move b (without companyId) to the bottom
        }
        return 0; // Keep the order the same if both have or don't have companyId
      });

      setData(list);
    });
  };

  const handleDelete = async (id) => {
    try {
      const watchRef = ref(database, `Watches/${id}`);
      await remove(watchRef);
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  const alarmCodeButtons = {
    C: "Cancel",
    G: "Green",
    O: "Orange",
    R: "Red",
  };

  const alarmCodeStyles = {
    C: { color: "#939196", label: "Idle" },
    G: { color: "#185519", label: "Green" },
    O: { color: "#CD5C08", label: "Orange" },
    R: { color: "#7D0A0A", label: "Red" },
  };

  const getDeviceInfo = async () => {
    const userAgent = navigator.userAgent;
    const isPC = /Windows|Mac|Linux/.test(userAgent);

    // Check network connectivity
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    let connectivity = "Unknown";

    if (connection) {
      if (connection.type === "wifi") {
        connectivity = "Wi-Fi";
      } else if (connection.type) {
        connectivity = connection.type; // It could return 'cellular', 'ethernet', 'none', etc.
      } else {
        // Fallback to effectiveType
        connectivity =
          connection.effectiveType === "4g"
            ? "Wi-Fi (Estimate)"
            : connection.effectiveType;
      }
    }

    // Browser detection logic without async/await
    const detectBrowser = () => {
      const ua = navigator.userAgent;

      if (ua.includes("Firefox")) return "Firefox";
      if (ua.includes("Edg")) return "Edge";
      if (ua.includes("Chrome") && !ua.includes("Edg")) return "Chrome";
      if (
        ua.includes("Safari") &&
        !ua.includes("Chrome") &&
        !ua.includes("Edg")
      )
        return "Safari";
      if (ua.includes("OPR") || ua.includes("Opera")) return "Opera";
      if (ua.includes("MSIE") || ua.includes("Trident"))
        return "Internet Explorer";
      return "Unknown";
    };

    let browser = detectBrowser();

    // Check if it's Brave asynchronously
    if (navigator.brave) {
      const isBrave = await navigator.brave.isBrave();
      if (isBrave) browser = "Brave";
    }

    const deviceBrand = /Mobile/.test(userAgent) ? "Mobile Device" : "PC";
    let batteryLevel = "(PC)";

    // Handle battery level for mobile devices
    if (!isPC && navigator.getBattery) {
      const battery = await navigator.getBattery();
      batteryLevel = `${Math.round(battery.level * 100)}%`;
    }

    return {
      connectivityMode: connectivity,
      deviceModel: deviceBrand,
      browser,
      batteryLevel,
    };
  };

  const logUpdate = async (watchId, logDetails, locationId) => {
    try {
      const deviceInfo = await getDeviceInfo();

      const locationRef = ref(database, `locations/${locationId}`);
      const locationSnapshot = await new Promise((resolve, reject) => {
        onValue(
          locationRef,
          (snapshot) => {
            resolve(snapshot.val());
          },
          {
            onlyOnce: true,
          }
        );
      });

      if (!locationSnapshot) {
        console.error(`Location with ID ${locationId} not found.`);
        return;
      }

      const { locationName, locationCode, companyName, latitude, longitude } =
        locationSnapshot;

      const extendedLogDetails = {
        ...logDetails,
        locationId,
        locationName: locationName || "Unknown",
        locationCode: locationCode || "Unknown",
        companyName: companyName || "Unknown",
        locationLatitude: latitude || "Unknown",
        locationLongitude: longitude || "Unknown",
        deviceInfo, // Include device information
        companyId: currentUser.companyId, // Add currentUser's companyId to the logs
      };

      // Log the alarm details inside the specific watch's log entry
      const alarmLogRef = ref(database, `Watches/${watchId}/logs/alarm`);
      await push(alarmLogRef, {
        ...extendedLogDetails, // Include all the details in the alarm log
      });

      // Log globally in the "logs" table under "logs/alarm"
      const globalLogRef = ref(database, `logs/alarm`);
      const globalLog = {
        ...extendedLogDetails,
        watchId, // Add the watchId to the global log for tracking
      };
      await push(globalLogRef, globalLog);
    } catch (error) {
      console.error("Error logging update:", error);
    }
  };

  const handleAlarmCodeChange = async (
    watchId,
    code,
    groupCode,
    companyId,
    locationId
  ) => {
    try {
      const watchesRef = ref(database, "Watches");

      // Fetch the snapshot of all watches
      const snapshot = await new Promise((resolve, reject) => {
        onValue(
          watchesRef,
          (snapshot) => {
            resolve(snapshot);
          },
          { onlyOnce: true } // Fetch data only once
        );
      });

      let updates = {};
      const logDetails = {
        alarmCode: code,
        latitude:
          data.find((item) => item.id === watchId)?.watchLatitude || "Unknown",
        longitude:
          data.find((item) => item.id === watchId)?.watchLongitude || "Unknown",
        timestamp: Date.now(),
        userId: currentUser?.uid || "Unknown ID",
        userEmail: currentUser?.email || "Unknown Email",
      };

      // Iterate through the watches to apply updates and logs
      snapshot.forEach((childSnapshot) => {
        const watch = childSnapshot.val();
        if (
          watch.groupCode === groupCode &&
          watch.companyId === companyId &&
          watch.locationId === locationId
        ) {
          // Update the alarm code for matching watches
          updates[`Watches/${childSnapshot.key}/alarmCode`] = code;
          updates[`Watches/${childSnapshot.key}/timeStamp`] = Date.now();
          // Log the update for each matching watch
          logUpdate(childSnapshot.key, logDetails, locationId); // Logs both globally and under the specific watch
        }
      });

      // Apply the updates to the database
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
        console.log(
          `Alarm code updated to ${code} for watches with groupCode: ${groupCode}`
        );
      } else {
        console.log("No matching watches found.");
      }
    } catch (error) {
      console.error("Error updating alarm code for group:", error);
    }
  };

  // const { google } = require('google-auth-library');

  // function getAccessToken(clientEmail, privateKey) {
  //   const SCOPES = ['https://www.googleapis.com/auth/cloud-platform']; // Or other relevant scopes

  //   return new Promise((resolve, reject) => {
  //     const jwtClient = new google.auth.JWT(
  //       clientEmail,
  //       null,
  //       privateKey.replace(/\\n/g, '\n'), // Ensure the private key format is correct
  //       SCOPES,
  //       null
  //     );

  //     jwtClient.authorize((err, tokens) => {
  //       if (err) {
  //         reject(err);
  //         return;
  //       }
  //       resolve(tokens.access_token);
  //     });
  //   });
  // }

  const confirmAlarmChange = (
    watchId,
    code,
    groupCode,
    companyId,
    locationId
  ) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to change the alarm code?"
    );
    if (isConfirmed) {
      handleAlarmCodeChange(watchId, code, groupCode, companyId, locationId);
    }
  };

  const filteredData = data.filter((watch) => {
    // Match company and location as before
    const matchesCompany = selectedCompany
      ? watch.companyId === selectedCompany
      : true;
    const matchesLocation = selectedLocation
      ? watch.locationId === selectedLocation
      : true;

    // Check if watch id or owner's displayName matches the search query
    const matchesSearchQuery =
      !searchQuery ||
      watch.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (watch &&
        watch.displayName &&
        watch.displayName.toLowerCase().includes(searchQuery.toLowerCase()));

    return matchesCompany && matchesLocation && matchesSearchQuery;
  });

  const actionColumn = [
    {
      field: "alarmCode",
      headerName: "Alarm Status",
      width: 160,
      renderCell: (params) => {
        const { alarmCode } = params.row;
        const { color, label } = alarmCodeStyles[alarmCode] || {
          color: "gray",
          label: "Unknown",
        };

        return (
          <div
            style={{
              backgroundColor: color,
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              textAlign: "center",

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100px",
            }}
          >
            {label}
          </div>
        );
      },
    },

    {
      field: "alarm Actions",
      headerName: "Alarm Actions",
      width: 330,
      renderCell: (params) => {
        const { id, groupCode, companyId, locationId } = params.row;

        return (
          <div className="alarmCodeButtons">
            {Object.entries(alarmCodeButtons).map(([code, label]) => (
              <button
                key={code}
                className={`alarmButton ${code.toLowerCase()}`}
                onClick={() =>
                  confirmAlarmChange(id, code, groupCode, companyId, locationId)
                }
              >
                {label}
              </button>
            ))}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/watches/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            {/* {currentUser.role !== 1 && (
              <div
                className="deleteButton"
                onClick={() => handleDelete(params.row.id)}
              >
                Delete
              </div>
            )} */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Watch List
        <div
          style={{
            gap: "10px",

            display: "flex",
          }}
        >
          {currentUser.role !== 3 && (
            <Link to="/watches/new" className="link">
              Assign Watch to a User
            </Link>
          )}
          {currentUser.role === 1 && (
            <Link to="/watches/new-watch-company" className="link">
              Assign Watch to a Company
            </Link>
          )}
        </div>
      </div>

      {/* Filters */}
      <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="company-filter-label">Company</InputLabel>
          <Select
            labelId="company-filter-label"
            value={selectedCompany}
            label="Company"
            onChange={(e) => setSelectedCompany(e.target.value)}
            disabled={currentUser.role !== 1}
          >
            <MenuItem value="">
              <em>All Companies</em>
            </MenuItem>
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="location-filter-label">Location</InputLabel>
          <Select
            labelId="location-filter-label"
            value={selectedLocation}
            label="Location"
            onChange={(e) => setSelectedLocation(e.target.value)}
            disabled={!selectedCompany}
          >
            <MenuItem value="">
              <em>All Locations</em>
            </MenuItem>
            {locations
              .filter(
                (location) =>
                  !selectedCompany || location.companyId === selectedCompany
              )
              .map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.locationName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label="Search by ID or Owner"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: 300 }}
        />
      </Box>

      {/* DataGrid */}
      <DataGrid
        className="datagrid"
        rows={filteredData}
        columns={watchColumns.concat(actionColumn)}
        pageSize={19}
        rowsPerPageOptions={[19]}
        checkboxSelection
      />
    </div>
  );
};

export default WatchDatatable;
