import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
  getDatabase,
  ref,
  set,
  push,
  serverTimestamp,
  get,
  update,
} from "firebase/database";
import { database } from "../../firebase";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// Function to shorten and encode companyId and locationId
const shortenId = (id, prefix) => {
  const hash = id.slice(-6); // Take last 6 characters
  return `${prefix}${hash}`;
};

const NewRelayBoard = ({ inputs, title }) => {
  const [data, setData] = useState({});
  const [port, setPort] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [currentLat, setCurrentLat] = useState("");
  const [currentLon, setCurrentLon] = useState("");
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const userRole = currentUser.role;
  const userCompanyId = currentUser.companyId;

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentLat(position.coords.latitude);
            setCurrentLon(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting location: ", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  useEffect(() => {
    // Fetch companies from Firebase
    const fetchCompanies = async () => {
      const dbRef = ref(database, "Companies");
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        setCompanies(Object.entries(snapshot.val()));
      }
    };
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompanyId) {
      // Fetch all locations and filter by selected companyId
      const fetchLocations = async () => {
        const dbRef = ref(database, "locations"); // Reference to all locations
        const snapshot = await get(dbRef);

        if (snapshot.exists()) {
          const locationsData = snapshot.val();
          // Filter locations by companyId
          const filteredLocations = Object.entries(locationsData).filter(
            ([key, location]) => location.companyId === selectedCompanyId
          );

          setLocations(filteredLocations);
        } else {
          setLocations([]);
        }
      };

      fetchLocations();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    // Auto-select the company based on the user's companyId and disable it if role is not 1
    if (userCompanyId) {
      setSelectedCompanyId(userCompanyId);
    }
  }, [userCompanyId]);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    // Generate shortened company and location IDs
    const shortCompanyId = shortenId(selectedCompanyId, "C");
    const shortLocationId = shortenId(selectedLocationId, "L");

    const relayBoardDid = data.did; // Assuming this is the unique identifier for the relay board

    // Retrieve latitude and longitude based on the selected location
    const selectedLocation = locations.find(
      (loc) => loc.id === selectedLocationId
    );

    // Prepare the board data that you want to add
    const newBoardData = {
      [relayBoardDid]: {
        conf: {
          apikey: "AIzaSyAcjG7gTUIyuVyvUIZMZXujIQ0FfVq1rnY",
          apn: data.apn || "",
          ban: data.ban || "",
          baud: data.baud || "9600",
          bui: data.bui || "",
          can_gc: data.can_gc || "K",
          can_ot: data.can_ot || "1",
          co_idn: selectedCompanyId, // Using the company ID
          cmd: "0",
          contyp: "0",
          dbit: "12",
          dburl:
            "https://esp32-firebase-demo-b4e55-default-rtdb.asia-southeast1.firebasedatabase.app/",
          did: data.did || "", // Example device ID (could also be dynamic)
          gate_w: data.gate_w || "",
          grn_gc: data.grn_gc || "0",
          grn_ot: data.grn_ot || "1",
          iac: 0,
          input: data.input || "GORCGORC",
          lat: currentLat, // Automatically input the current latitude
          lo_idn: selectedLocationId, // Using the location ID
          lon: currentLon, // Automatically input the current longitude
          mqtt_p: data.mqtt_p || "",
          mqttip: data.mqttip || "",
          org_gc: data.org_gc || "0",
          org_ot: data.org_ot || "1",
          p_dns: data.p_dns || "",
          parity: "0",
          pass_0: data.pass_0 || "12345678",
          red_gc: data.red_gc || "0",
          red_ot: data.red_ot || "1",
          rly_os: data.rly_os || "0000",
          s_dns: data.s_dns || "",
          sbit: "16",
          smode: "0",
          ssid_0: data.ssid_0 || "",
          sta_ip: data.sta_ip || "",
          staips: "0",
          subnet: data.subnet || "",
        },
        status: {
          alert: {
            letter: data.input ? data.input[0] : "", // First letter of the input field
            timestamp: serverTimestamp(), // Automatically log the current timestamp
          },
          hb: "0",
          msg: "1",
          res: "Config Update Success",
          update: "1",
        },
      },
    };

    try {
      // Reference to the boards node for the specific company and location
      const relayBoardRef = ref(database, `RelayBoards`);

      // Use update to add the new board
      await update(relayBoardRef, newBoardData);

      console.log("New board added successfully:", newBoardData);
    } catch (error) {
      console.error("Error adding new relay board:", error);
    }
  };

  const connectSerial = async () => {
    try {
      const selectedPort = await navigator.serial.requestPort();
      await selectedPort.open({ baudRate: 115200 });
      setPort(selectedPort);
      console.log("Connected to serial port");
    } catch (error) {
      console.error("Failed to open serial port:", error);
    }
  };

  const sendCommandAndReadData = async () => {
    if (port) {
      try {
        const writer = port.writable.getWriter();
        await writer.write(new TextEncoder().encode("G"));

        const reader = port.readable.getReader();
        let jsonString = "";
        let done = false;

        while (!done) {
          const { value, done: readerDone } = await reader.read();
          if (value) {
            const textDecoder = new TextDecoder();
            jsonString += textDecoder.decode(value);
            try {
              JSON.parse(jsonString);
              done = true;
            } catch (error) {}
          }

          done = readerDone || done;
        }

        console.log("Complete JSON data received from Arduino:", jsonString);
        const jsonData = JSON.parse(jsonString);
        const mappedData = {
          deviceId: jsonData.did,
        };

        setData(mappedData);
        reader.releaseLock();
        writer.releaseLock();
        await port.close();
      } catch (error) {
        console.error("Failed to read data from Arduino:", error);
      }
    }
  };

  if (userRole !== 1 && userRole !== 2) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <p>You do not have access to this page, please contact your admin.</p>
        <br />
        <Link
          to="/"
          style={{
            textDecoration: "underline",
            color: "black",
            fontWeight: "bold",
          }}
        >
          Go Back
        </Link>
      </div>
    );
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
          <div style={{ gap: "20px", display: "flex", marginLeft: "20px" }}>
            <button
              onClick={connectSerial}
              style={{
                height: "30px",
                width: "250px",
                color: "white",
                backgroundColor: "black",
                border: "none",
              }}
            >
              Connect Serial
            </button>
            <button
              onClick={sendCommandAndReadData}
              style={{
                height: "30px",
                width: "250px",
                color: "white",
                backgroundColor: "black",
                border: "none",
              }}
            >
              Autofill from Arduino
            </button>
          </div>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label>Company *</label>
                <select
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValue === "addCompany") {
                      navigate("/companies/new"); // Redirect to "Add Company" page
                    } else {
                      setSelectedCompanyId(selectedValue); // Set the selected company if it's not "Add Company"
                    }
                  }}
                  disabled={userRole !== 1}
                  value={selectedCompanyId}
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  required
                >
                  <option>Select Company</option>
                  {companies.map(([id, company]) => (
                    <option key={id} value={id}>
                      {company.companyName}
                    </option>
                  ))}
                  {userRole === 1 && (
                    <option value="addCompany">Add Company</option>
                  )}
                </select>
              </div>

              <div className="formInput">
                <label>Location *</label>
                <select
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValue === "addLocation") {
                      navigate("/locations/new"); // Redirect to "Add Location" page
                    } else {
                      setSelectedLocationId(selectedValue); // Set selected location
                    }
                  }}
                  disabled={!selectedCompanyId} // Locations can only be selected after a company is selected
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  required
                >
                  <option>Select Location</option>
                  {locations.map(([id, location]) => (
                    <option key={id} value={id}>
                      {location.locationName}
                    </option>
                  ))}
                  {(userRole === 1 || userRole === 2) && (
                    <option value="addLocation">
                      Add Location
                    </option> // Show "Add Location" for roles 1 and 2
                  )}
                </select>
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    value={data[input.id] || ""}
                    onChange={handleInput}
                    required={input.required}
                    maxLength={input.maxLength}
                  />
                </div>
              ))}
              <span
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "50px",
                  color: "gray",
                  fontSize: "10px",
                }}
              >
                Please Fill all the required fields (*)
              </span>
              <div
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                }}
              >
                <button type="submit">Add Relay Board</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRelayBoard;
