import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { alarmLogsColumn, relayBoardLogsColumn } from "../../datatablesource";
import { useEffect, useState } from "react";
import { ref, onValue, get } from "firebase/database";
import { database } from "../../firebase";
import {
  IconButton,
  Menu,
  MenuItem,
  Select,
  MenuItem as MuiMenuItem,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

const LogsDataTable = () => {
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [startDate, setStartDate] = useState(null); // State for start date
  const [endDate, setEndDate] = useState(null); // State for end date
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [alarmCodeFilter, setAlarmCodeFilter] = useState("");

  const [deviceIdFilter, setDeviceIdFilter] = useState("");
  const [alertFilter, setAlertFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");

  const [watchIdFilter, setWatchIdFilter] = useState("");
  const [userEmailFilter, setUserEmailFilter] = useState("");
  const [deviceTypeFilter, setDeviceTypeFilter] = useState("");
  const [selectedLogType, setSelectedLogType] = useState("watches"); // New state for dropdown selection
  const [columns, setColumns] = useState(alarmLogsColumn); // Set initial columns to watches columns

  const handleLogTypeChange = (event) => {
    const type = event.target.value;
    setSelectedLogType(type);
    setColumns(type === "watches" ? alarmLogsColumn : relayBoardLogsColumn);
  };

  const handleFilterChange = (type, value) => {
    console.log(`Changing filter for ${type}: ${value}`);
    if (type === "watchId") setWatchIdFilter(value);
    else if (type === "userEmail") setUserEmailFilter(value);
    else if (type === "deviceType") setDeviceTypeFilter(value);
    else if (type === "deviceId") setDeviceIdFilter(value);
    else if (type === "alert") setAlertFilter(value);
    else if (type === "type") setTypeFilter(value);
  };

  const getAlarmStatus = (code) => {
    switch (code) {
      case "C":
        return "Cancelled";
      case "G":
        return "Green";
      case "R":
        return "Red";
      case "O":
        return "Orange";
      default:
        return "Unknown";
    }
  };

  // Fetch companies
  useEffect(() => {
    const fetchCompanies = async () => {
      const companiesRef = ref(database, "Companies");
      const snapshot = await get(companiesRef);
      const companiesList = [];
      snapshot.forEach((childSnapshot) => {
        companiesList.push({
          id: childSnapshot.key,
          name: childSnapshot.val().companyName,
        });
      });
      setCompanies(companiesList);
    };

    fetchCompanies();
  }, []);

  // Fetch locations based on selected company
  useEffect(() => {
    if (selectedCompany) {
      const fetchLocations = async () => {
        const locationsRef = ref(database, "locations");
        const snapshot = await get(locationsRef);
        const locationsList = [];
        snapshot.forEach((childSnapshot) => {
          const locationData = childSnapshot.val();
          if (locationData.companyId === selectedCompany) {
            locationsList.push({
              id: childSnapshot.key,
              name: locationData.locationName,
            });
          }
        });
        setLocations(locationsList);
      };

      fetchLocations();
    } else {
      setLocations([]);
    }
  }, [selectedCompany]);

  useEffect(() => {
    const logsRef = ref(
      database,
      selectedLogType === "watches" ? "logs/alarm" : "logs/boards"
    );

    const unsub = onValue(logsRef, (snapshot) => {
      let list = [];
      snapshot.forEach((childSnapshot) => {
        const logData = childSnapshot.val();

        const uniqueId =
          childSnapshot.key ||
          `${logData.deviceId || ""}_${logData.timestamp || ""}`;

        // General filtering for both watches and relayboards
        const matchesCompany =
          !selectedCompany ||
          (selectedLogType === "watches"
            ? logData.companyId === selectedCompany
            : logData.companyID === selectedCompany); // Use companyId for relayboard

        const matchesLocation =
          !selectedLocation || logData.locationId === selectedLocation;

        const matchesDate =
          (!startDate ||
            dayjs(Number(logData.timestamp)).isAfter(
              dayjs(startDate).startOf("day")
            )) &&
          (!endDate ||
            dayjs(Number(logData.timestamp)).isBefore(
              dayjs(endDate).endOf("day")
            ));

        // Filtering for watches
        const matchesAlarmCode =
          !alarmCodeFilter || logData.alarmCode === alarmCodeFilter;
        const matchesWatchId =
          !watchIdFilter ||
          (logData.watchId &&
            logData.watchId
              .toLowerCase()
              .includes(watchIdFilter.toLowerCase()));
        const matchesUserEmail =
          !userEmailFilter ||
          (logData.userEmail &&
            logData.userEmail
              .toLowerCase()
              .includes(userEmailFilter.toLowerCase()));
        const matchesDeviceType =
          !deviceTypeFilter ||
          (logData.deviceInfo?.deviceModel &&
            logData.deviceInfo.deviceModel
              .toLowerCase()
              .includes(deviceTypeFilter.toLowerCase()));

        // Filtering for relayboards
        const matchesDeviceId =
          !deviceIdFilter ||
          (logData.deviceID &&
            logData.deviceID
              .toLowerCase()
              .includes(deviceIdFilter.toLowerCase()));
        const matchesAlert =
          !alertFilter ||
          logData.alert?.toLowerCase() === alertFilter.toLowerCase();
        const matchesType =
          !typeFilter ||
          logData.type?.toLowerCase().includes(typeFilter.toLowerCase());

        // Structuring the data based on log type
        if (
          selectedLogType === "watches" &&
          matchesCompany &&
          matchesLocation &&
          matchesDate &&
          matchesAlarmCode &&
          matchesWatchId &&
          matchesUserEmail &&
          matchesDeviceType
        ) {
          list.push({
            id: uniqueId,
            companyName: logData.companyName,
            alarmCode: getAlarmStatus(logData.alarmCode),
            "deviceInfo.batteryLevel":
              logData.deviceInfo?.batteryLevel || "N/A",
            "deviceInfo.browser": logData.deviceInfo?.browser || "N/A",
            "deviceInfo.connectivityMode":
              logData.deviceInfo?.connectivityMode || "N/A",
            "deviceInfo.deviceModel": logData.deviceInfo?.deviceModel || "N/A",
            latitude: logData.latitude,
            longitude: logData.longitude,
            locationCode: logData.locationCode,
            locationId: logData.locationId,
            locationLatitude: logData.locationLatitude,
            locationLongitude: logData.locationLongitude,
            locationName: logData.locationName,
            timestamp: logData.timestamp,
            userEmail: logData.userEmail,
            userId: logData.userId,
            watchId: logData.watchId,
            audioUrl: logData.audio || "",
          });
        } else if (
          selectedLogType === "relayboards" &&
          matchesDeviceId &&
          matchesAlert &&
          matchesType &&
          matchesCompany &&
          matchesLocation
        ) {
          list.push({
            id: uniqueId,
            deviceId: logData.deviceID,
            alert: logData.alert,
            companyId: logData.companyID,
            locationId: logData.locationID,
            timestamp: logData.timestamp,
            type: logData.type || "N/A",
          });
        }

        list.sort((a, b) => b.timestamp - a.timestamp);
      });

      list.sort((a, b) => b.timestamp - a.timestamp);

      setData(list);
    });

    return () => unsub();
  }, [
    selectedCompany,
    selectedLocation,
    startDate,
    endDate,
    alarmCodeFilter,
    watchIdFilter,
    userEmailFilter,
    deviceTypeFilter,
    selectedLogType,
    deviceIdFilter,
    alertFilter,
    typeFilter,
  ]);

  const handleCompanyChange = (event) => {
    const companyId = event.target.value;
    setSelectedCompany(companyId);

    console.log(companyId);

    const company = companies.find((comp) => comp.id === companyId);
    if (company) {
      setSelectedCompanyName(company.name);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Logs Report", 20, 10);
    doc.setFontSize(12);

    const yStart = 20;
    const lineHeight = 10;
    const maxLinesPerPage = Math.floor(
      (doc.internal.pageSize.height - yStart) / lineHeight
    );

    let y = yStart;

    data.forEach((log, index) => {
      if (y + 4 * lineHeight > doc.internal.pageSize.height) {
        doc.addPage();
        y = yStart;
      }

      doc.setFontSize(10);
      doc.text(`Log ID: ${log.id}`, 20, y);
      y += lineHeight;
      doc.text(`Company Name: ${log.companyName}`, 20, y);
      y += lineHeight;
      doc.text(`Alarm Code: ${log.alarmCode}`, 20, y);
      y += lineHeight;
      doc.text(
        `Device Battery Level: ${log["deviceInfo.batteryLevel"]}`,
        20,
        y
      );
      y += lineHeight;
      doc.text(`Device Browser: ${log["deviceInfo.browser"]}`, 20, y);
      y += lineHeight;
      doc.text(
        `Device Connectivity Mode: ${log["deviceInfo.connectivityMode"]}`,
        20,
        y
      );
      y += lineHeight;
      doc.text(`Device Model: ${log["deviceInfo.deviceModel"]}`, 20, y);
      y += lineHeight;
      doc.text(`Latitude: ${log.latitude}`, 20, y);
      y += lineHeight;
      doc.text(`Longitude: ${log.longitude}`, 20, y);
      y += lineHeight;
      doc.text(`Location Code: ${log.locationCode}`, 20, y);
      y += lineHeight;
      doc.text(`Location ID: ${log.locationId}`, 20, y);
      y += lineHeight;
      doc.text(`Location Latitude: ${log.locationLatitude}`, 20, y);
      y += lineHeight;
      doc.text(`Location Longitude: ${log.locationLongitude}`, 20, y);
      y += lineHeight;
      doc.text(`Location Name: ${log.locationName}`, 20, y);
      y += lineHeight;
      doc.text(`Timestamp: ${log.timestamp}`, 20, y);
      y += lineHeight;
      doc.text(`User Email: ${log.userEmail}`, 20, y);
      y += lineHeight;
      doc.text(`User ID: ${log.userId}`, 20, y);
      y += lineHeight;
      doc.text(`Watch ID: ${log.watchId}`, 20, y);
      y += lineHeight * 2;
    });

    doc.save("logs_report.pdf");
    handleMenuClose();
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Logs");
    XLSX.writeFile(wb, "logs_report.xlsx");
    handleMenuClose();
  };

  const clearFilters = () => {
    setSelectedCompany("");
    setSelectedLocation("");
    setStartDate(null);
    setEndDate(null);
    setAlarmCodeFilter("");
    setWatchIdFilter("");
    setUserEmailFilter("");
    setDeviceTypeFilter("");
  };

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <div>
          <span>Data Log</span>
          <FormControl
            variant="outlined"
            style={{ minWidth: 120, marginLeft: "20px" }}
          >
            <InputLabel>Log Type</InputLabel>
            <Select
              value={selectedLogType}
              onChange={handleLogTypeChange}
              label="Log Type"
            >
              <MenuItem value="watches">Watches</MenuItem>
              <MenuItem value="relayboards">Relay Boards</MenuItem>
            </Select>
          </FormControl>
        </div>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="primary"
        >
          <span
            style={{
              fontSize: "13px",
              color: "gray",
            }}
          >
            Download Report
          </span>
          <DownloadIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={downloadPDF}>Download PDF</MenuItem>
          <MenuItem onClick={downloadExcel}>Download Excel</MenuItem>
        </Menu>
      </div>

      <div
        className="datatableFilters"
        style={{
          display: "flex",
          gap: "20px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        {/* Filters common for both Watches and Relay Boards */}
        <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
          <InputLabel>Company</InputLabel>
          <Select
            value={selectedCompany}
            onChange={handleCompanyChange}
            label="Company"
            fullWidth
          >
            {companies.map((company) => (
              <MuiMenuItem key={company.id} value={company.id}>
                {company.name}
              </MuiMenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
          <InputLabel>Location</InputLabel>
          <Select
            value={selectedLocation}
            onChange={(e) => setSelectedLocation(e.target.value)}
            label="Location"
            fullWidth
            disabled={!selectedCompany}
          >
            {locations.map((location) => (
              <MuiMenuItem key={location.id} value={location.id}>
                {location.name}
              </MuiMenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
          <TextField
            label="End Date"
            type="date"
            fullWidth
            value={endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
          <InputLabel>Alarm Code</InputLabel>
          <Select
            value={alarmCodeFilter}
            onChange={(e) => setAlarmCodeFilter(e.target.value)}
            label="Alarm Code"
            fullWidth
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="C">Cancelled</MenuItem>
            <MenuItem value="G">Green</MenuItem>
            <MenuItem value="R">Red</MenuItem>
            <MenuItem value="O">Orange</MenuItem>
          </Select>
        </FormControl>

        {/* Filters for Watches - Only shown when 'watches' is selected */}
        {selectedLogType === "watches" && (
          <>
            <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
              <TextField
                label="Watch ID"
                fullWidth
                value={watchIdFilter}
                onChange={(e) => handleFilterChange("watchId", e.target.value)}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
              <TextField
                label="User Email"
                fullWidth
                value={userEmailFilter}
                onChange={(e) =>
                  handleFilterChange("userEmail", e.target.value)
                }
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
              <TextField
                label="Device Type"
                fullWidth
                value={deviceTypeFilter}
                onChange={(e) =>
                  handleFilterChange("deviceType", e.target.value)
                }
              />
            </FormControl>
          </>
        )}

        {/* Filters for Relay Boards - Only shown when 'relayboards' is selected */}
        {selectedLogType === "relayboards" && (
          <>
            <TextField
              label="Device ID"
              value={deviceIdFilter}
              onChange={(e) => handleFilterChange("deviceId", e.target.value)}
            />
            <TextField
              label="Alert"
              value={alertFilter}
              onChange={(e) => handleFilterChange("alert", e.target.value)}
            />
            <TextField
              label="Type"
              value={typeFilter}
              onChange={(e) => handleFilterChange("type", e.target.value)}
            />
          </>
        )}

        <Button onClick={clearFilters} style={{ alignSelf: "center" }}>
          Clear Filters
        </Button>
      </div>

      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5, 10, 25]}
        checkboxSelection
        disableSelectionOnClick
        className="datagrid"
        sortModel={[
          {
            field: "timestamp", // Field name that you want to sort
            sort: "desc", // Sort direction (descending)
          },
        ]}
      />
    </div>
  );
};

export default LogsDataTable;
