import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { ref, get, query, orderByChild, equalTo } from "firebase/database";
import { database } from "../../firebase";

const CompanyWatches = ({ companyId }) => {
  const [watches, setWatches] = useState([]);

  useEffect(() => {
    const fetchWatches = async () => {
      try {
        const watchesRef = ref(database, "Watches");
        const q = query(watchesRef, orderByChild('companyId'), equalTo(companyId));
        const snapshot = await get(q);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const watchesList = Object.keys(data).map(key => ({
            id: key,
            ...data[key]
          }));
          setWatches(watchesList);
        } else {
          console.log("No watches found");
        }
      } catch (error) {
        console.error("Error fetching watches:", error);
      }
    };

    if (companyId) {
      fetchWatches();
    }
  }, [companyId]);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Watch Id</TableCell>
            <TableCell className="tableCell">Watch Owner</TableCell>
            <TableCell className="tableCell">Location</TableCell>
            <TableCell className="tableCell">Company</TableCell>
            <TableCell className="tableCell">Group Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {watches.map((watch) => (
            <TableRow key={watch.id}>
              <TableCell className="tableCell">{watch.id}</TableCell>
              <TableCell className="tableCell">{watch.displayName}</TableCell>
              <TableCell className="tableCell">{watch.location}</TableCell>
              <TableCell className="tableCell">{watch.company}</TableCell>
              <TableCell className="tableCell">{watch.groupCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompanyWatches;
