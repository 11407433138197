export const userInputs = [
  {
    id: "displayName",
    label: "Name and surname *",
    type: "text",
    placeholder: "John Doe",
    required: true,
    maxLength:45
  },
  {
    id: "email",
    label: "Email *",
    type: "email",
    placeholder: "john_doe@gmail.com",
    required: true,
    maxLength:45
  },
  {
    id: "password",
    label: "Password *",
    type: "password",
    maxLength:40,
    placeholder:
      "Must be 8-20 characters with an uppercase, a number, and a special character",
    required: true,
    validate: (value) =>
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/.test(
        value
      ),
  },
  {
    id: "address",
    label: "Address *",
    type: "text",
    placeholder: "Elton St. 216 NewYork",
    required: true,
    maxLength:60
  },
  {
    id: "postCode",
    label: "Postal Code *",
    type: "text",
    placeholder: "Postal Code",
    required: true,
    maxLength:14
  },
];

export const watchInputs = [
  // {
  //   id: "watchId",
  //   label: "Watch Id",
  //   type: "text",
  //   placeholder: "dKDAI39A8DF87",
  // },
  // {
  //   id: "displayName",
  //   label: "Watch Owner",
  //   type: "text",
  //   placeholder: "John Doe",
  // },

  {
    id: "watchDisplayName",
    label: "Display Name",
    type: "text",
    placeholder: "Display Name",
    required: true,
    maxLength:8
  },

  {
    id: "groupCode",
    label: "Group Code",
    type: "text",
    placeholder: "1",
    required: false,
    maxLength:2
  },
];

export const productInputs = [
  {
    id: 1,
    label: "Title",
    type: "text",
    placeholder: "Apple Macbook Pro",
  },
  {
    id: 2,
    label: "Description",
    type: "text",
    placeholder: "Description",
  },
  {
    id: 3,
    label: "Category",
    type: "text",
    placeholder: "Computers",
  },
  {
    id: 4,
    label: "Price",
    type: "text",
    placeholder: "100",
  },
  {
    id: 5,
    label: "Stock",
    type: "text",
    placeholder: "in stock",
  },
];

export const companyInputs = [
  {
    id: "companyName",
    label: "Company Name *",
    type: "text",
    placeholder: "SCSI",
    required: true,
    maxLength:30
  },
  {
    id: "address",
    label: "Address *",
    type: "text",
    placeholder: "123 Main St, Suite 500",
    required: true,
    maxLength:60
  },
  {
    id: "city",
    label: "City *",
    type: "text",
    placeholder: "Metropolis",
    required: true,
    maxLength:20
  },
  {
    id: "state",
    label: "State/Province *",
    type: "text",
    placeholder: "NY",
    required: true,
    maxLength:20
  },
  {
    id: "postalCode",
    label: "Postal Code *",
    type: "text",
    placeholder: "10001",
    required: true,
    maxLength:14
  },
  // {
  //   id: "country",
  //   label: "Country",
  //   type: "text",
  //   placeholder: "USA",
  // },
  // {
  //   id: "phone",
  //   label: "Phone Number",
  //   type: "text",
  //   placeholder: "+1 234 567 8901",
  // }
];

export const locationInputs = [
  {
    id: "locationName",
    label: "Location Name *",
    type: "text",
    placeholder: "Headquarters, Branch Office",
    required: true,
    maxLength:30
  },
  {
    id: "address",
    label: "Address *",
    type: "text",
    placeholder: "123 Main St, Suite 500",
    required: true,
    maxLength:60
  },
  {
    id: "city",
    label: "City *",
    type: "text",
    placeholder: "Metropolis",
    required: true,
    maxLength:30
  },
  {
    id: "state",
    label: "State/Province *",
    type: "text",
    placeholder: "NY",
    required: true,
    maxLength:30
  },
  {
    id: "postalCode",
    label: "Postal Code *",
    type: "text",
    placeholder: "10001",
    required: true,
    maxLength:14
  },
  // {
  //   id: "country",
  //   label: "Country",
  //   type: "text",
  //   placeholder: "USA",
  // },
  // {
  //   id: "companyName",
  //   label: "Company Name",
  //   type: "text",
  //   placeholder: "Connectified",
  // },
];

export const groupCodeInputs = [
  {
    id: "groupCode",
    label: "Group Code",
    type: "text",
    placeholder: "1",
  },
  {
    id: "location",
    label: "Location",
    type: "text",
    placeholder: "Connectified Ph Branch",
  },
  {
    id: "company",
    label: "Company",
    type: "text",
    placeholder: "Connectified",
  },
  {
    id: "watches",
    label: "Watches",
    type: "number",
    placeholder: "62",
  },
  {
    id: "locationID",
    label: "Location ID",
    type: "text",
    placeholder: "L12345",
  },
  {
    id: "companyId",
    label: "Company ID",
    type: "text",
    placeholder: "C12345",
  },
];

export const relayBoardInput = [
  {
    id: "did",
    label: "Device ID *",
    type: "text",
    placeholder: "b000e868e7309a28",
    required: true,
    maxLength:40
  },
  // { id: "apikey", label: "API Key", type: "text", placeholder: "Enter API Key" },
  // { id: "apn", label: "APN", type: "text", placeholder: "Enter APN" },
  // { id: "baud", label: "Baud Rate", type: "text", placeholder: "9600" },
  // { id: "can_gc", label: "CAN GC", type: "text", placeholder: "K" },
  // { id: "can_ot", label: "CAN OT", type: "text", placeholder: "1" },
  // { id: "cid", label: "CID", type: "text", placeholder: "c90009" },
  // { id: "cmd", label: "Command", type: "text", placeholder: "con" },
  // { id: "contyp", label: "Connection Type", type: "text", placeholder: "0" },
  // { id: "dbit", label: "Data Bit", type: "text", placeholder: "12" },
  // { id: "dburl", label: "Database URL", type: "text", placeholder: "Enter Database URL" },

  // { id: "gate_w", label: "Gateway", type: "text", placeholder: "Enter Gateway" },
  // { id: "gps", label: "GPS", type: "text", placeholder: "Enter GPS" },
  {
    id: "grn_gc",
    label: "Green Group Code",
    type: "text",
    placeholder: "0",
    required: false,
    maxLength:2
  },
  {
    id: "org_gc",
    label: "Orange Group Code",
    type: "text",
    placeholder: "0",
    required: false,
    maxLength:2
  },
  {
    id: "red_gc",
    label: "Red Group Code",
    type: "text",
    placeholder: "0",
    required: false,
    maxLength:2
  },
  // { id: "grn_ot", label: "Green OT", type: "text", placeholder: "1" },
  // { id: "iac", label: "IAC", type: "number", placeholder: "0" },
  // { id: "input", label: "Input", type: "text", placeholder: "GORCGORC" },
  // { id: "lid", label: "Location", type: "text", placeholder: "L00001" },
  // { id: "mqtt_p", label: "MQTT Port", type: "text", placeholder: "Enter MQTT Port" },
  // { id: "mqttip", label: "MQTT IP", type: "text", placeholder: "Enter MQTT IP" },

  // { id: "org_ot", label: "Orange OT", type: "text", placeholder: "1" },
  // { id: "p_dns", label: "Primary DNS", type: "text", placeholder: "Enter Primary DNS" },
  // { id: "parity", label: "Parity", type: "text", placeholder: "0" },
  // { id: "pass_0", label: "Password", type: "password", placeholder: "Enter Password" },

  // { id: "red_ot", label: "Red OT", type: "text", placeholder: "1" },
  // { id: "rly_os", label: "Relay OS", type: "text", placeholder: "0000" },
  // { id: "s_dns", label: "Secondary DNS", type: "text", placeholder: "Enter Secondary DNS" },
  // { id: "sbit", label: "Stop Bit", type: "text", placeholder: "16" },
  // { id: "smode", label: "SMODE", type: "text", placeholder: "0" },
  // { id: "ssid_0", label: "SSID", type: "text", placeholder: "Enter SSID" },
  // { id: "sta_ip", label: "Station IP", type: "text", placeholder: "Enter Station IP" },
  // { id: "staips", label: "STA IPS", type: "text", placeholder: "0" },
  // { id: "subnet", label: "Subnet", type: "text", placeholder: "Enter Subnet" },
];


export const billingInput = [
  {
    id: "subscriptionPlan",
    label: "Plan Name *",
    type: "text",
    placeholder: "A",
    required: true,
    maxLength:20
  },
  {
    id: "price",
    label: "Plan Price *",
    type: "text",
    placeholder: "500",
    required: true,
    maxLength:20
  },
  {
  id:"description",
  label:"Plan Description",
  type:"text",
  placeholder:"Description",
  required: false,
  maxLength:150
  }
]