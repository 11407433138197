import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect, useContext } from "react";
import {
  getDatabase,
  ref,
  set,
  serverTimestamp,
  get,
  child,
} from "firebase/database";
import { database } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import useCountryList from "react-select-country-list"; // Import country list library

const NewCompany = ({ inputs, title }) => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize useNavigate
  const [data, setData] = useState({
    ar: {
      alert: "",
      en: false,
      location: {},
      server: {
        backup: { host: "127.0.0.1", port: "8002" },
        hb: 65000,
        primary: { host: "127.0.0.1", port: "8000" },
        reconnect: 10000,
      },
    },
    location: {},
    cmd: {
      alert: "",
      boards_cmd: {
        hb: "",
        msg: "",
        res: "",
        update: "0",
      },
    },
    communicator: {
      cmd: {
        balert: "",
        boards_cmd: {
          hb: "",
          msg: "",
          res: "",
          update: "0",
        },
        walert: "",
      },
      en: false,
    },
  });

  const [existingCompanyIds, setExistingCompanyIds] = useState([]);
  const countryOptions = useCountryList().getData();

  useEffect(() => {
    const fetchExistingCompanyIds = async () => {
      const dbRef = ref(database, "Companies");
      const snapshot = await get(child(dbRef, "/"));
      if (snapshot.exists()) {
        const ids = Object.keys(snapshot.val()).map(
          (key) => snapshot.val()[key].companyId
        );
        setExistingCompanyIds(ids);
      }
    };

    fetchExistingCompanyIds();
  }, []);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    // Handle nested data for cmd and communicator if applicable
    if (id.startsWith("cmd_")) {
      setData((prevData) => ({
        ...prevData,
        cmd: {
          ...prevData.cmd,
          [id.split("_")[1]]: value, // Update specific cmd field
        },
      }));
    } else if (id.startsWith("communicator_")) {
      setData((prevData) => ({
        ...prevData,
        communicator: {
          ...prevData.communicator,
          [id.split("_")[1]]: value, // Update specific communicator field
        },
      }));
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const generateUniqueId = () => {
    let uniqueId;
    do {
      uniqueId = `C${Math.floor(10000 + Math.random() * 90000)}`; // Generates a unique ID in the format CXXXXX
    } while (existingCompanyIds.includes(uniqueId));
    return uniqueId;
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const companyId = generateUniqueId(); // Generate the unique company ID
      console.log("Generated company ID:", companyId); // Log the generated company ID
      const newCompanyRef = ref(database, `Companies/${companyId}`); // Create a reference using the generated ID

      // Set the data to the new reference location
      await set(newCompanyRef, {
        ...data,
        companyId, // Ensure the companyId is set to the generated ID
        timeStamp: serverTimestamp(), // Add a timestamp
      });

      console.log("Company added successfully with ID:", companyId);

      // Alert message showing that the company has been added
      alert(`Company added successfully with ID: ${companyId}`);

      // Redirect to the /companies page after a short delay
      setTimeout(() => {
        navigate("/companies");
      }, 2000); // Delay for 2 seconds (2000ms) before redirecting
    } catch (error) {
      console.log("Error adding company:", error);
      alert("An error occurred while adding the company.");
    }
  };

  // Check user role and redirect if necessary
  useEffect(() => {
    if (currentUser.role !== 1) {
      navigate("/"); // Redirect to homepage or any other page if role is not 1
    }
  }, [currentUser.role, navigate]);

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                    required={input.required}
                    maxLength={input.maxLength}
                  />
                </div>
              ))}

              {/* Country Dropdown */}
              <div className="formInput">
                <label htmlFor="country">Country *</label>
                <select
                  id="country"
                  onChange={handleInput} // Use handleInput for changes
                  required
                  style={{
                    height: "30px",
                    width: "100%",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  <option value="">Select a Country</option>
                  {countryOptions.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
              {/* Additional fields for cmd and communicator */}
              {/* <div className="formInput">
                <label>Command Alert</label>
                <input
                  id="cmd_alert"
                  type="text"
                  placeholder="Command for alert"
                  onChange={handleInput}
                />
              </div>
              <div className="formInput">
                <label>Command Boards</label>
                <input
                  id="cmd_boards_cmd"
                  type="text"
                  placeholder="Command for boards"
                  onChange={handleInput}
                />
              </div>
              <div className="formInput">
                <label>Communicator Command</label>
                <input
                  id="communicator_cmd"
                  type="text"
                  placeholder="Communicator command"
                  onChange={handleInput}
                />
              </div> */}
              <span
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "50px",
                  color: "gray",
                  fontSize:"10px"
                }}
              >
                Please Fill all the required fields (*)
              </span>
              <div
                style={{ display: "flex", width: "100%", marginLeft: "50px" }}
              >
                <button type="submit">Add Company</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCompany;
