import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { companyColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext, useCallback } from "react";
import { ref, onValue, remove } from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, TextField } from "@mui/material";

const CompanyDataTable = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  // Redirect user if they don't have the correct role
  useEffect(() => {
    if (currentUser.role !== 1) {
      navigate("/");
    }
  }, [currentUser.role, navigate]);

  // Fetch company data once when the component mounts
  useEffect(() => {
    const companyRef = ref(database, "Companies");
  
    const unsub = onValue(companyRef, (snapshot) => {
      const list = [];
      snapshot.forEach((childSnapshot) => {
        list.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      // Update only if the data has changed
      setData((prevData) => {
        if (JSON.stringify(prevData) !== JSON.stringify(list)) {
          return list;
        }
        return prevData;
      });
    });
  
    return () => unsub();
  }, []);
  

  // Use useCallback to memoize the delete function to avoid unnecessary re-creations
  const handleDelete = useCallback(async (id) => {
    try {
      if (!id) return; // Prevent unnecessary delete operation if id is invalid
      const companyRef = ref(database, `Companies/${id}`);
      await remove(companyRef);
      setData((prevData) => prevData.filter((item) => item.id !== id));
    } catch (err) {
      console.log("Error deleting company:", err);
    }
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        const companyId = params.row.id;
        if (!companyId) return null; // Prevent rendering if ID is missing

        return (
          <div className="cellAction">
            <Link
              to={`/companies/${companyId}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  // Filter data based on search query for companyName or ID
  const filteredData = data.filter(
    (company) =>
      company.companyName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      company.id?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Company
        <Link to="/companies/new" className="link">
          Add New
        </Link>
      </div>
      <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
        <TextField
          label="Search by ID or Company Name"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: 300 }}
        />
      </Box>
      <DataGrid
        className="datagrid"
        rows={filteredData} // Pass the filtered data to DataGrid
        columns={companyColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default CompanyDataTable;
