import { useContext, useState } from "react";
import "./login.scss";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import { auth, database } from "../../firebase";
import { ref, get } from "firebase/database";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import WGLogoLink from "../../Images/WGLOGO.png";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationError, setVerificationError] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetEmailError, setResetEmailError] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // New state for toggling password visibility

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (!user.emailVerified) {
        setVerificationError(true);
        await signOut(auth);
        console.log("Logged out due to unverified email.");
      } else {
        // Fetch the user's data from Realtime Database
        const userRef = ref(database, `users/${user.uid}`); // Reference to the user's data in Realtime Database
        const userSnapshot = await get(userRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.val();
          const userRole = userData.role; // Assuming role is stored as 'role'
          const displayName = userData.displayName || "No Name"; // Default if displayName is null
          const companyId = userData.companyId;

          // Dispatch user data including displayName and role
          dispatch({
            type: "LOGIN",
            payload: {
              uid: user.uid,
              email: user.email,
              displayName: displayName,
              role: userRole,
              companyId: companyId,
            },
          });

          navigate("/"); // Navigate to the home page
        } else {
          console.error("No user data found in Realtime Database!");
        }
      }
    } catch (err) {
      console.error("Login error: ", err);
      setError(true);
    }
  };
  const handleResetPassword = () => {
    if (!resetEmail) {
      setResetEmailError("Please enter your email.");
      return;
    }

    // Attempt to send the reset email without checking if the email exists
    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        setResetEmailSent(true);
        setResetEmailError("");
        setShowResetModal(false);
      })
      .catch((error) => {
        console.error("Error resetting password:", error);
        // Show a generic error message without indicating if the email exists
        setResetEmailError("Failed to send reset email. Please try again.");
      });
  };

  return (
    <div
      className="login"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src={WGLogoLink}
        alt="WG Logo"
        className="logo"
        style={{
          maxHeight: "500px",
          width: "500px",
        }}
      />
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div style={{ position: "relative" }}>
          <input
            type={showPassword ? "text" : "password"} // Toggle type based on showPassword
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%) translateX(30%)",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </button>
        </div>
        <button className="button" type="submit">
          Login
        </button>
        {error && <span>Invalid Credentials</span>}
        {verificationError && (
          <span>Please check your email to verify your account</span>
        )}
        {resetEmailSent && (
          <span
            style={{
              color: "#6A9C89",
            }}
          >
            Password reset email sent!
          </span>
        )}
        <span
          style={{
            cursor: "pointer",
            color: "#3C3D37",
            marginTop: "10px",
          }}
          onClick={() => setShowResetModal(true)}
        >
          Forgot Password?
        </span>
      </form>

      {/* Password Reset Modal */}
      {showResetModal && (
        <div className="modal-overlay">
          <div className="modal">
            <p
              style={{
                marginBottom: "10px",
              }}
            >
              Reset Password
            </p>
            <input
              type="text"
              placeholder="Enter your email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
            {resetEmailError && (
              <span style={{ color: "red" }}>{resetEmailError}</span>
            )}
            <button className="button1" onClick={handleResetPassword}>
              Send Reset Email
            </button>
            <button
              className="button2"
              onClick={() => setShowResetModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
