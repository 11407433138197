import "./singleWatch.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext, useRef } from "react";
import {
  ref,
  get,
  update,
  onValue,
  push,
  serverTimestamp,
  remove,
} from "firebase/database";
import { database } from "../../firebase";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { AuthContext } from "../../context/AuthContext";
import mapIconUrl from "../../Images/mapIcon.png";
import warningIconUrl from "../../Images/warningIcon.png";
import L from "leaflet";
import SingleWatchLogDataTable from "../../components/datatable/SingleWatchLogDataTable";
import periodIcon from "../../Images/period.png";
import { useParams } from "react-router-dom"; // Import useParams
import SingleRelayBoardDataTable from "../../components/datatable/SingleRelayBoardDataTable";
import redRelayBoardIcon from "../../Images/5.png";
import orangeRelayBoardIcon from "../../Images/6.png";
import greenRelayBoardIcon from "../../Images/7.png";
import cancelledRelayBoardIcon from "../../Images/8.png";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection

const SingleRelayBoard = () => {
  const [currentAlarmStatus, setCurrentAlarmStatus] = useState("Unknown");
  const navigate = useNavigate(); // Initialize navigate for redirection
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);

  const { id = window.location.pathname.split("/").pop() } = useParams();

  const [relayBoardDetails, setRelayBoardDetails] = useState(null);
  const [formValues, setFormValues] = useState({
    did: "", // Default to empty string if not available
    lo_idn: "", // Default to empty string if not available
    co_idn: "", // Default to empty string if not available
    location: "", // Default to empty string if not available
    grn_gc: [], // Initialize as an array if not available
    org_gc: [], // Initialize as an array if not available
    red_gc: [], // Initialize as an array if not available
    apikey: "", // Default to empty string if not available
    apn: "", // Default to empty string if not available
    baud: "", // Default to empty string if not available
    can_gc: "", // Default to empty string if not available
    can_ot: "", // Default to empty string if not available
    cmd: "", // Default to empty string if not available
    dburl: "", // Default to empty string if not available
    // Additional fields
    displayName: "", // Default to empty string if not available
    company: "", // Default to empty string if not available
    companyId: "", // Default to empty string if not available
    groupCode: "", // Default to empty string if not available
    FW: "1.00", // Default to "1.00" if FW is not available
    lat: "0", // Default to "0" if latitude is not available
    lon: "0", // Default to "0" if longitude is not available
    ban: "", // Default to empty string if ban is not available
    contyp: "0", // Default to "0" if contyp is not available
    dbit: "12", // Default to "12" if dbit is not available
    gate_w: "192.168.5.1", // Default to "192.168.5.1" if gate_w is not available
    grn_ot: "1", // Default to "1" if grn_ot is not available
    iac: "0", // Default to "0" if iac is not available
    input: "", // Default to empty string if input is not available
    mqttip: "", // Default to empty string if mqttip is not available
    org_ot: "", // Default to empty string if org_ot is not available
    p_dns: "", // Default to empty string if p_dns is not available
    parity: "", // Default to empty string if parity is not available
    pass_0: "", // Default to empty string if pass_0 is not available
    pass_1: "", // Default to empty string if pass_1 is not available
    pass_2: "", // Default to empty string if pass_2 is not available
    pass_3: "", // Default to empty string if pass_3 is not available
    pass_4: "", // Default to empty string if pass_4 is not available
    red_ot: "", // Default to empty string if red_ot is not available
    rly_os: "", // Default to empty string if rly_os is not available
    s_dns: "", // Default to empty string if s_dns is not available
    sbit: "", // Default to empty string if sbit is not available
    ssid_0: "", // Default to empty string if ssid_0 is not available
    ssid_1: "", // Default to empty string if ssid_1 is not available
    ssid_2: "", // Default to empty string if ssid_2 is not available
    ssid_3: "", // Default to empty string if ssid_3 is not available
    ssid_4: "", // Default to empty string if ssid_4 is not available
    sta_ip: "", // Default to empty string if sta_ip is not available
    staips: "", // Default to empty string if staips is not available
    subnet: "", // Default to empty string if subnet is not available
    wa_idn: "", // Default to empty string if wa_idn is not available
  });

  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal

  // Toggle modal visibility
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };
  // Handle watch deletion
  const handleDeleteRelayBoard = async () => {
    try {
      // Remove the watch from 'Watches' database path
      await remove(ref(database, `RelayBoards/${id}`));

      // Find users that have this watchId and clear the watchId field
      // const usersRef = ref(database, "users");
      // const snapshot = await get(usersRef);
      // if (snapshot.exists()) {
      //   const updates = {};
      //   snapshot.forEach((childSnapshot) => {
      //     const user = childSnapshot.val();
      //     if (user.watchId === watchId) {
      //       updates[`users/${childSnapshot.key}/watchId`] = null; // Clear watchId
      //     }
      //   });
      //   if (Object.keys(updates).length > 0) {
      //     await update(ref(database), updates); // Apply updates
      //   }
      // }

      // alert("Watch and associated user links deleted successfully.");
      setShowDeleteModal(false); // Close modal
      navigate("/relayboards"); // Redirect to watches list
    } catch (error) {
      console.error("Error deleting relayboard:", error);
      alert("An error occurred while deleting the watch.");
    }
  };

  const fetchAlertStatus = async () => {
    try {
      const alertRef = ref(database, `RelayBoards/${id}/status/alert`);
      const alertSnapshot = await get(alertRef);

      if (alertSnapshot.exists()) {
        const alertString = alertSnapshot.val(); // Get the alert string
        const alarmCode = alertString?.charAt(0); // Extract the alarm code from the alert string

        // Map the alarm code to a status
        const alarmStatusMap = {
          C: "Idle",
          G: "Green",
          O: "Orange",
          R: "Red",
        };

        setCurrentAlarmStatus(alarmStatusMap[alarmCode] || "Unknown"); // Update state with the status

        console.log("this is alarm status", currentAlarmStatus);
      } else {
        setCurrentAlarmStatus("Unknown"); // No alert found
      }
    } catch (error) {
      console.error("Error fetching alert status:", error);
      setCurrentAlarmStatus("Unknown"); // In case of error
    }
  };

  // Use useEffect to fetch the alert status when the component mounts
  useEffect(() => {
    fetchAlertStatus();
  }, []); // Add relayBoard as a dependency if it changes

  const getStatusColor = (status) => {
    switch (status) {
      case "Cancelled":
        return "#939196"; // Color for cancelled status
      case "Green":
        return "#185519"; // Color for green status
      case "Orange":
        return "#CD5C08"; // Color for orange status
      case "Red":
        return "#7D0A0A"; // Color for red status
      case "Unknown":
      default:
        return "gray"; // Default color for unknown status
    }
  };

  useEffect(() => {
    // Fetch user details
    const userRef = ref(database, `users/${currentUser.uid}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      setUser(userData);

      // If role is 2, auto-fill company details
      if (userData.role === 2) {
        setFormValues((prevValues) => ({
          ...prevValues,
          company: userData.companyName,
        }));
      }
    });
  }, [currentUser.uid]);

  const alarmCodeLabels = {
    C: "Cancel",
    G: "Green",
    O: "Orange",
    R: "Red",
  };

  const alarmCodeColors = {
    C: "#3C3D37",
    G: "#185519",
    O: "#CD5C08",
    R: "#7D0A0A",
  };

  // Define the custom icons
  const mapIcon = L.icon({
    iconUrl: mapIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const redIcon = L.icon({
    iconUrl: redRelayBoardIcon,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const orangeIcon = L.icon({
    iconUrl: orangeRelayBoardIcon,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const cancelledIcon = L.icon({
    iconUrl: cancelledRelayBoardIcon,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const greenIcon = L.icon({
    iconUrl: greenRelayBoardIcon,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const icon = (() => {
    if (!currentAlarmStatus) {
      return mapIcon; // Default if no alarm code is provided
    }

    switch (currentAlarmStatus) {
      case "Cancelled":
        return cancelledIcon; // Specify your cancelled icon
      case "Green":
        return greenIcon; // Specify your green icon
      case "Red":
        return redIcon; // Specify your red icon
      case "Orange":
        return orangeIcon; // Specify your orange icon
      default:
        return cancelledIcon; // Use the warning icon for any other cases
    }
  })();

  const mapRef = useRef();

  useEffect(() => {
    const fetchRelayBoardDetails = async () => {
      try {
        const relayBoardRef = ref(database, `RelayBoards/${id}/conf`); // Use dynamic path
        const snapshot = await get(relayBoardRef);

        // Log the snapshot data
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log("Relay Board Data:", data); // Log the fetched data

          // Autofill the form values based on fetched relayBoard details
          setRelayBoardDetails(data);
          setFormValues({
            displayName: data.displayName || "", // Default to empty string if not available
            location: data.locationName || "", // Default to empty string if not available
            company: data.company || "", // Default to empty string if not available
            companyId: data.companyId || "", // Default to empty string if not available
            groupCode: data.groupCode || "", // Default to empty string if not available
            apikey: data.apikey || "", // Default to empty string if not available
            apn: data.apn || "", // Default to empty string if not available
            baud: data.baud || "9600", // Default to "9600" if not available
            can_gc: data.can_gc || "K", // Default to "K" if not available
            can_ot: data.can_ot || "1", // Default to "1" if not available
            grn_gc: data.grn_gc || "", // Default to empty string if not available
            red_gc: data.red_gc || "", // Default to empty string if not available
            org_gc: data.org_gc || "", // Default to empty string if not available
            lo_idn: data.lo_idn || "", // Default to empty string if not available
            co_idn: data.co_idn || "", // Default to empty string if not available
            did: data.did || "", // Default to empty string if not available
            lat: data.lat || "0", // Default to "0" if latitude is not available
            lon: data.lon || "0", // Default to "0" if longitude is not available
            FW: data.FW || "1.00", // Default to "1.00" if FW is not available
            ban: data.ban || "", // Default to empty string if ban is not available
            cmd: data.cmd || "con", // Default to "con" if cmd is not available
            contyp: data.contyp || "0", // Default to "0" if contyp is not available
            dbit: data.dbit || "12", // Default to "12" if dbit is not available
            dburl: data.dburl || "", // Default to empty string if dburl is not available
            gate_w: data.gate_w || "192.168.5.1", // Default to "192.168.5.1" if gate_w is not available
            grn_ot: data.grn_ot || "1", // Default to "1" if grn_ot is not available
            iac: data.iac || "0", // Default to "0" if iac is not available
            input: data.input || "",

            // Add more fields as needed
            // Additional fields for new inputs
            mqttip: data.mqttip || "", // Default to empty string if mqttip is not available
            org_ot: data.org_ot || "", // Default to empty string if org_ot is not available
            p_dns: data.p_dns || "", // Default to empty string if p_dns is not available
            parity: data.parity || "", // Default to empty string if parity is not available
            pass_0: data.pass_0 || "", // Default to empty string if pass_0 is not available
            pass_1: data.pass_1 || "", // Default to empty string if pass_1 is not available
            pass_2: data.pass_2 || "", // Default to empty string if pass_2 is not available
            pass_3: data.pass_3 || "", // Default to empty string if pass_3 is not available
            pass_4: data.pass_4 || "", // Default to empty string if pass_4 is not available
            red_ot: data.red_ot || "", // Default to empty string if red_ot is not available
            rly_os: data.rly_os || "", // Default to empty string if rly_os is not available
            s_dns: data.s_dns || "", // Default to empty string if s_dns is not available
            sbit: data.sbit || "", // Default to empty string if sbit is not available
            ssid_0: data.ssid_0 || "", // Default to empty string if ssid_0 is not available
            ssid_1: data.ssid_1 || "", // Default to empty string if ssid_1 is not available
            ssid_2: data.ssid_2 || "", // Default to empty string if ssid_2 is not available
            ssid_3: data.ssid_3 || "", // Default to empty string if ssid_3 is not available
            ssid_4: data.ssid_4 || "", // Default to empty string if ssid_4 is not available
            sta_ip: data.sta_ip || "", // Default to empty string if sta_ip is not available
            staips: data.staips || "", // Default to empty string if staips is not available
            subnet: data.subnet || "", // Default to empty string if subnet is not available
            wa_idn: data.wa_idn || "", // Default to empty string if wa_idn is not available

            // Add more fields as needed
          });

          console.log(data.lon, data.lat);

          // Fetch location logs
          const logsRef = ref(database, `RelayBoards/${id}/logs/location`);
          onValue(logsRef, (snapshot) => {
            const logsData = snapshot.val();
            if (logsData) {
              const breadcrumbsList = Object.values(logsData)
                .map((log) => [
                  parseFloat(log.latitude),
                  parseFloat(log.longitude),
                  new Date(parseInt(log.timestamp)).toLocaleString(),
                  log.alarmCode,
                ])
                .filter(([lat, lon]) => !isNaN(lat) && !isNaN(lon));

              // Limit to latest 10 breadcrumbs
              const latestBreadcrumbs = breadcrumbsList.slice(0);
              setBreadcrumbs(latestBreadcrumbs);
            } else {
              setBreadcrumbs([]);
            }
          });
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching relay board details:", error);
      }
    };

    fetchRelayBoardDetails();
  }, [id, user]);

  useEffect(() => {
    // On component mount, auto-select company and filter locations
    const initialCompany = companies.find(
      (company) => company.companyId === formValues.co_idn
    );
    setSelectedCompany(initialCompany ? initialCompany.companyId : "");

    // Filter locations based on the initial company
    setFilteredLocations(
      locations.filter((location) => location.companyId === formValues.co_idn)
    );
  }, [companies, locations, formValues.co_idn]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCompanyChange = (e) => {
    const newCompanyId = e.target.value;
    setSelectedCompany(newCompanyId);
    setFormValues((prevValues) => ({
      ...prevValues,
      co_idn: newCompanyId,
      lo_idn: "", // Reset location when company changes
      companyName: newCompanyId.companyName,
    }));

    // Filter locations based on the selected company
    setFilteredLocations(
      locations.filter((location) => location.companyId === newCompanyId)
    );
  };

  const handleLocationChange = (e) => {
    const newLocationId = e.target.value;
    setFormValues((prevValues) => ({
      ...prevValues,
      lo_idn: newLocationId,
      location: e.target.options[e.target.selectedIndex].text, // Get location name
    }));
  };

  const handleSave = async () => {
    const mergedGrnGc = Array.isArray(formValues.grn_gc)
      ? formValues.grn_gc.join("")
      : "";
    const mergedOrgGc = Array.isArray(formValues.org_gc)
      ? formValues.org_gc.join("")
      : "";
    const mergedRedGc = Array.isArray(formValues.red_gc)
      ? formValues.red_gc.join("")
      : "";

      const updatedValues = {
        // Only include the necessary values
        grn_gc: mergedGrnGc, // Updated from merged GrnGc
        org_gc: mergedOrgGc, // Updated from merged OrgGc
        red_gc: mergedRedGc, // Updated from merged RedGc
        
        // Make sure to push only the IDs
        co_idn: formValues.co_idn, // Default value from formValues
        lo_idn: formValues.lo_idn, // Default value from formValues
        
        // Other necessary fields
        did: formValues.did, // Default value from formValues
        apikey: formValues.apikey, // Default value from formValues
        apn: formValues.apn, // Default value from formValues
        baud: formValues.baud, // Default value from formValues
        can_gc: formValues.can_gc, // Default value from formValues
        can_ot: formValues.can_ot, // Default value from formValues
        cmd: formValues.cmd, // Default value from formValues
        dburl: formValues.dburl, // Default value from formValues
        lat: formValues.lat, // Default value from formValues
        lon: formValues.lon, // Default value from formValues
        
        // Additional fields
        FW: formValues.FW, // Default value from formValues
        ban: formValues.ban, // Default value from formValues
        contyp: formValues.contyp, // Default value from formValues
        dbit: formValues.dbit, // Default value from formValues
        gate_w: formValues.gate_w, // Default value from formValues
        grn_ot: formValues.grn_ot, // Default value from formValues
        iac: formValues.iac, // Default value from formValues
        input: formValues.input, // Default value from formValues
        mqttip: formValues.mqttip, // Default value from formValues
        org_ot: formValues.org_ot, // Default value from formValues
        p_dns: formValues.p_dns, // Default value from formValues
        parity: formValues.parity, // Default value from formValues
        pass_0: formValues.pass_0, // Default value from formValues
        pass_1: formValues.pass_1, // Default value from formValues
        pass_2: formValues.pass_2, // Default value from formValues
        pass_3: formValues.pass_3, // Default value from formValues
        pass_4: formValues.pass_4, // Default value from formValues
        red_ot: formValues.red_ot, // Default value from formValues
        rly_os: formValues.rly_os, // Default value from formValues
        s_dns: formValues.s_dns, // Default value from formValues
        sbit: formValues.sbit, // Default value from formValues
        ssid_0: formValues.ssid_0, // Default value from formValues
        ssid_1: formValues.ssid_1, // Default value from formValues
        ssid_2: formValues.ssid_2, // Default value from formValues
        ssid_3: formValues.ssid_3, // Default value from formValues
        ssid_4: formValues.ssid_4, // Default value from formValues
        sta_ip: formValues.sta_ip, // Default value from formValues
        staips: formValues.staips, // Default value from formValues
        subnet: formValues.subnet, // Default value from formValues
        wa_idn: formValues.wa_idn, // Default value from formValues
      };
      

    console.log("this is updated values", updatedValues);

    try {
      // Update the specific 'update' field in 'boards_cmd' to 1
      const boardsCmdRef = ref(
        database,
        `Companies/${formValues.co_idn}/cmd/boards_cmd`
      );
      await update(boardsCmdRef, { update: "1" });

      // Optionally, update other details in RelayBoards if needed
      const relayBoardRef = ref(database, `RelayBoards/${id}/conf`);
      await update(relayBoardRef, updatedValues);

      alert("RelayBoard updated successfully!");
    } catch (error) {
      console.error("Error updating details:", error);
      alert("Failed to update details.");
    }
  };

  useEffect(() => {
    if (user && user.role === 1) {
      const companiesRef = ref(database, "Companies");
      onValue(
        companiesRef,
        (snapshot) => {
          const companiesData = snapshot.val();

          if (companiesData) {
            const companyList = Object.keys(companiesData).map((key) => ({
              companyId: key,
              companyName:
                companiesData[key]?.name ||
                companiesData[key]?.companyName ||
                "Unnamed Company", // Fallback to "Unnamed Company" if name is missing
            }));
            setCompanies(companyList); // Set companies state
          } else {
            setCompanies([]); // In case there is no data, clear the list
          }
        },
        (error) => {
          console.error("Error fetching companies:", error); // Handle errors
          setCompanies([]); // Clear the companies list if there's an error
        }
      );
    }
  }, [user]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locationsRef = ref(database, "locations"); // Path to locations in Firebase
        const snapshot = await get(locationsRef);

        if (snapshot.exists()) {
          const locationsData = snapshot.val();
          const locationsList = Object.keys(locationsData).map((key) => ({
            lo_idn: key, // The location ID
            locationName:
              locationsData[key].name || locationsData[key].locationName, // Use the correct field for location name
            companyId: locationsData[key].companyId, // The associated company ID
          }));

          setLocations(locationsList); // Set the locations state
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    // Filter locations based on the selected company
    setFilteredLocations(
      locations.filter((location) => location.companyId === formValues.co_idn)
    );
  }, [locations, formValues.co_idn]);

  useEffect(() => {
    if (formValues.lat && formValues.lon && mapRef.current) {
      // Update the map view when lat/lon is available
      mapRef.current.setView([formValues.lat, formValues.lon], 30); // Adjust the zoom level as needed
    }
  }, [formValues.lat, formValues.lon]);

  const handleGroupCodeChange = (group, index, value) => {
    const updatedCodes = [...formValues[group]];
    updatedCodes[index] = value.slice(0, 1); // Limit to 1 character
    setFormValues((prevValues) => ({
      ...prevValues,
      [group]: updatedCodes,
    }));
  };

  const addInput = (group) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [group]: [...prevValues[group], ""],
    }));
  };

  const removeInput = (group, index) => {
    const updatedCodes = formValues[group].filter((_, i) => i !== index);
    setFormValues((prevValues) => ({
      ...prevValues,
      [group]: updatedCodes,
    }));
  };

  // ... (Rest of the code remains unchanged)

  const isRole3 =
    currentUser.role === 3 ||
    currentUser.role === undefined ||
    currentUser.role === null ||
    currentUser.role === "";

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Relay Board Information</h1>
            <div className="item">
              <div className="details">
                <span className="itemKey">Relay Board Id:</span>
                <h2 className="itemTitle">{id}</h2>

                <div className="detailItem">
                  <span className="itemKey">LocationId:</span>
                  <span className="itemValue">{formValues.lo_idn}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">CompanyId:</span>
                  <span className="itemValue">{formValues.co_idn}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="alarmStatus">
              <div className="currentStatus">
                <h2>Current Alarm Status</h2>
                <div
                  className="statusLabel"
                  style={{
                    backgroundColor: getStatusColor(currentAlarmStatus), // Determine the background color based on status
                  }}
                >
                  {currentAlarmStatus}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div
            className="editSection"
            style={{
              position: "relative",
              maxHeight: "400px", // Set a fixed height (adjust as necessary)
              overflowY: "auto", // Enable vertical scrolling
              border: "1px solid #ccc", // Optional: Add border for aesthetics
              padding: "20px", // Optional: Add padding for aesthetics
              backgroundColor: "#f9f9f9", // Optional: Change background color
            }}
          >
            <h1 className="title">Edit Relay Board Details</h1>
            <div className="editForm">
              <label>
                DID:
                <input
                  type="text"
                  name="did"
                  value={formValues.did}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                  maxLength={40}
                />
              </label>

              {user && user.role === 1 ? (
                <label>
                  Company:
                  <select
                    value={formValues.co_idn || selectedCompany}
                    onChange={handleCompanyChange}
                    required
                    style={{
                      height: "35px",
                    }}
                  >
                    <option value="">Select Company</option>
                    {companies.map((company) => (
                      <option key={company.companyId} value={company.companyId}>
                        {company.companyName}
                      </option>
                    ))}
                  </select>
                </label>
              ) : (
                <label>
                  Company:
                  <input
                    type="text"
                    name="co_idn"
                    value={formValues.company}
                    onChange={handleInputChange}
                    disabled
                  />
                </label>
              )}

              {user && user.role !== 3 ? (
                <label>
                  Location:
                  <select
                    value={formValues.lo_idn}
                    onChange={handleLocationChange}
                    required
                    style={{
                      height: "35px",
                    }}
                  >
                    <option value="">Select Location</option>
                    {filteredLocations.map((location) => (
                      <option key={location.lo_idn} value={location.lo_idn}>
                        {location.locationName}{" "}
                        {/* Assuming locationName is the name you want to display */}
                      </option>
                    ))}
                  </select>
                </label>
              ) : (
                <label>
                  Location:
                  <input
                    type="text"
                    name="lo_idn"
                    value={formValues.location}
                    onChange={handleInputChange}
                    disabled
                  />
                </label>
              )}

              {/* <label>
                Location:
                <input
                  type="text"
                  name="location"
                  value={formValues.location}
                  onChange={handleInputChange}
                />
              </label> */}

              {["grn_gc", "org_gc", "red_gc"].map((group) => (
                <div key={group}>
                  <label>{group.replace("_", " ").toUpperCase()}:</label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {Array.isArray(formValues[group]) &&
                      formValues[group].map((code, index) => (
                        <div key={index} style={{ display: "inline-block" }}>
                          <input
                            type="text"
                            name={group}
                            value={code}
                            onChange={(e) =>
                              handleGroupCodeChange(
                                group,
                                index,
                                e.target.value
                              )
                            }
                            style={{
                              width: "30px",
                              textAlign: "center",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                              padding: "5px",
                            }}
                            maxLength={1}
                            disabled={isRole3} // Disable input fields for role 3
                          />
                          <button
                            type="button"
                            onClick={() => removeInput(group, index)}
                            style={{
                              border: "none",
                              cursor: "pointer",
                            }}
                            disabled={isRole3} // Disable remove button for role 3
                          >
                            -
                          </button>
                        </div>
                      ))}
                    <button
                      type="button"
                      onClick={() => addInput(group)}
                      style={{
                        border: "solid black 0.3px",
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                      disabled={isRole3} // Disable add button for role 3
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}

              <label>
                Green Group Code:
                <input
                  type="text"
                  name="grn_gc"
                  value={formValues.grn_gc}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                />
              </label>

              <label>
                Orange Group Code:
                <input
                  type="text"
                  name="org_gc"
                  value={formValues.org_gc}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                />
              </label>

              <label>
                Red Group Code:
                <input
                  type="text"
                  name="red_gc"
                  value={formValues.red_gc}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                />
              </label>

              {/* New fields for relay board */}
              <label>
                API Key:
                <input
                  type="text"
                  name="apikey"
                  value={formValues.apikey}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                />
              </label>

              <label>
                APN:
                <input
                  type="text"
                  name="apn"
                  value={formValues.apn}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                  maxLength={30}
                />
              </label>

              <label>
                Baud Rate:
                <input
                  type="text"
                  name="baud"
                  value={formValues.baud}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                  maxLength={18}
                />
              </label>

              <label>
                CAN GC:
                <input
                  type="text"
                  name="can_gc"
                  value={formValues.can_gc}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                />
              </label>

              <label>
                CAN OT:
                <input
                  type="text"
                  name="can_ot"
                  value={formValues.can_ot}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                />
              </label>

              <label>
                Command:
                <input
                  type="text"
                  name="cmd"
                  value={formValues.cmd}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                />
              </label>

              <label>
                DB URL:
                <input
                  type="text"
                  name="dburl"
                  value={formValues.dburl}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                />
              </label>

              {/* New Fields */}
              <label>
                MQTT IP:
                <input
                  type="text"
                  name="mqttip"
                  value={formValues.mqttip}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Orange Group Code:
                <input
                  type="text"
                  name="org_gc"
                  value={formValues.org_gc}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Orange Group OT:
                <input
                  type="text"
                  name="org_ot"
                  value={formValues.org_ot}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Primary DNS:
                <input
                  type="text"
                  name="p_dns"
                  value={formValues.p_dns}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Parity:
                <input
                  type="text"
                  name="parity"
                  value={formValues.parity}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Password 0:
                <input
                  type="text"
                  name="pass_0"
                  value={formValues.pass_0}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Password 1:
                <input
                  type="text"
                  name="pass_1"
                  value={formValues.pass_1}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Password 2:
                <input
                  type="text"
                  name="pass_2"
                  value={formValues.pass_2}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Password 3:
                <input
                  type="text"
                  name="pass_3"
                  value={formValues.pass_3}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Password 4:
                <input
                  type="text"
                  name="pass_4"
                  value={formValues.pass_4}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Red Group Code:
                <input
                  type="text"
                  name="red_gc"
                  value={formValues.red_gc}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Red Group OT:
                <input
                  type="text"
                  name="red_ot"
                  value={formValues.red_ot}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Relay OS:
                <input
                  type="text"
                  name="rly_os"
                  value={formValues.rly_os}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Secondary DNS:
                <input
                  type="text"
                  name="s_dns"
                  value={formValues.s_dns}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Serial Bit Rate:
                <input
                  type="text"
                  name="sbit"
                  value={formValues.sbit}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                SSID 0:
                <input
                  type="text"
                  name="ssid_0"
                  value={formValues.ssid_0}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                SSID 1:
                <input
                  type="text"
                  name="ssid_1"
                  value={formValues.ssid_1}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                SSID 2:
                <input
                  type="text"
                  name="ssid_2"
                  value={formValues.ssid_2}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                SSID 3:
                <input
                  type="text"
                  name="ssid_3"
                  value={formValues.ssid_3}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                SSID 4:
                <input
                  type="text"
                  name="ssid_4"
                  value={formValues.ssid_4}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Static IP:
                <input
                  type="text"
                  name="sta_ip"
                  value={formValues.sta_ip}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Static IPs:
                <input
                  type="text"
                  name="staips"
                  value={formValues.staips}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Subnet:
                <input
                  type="text"
                  name="subnet"
                  value={formValues.subnet}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <label>
                Wa ID:
                <input
                  type="text"
                  name="wa_idn"
                  value={formValues.wa_idn}
                  onChange={handleInputChange}
                  disabled={isRole3}
                />
              </label>

              <button className="saveButton" onClick={handleSave}>
                Update Relay Board
              </button>
              <button
                style={{
                  color: "red",
                }}
                onClick={handleDeleteClick}
              >
                Delete Relay Board
              </button>
            </div>
            {showDeleteModal && (
              <div className="deleteModal">
                <div className="deleteModalContent">
                  <p>Are you sure you want to delete this Relay Board?</p>
                  <p
                    style={{
                      color: "gray",
                      fontSize: "12px",
                    }}
                  ></p>
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    Please be aware: Deleting a Relay Board is irreversible and
                    cannot be undone.{" "}
                  </p>
                  <div className="">
                    <button
                      className="cancel"
                      onClick={() => {
                        handleDeleteRelayBoard();
                        alert("Relay Board Deleted");
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="confirm"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
            <style jsx>{`
              .deleteModal {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1000;
              }

              .deleteModalContent {
                background-color: white;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
                width: 300px;
                text-align: center;
              }

              .deleteModal p {
                font-size: 18px;
                margin-bottom: 20px;
                color: #333; /* Dark text for better readability */
              }

              .deleteModal button {
                margin: 5px;
                padding: 10px 20px;
                font-size: 16px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
              }

              .deleteModal button.confirm {
                background-color: #e74c3c;
                color: white;
              }

              .deleteModal button.cancel {
                background-color: #95a5a6;
                color: white;
              }

              .deleteModalButtons {
                display: flex;
                justify-content: space-between;
              }
            `}</style>
          </div>

          <div className="mapSection">
            <div className="mapSection">
              <MapContainer
                center={[0, 0]} // Set the initial center of the map (latitude, longitude)
                zoom={2} // Set an appropriate zoom level
                style={{ height: "400px", width: "100%" }}
                ref={mapRef} // Assign the ref to the MapContainer
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                {formValues.lat && formValues.lon && (
                  <Marker
                    position={[formValues.lat, formValues.lon]}
                    icon={icon}
                  >
                    <Popup>
                      <h3>Current Location</h3>
                      <p>{`Latitude: ${formValues.lat}, Longitude: ${formValues.lon}`}</p>
                    </Popup>
                  </Marker>
                )}
              </MapContainer>
            </div>
          </div>
        </div>
        <div>
          <SingleRelayBoardDataTable cid={""} lid={""} />
        </div>
      </div>
    </div>
  );
};

export default SingleRelayBoard;
