import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  // Save user to localStorage on change
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);

  // Manage active tabs and logout when all tabs are closed
  useEffect(() => {
    const tabCountKey = "activeTabs";

    const incrementTabCount = () => {
      const activeTabs = parseInt(localStorage.getItem(tabCountKey) || "0", 10);
      localStorage.setItem(tabCountKey, activeTabs + 1);
    };

    const decrementTabCount = () => {
      const activeTabs = parseInt(localStorage.getItem(tabCountKey) || "0", 10);
      const newCount = Math.max(0, activeTabs - 1);
      localStorage.setItem(tabCountKey, newCount);
      if (newCount === 0) {
        localStorage.removeItem("user");
        dispatch({ type: "LOGOUT" });
      }
    };

    incrementTabCount();

    window.addEventListener("beforeunload", decrementTabCount);

    return () => {
      decrementTabCount();
      window.removeEventListener("beforeunload", decrementTabCount);
    };
  }, [dispatch]);

  // Handle user inactivity and logout after 30 minutes
  useEffect(() => {
    if (!state.currentUser) return; // Skip inactivity logic if no user

    let inactivityTimer;

    const resetTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        alert("Logged out due to inactivity");
        localStorage.removeItem("user");
        dispatch({ type: "LOGOUT" });
      }, 30 * 60 * 1000); // 30 minutes
    };

    // Add event listeners for user activity
    const events = ["mousemove", "keydown", "scroll", "click"];
    events.forEach(event =>
      window.addEventListener(event, resetTimer)
    );

    // Reset the timer when the tab is accessed
    resetTimer();

    // Clean up event listeners
    return () => {
      events.forEach(event =>
        window.removeEventListener(event, resetTimer)
      );
      clearTimeout(inactivityTimer);
    };
  }, [state.currentUser, dispatch]);

  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
