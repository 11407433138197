import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/AuthContext";

import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import {
  billingInput,
  companyInputs,
  locationInputs,
  productInputs,
  relayBoardInput,
  userInputs,
  watchInputs,
} from "./formSource";

import NewWatch from "./pages/new/NewWatch";
import WatchList from "./pages/list/WatchList";
import RelayBoardList from "./pages/list/RelayBoardList";
import NewRelayBoard from "./pages/new/NewRelayBoard";
import NewCompany from "./pages/new/NewCompany";
import CompanyList from "./pages/list/CompanyList";
import LocationList from "./pages/list/LocationList";
import NewLocation from "./pages/new/NewLocation";
import SingleWatch from "./pages/single/SingleWatch";
import SingleCompany from "./pages/single/singleCompany";
import SingleLocation from "./pages/single/SingleLocation";
import LogsList from "./pages/list/LogsList";
import SingleRelayBoard from "./pages/single/singleRelayBoard";
import NewWatchCompany from "./pages/new/NewWatchCompany";
import NoCompanyWatchesList from "./pages/list/NoCompanyWatchesList";

import "./style/dark.scss";
import BillingList from "./pages/list/Billing";
import SingleBilling from "./pages/single/SingleBilling";
import NewBilling from "./pages/new/NewBilling";
import BulkPlanningList from "./pages/list/BulkPlanningList";
import SingleBillingPage from "./pages/list/SingleBillingPage";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { currentUser } = useContext(AuthContext);

  // Protect routes to make sure only logged-in users can access them
  const RequireAuth = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    // Prevent users with role 4 from accessing the application
    if (currentUser.role === 4) {
      alert("Access denied. Your role does not permit logging in.");
      return <Navigate to="/login" />;
    }

    return children;
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            {/* Redirect logged-in users away from the login page */}
            <Route
              path="login"
              element={!currentUser ? <Login /> : <Navigate to="/" />}
            />

            {/* Protected Routes */}
            <Route
              index
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <RequireAuth>
                    <List />
                  </RequireAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAuth>
                    <Single />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <New inputs={userInputs} title="Add New User" />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="watches">
              <Route
                index
                element={
                  <RequireAuth>
                    <WatchList />
                  </RequireAuth>
                }
              />
              <Route
                path=":watchId"
                element={
                  <RequireAuth>
                    <SingleWatch />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewWatch
                      inputs={watchInputs}
                      title="Assign Watch to a User"
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="new-watch-company"
                element={
                  <RequireAuth>
                    <NoCompanyWatchesList
                      inputs={watchInputs}
                      title="Assign Watch to a Company"
                    />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="relayboards">
              <Route
                index
                element={
                  <RequireAuth>
                    <RelayBoardList />
                  </RequireAuth>
                }
              />
              <Route
                path=":relayBoardId"
                element={
                  <RequireAuth>
                    <SingleRelayBoard />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewRelayBoard
                      inputs={relayBoardInput}
                      title="Add New Relay Board"
                    />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="companies">
              <Route
                index
                element={
                  <RequireAuth>
                    <CompanyList />
                  </RequireAuth>
                }
              />
              <Route
                path=":companyId"
                element={
                  <RequireAuth>
                    <SingleCompany />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewCompany
                      inputs={companyInputs}
                      title="Add New Company"
                    />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="locations">
              <Route
                index
                element={
                  <RequireAuth>
                    <LocationList />
                  </RequireAuth>
                }
              />
              <Route
                path=":locationId"
                element={
                  <RequireAuth>
                    <SingleLocation />
                  </RequireAuth>
                }
              />
              <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewLocation
                      inputs={locationInputs}
                      title="Add New Location"
                    />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="logs">
              <Route
                index
                element={
                  <RequireAuth>
                    <LogsList />
                  </RequireAuth>
                }
              />
            </Route>

            <Route path="billing">
              <Route index element={<BillingList />} />
              <Route
                path=":companyId"
                element={
                  <RequireAuth>
                    <SingleBillingPage />
                  </RequireAuth>
                }
              />
                <Route
                path="new"
                element={
                  <RequireAuth>
                    <NewBilling
                      inputs={billingInput}
                      title="Add New Plan"
                    />
                  </RequireAuth>
                }
              />
                <Route
                path="subscribe-to-plan"
                element={
                  <RequireAuth>
                    <BulkPlanningList
                      inputs={billingInput}
                      title="Subscribe to a Plan"
                    />
                  </RequireAuth>
                }
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
