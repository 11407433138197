import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { ref, onValue, remove, get } from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
} from "@mui/material";

const Datatable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  useEffect(() => {
    const userRef = ref(database, "users");
    const companiesRef = ref(database, "Companies");
    const locationsRef = ref(database, "locations");

    const unsubscribeUsers = onValue(userRef, (snapshot) => {
      let list = [];
      snapshot.forEach((childSnapshot) => {
        const userData = { id: childSnapshot.key, ...childSnapshot.val() };

        if (
          currentUser.role === 1 ||
          (currentUser.role === 2 &&
            userData.companyId === currentUser.companyId) ||
          (currentUser.role === 3 &&
            userData.companyId === currentUser.companyId &&
            userData.id === currentUser.uid)
        ) {
          list.push(userData);
        }
      });
      setData(list);
    });

    const unsubscribeCompanies = onValue(companiesRef, (snapshot) => {
      const companiesList = [];
      snapshot.forEach((childSnapshot) => {
        companiesList.push({
          id: childSnapshot.key,
          ...childSnapshot.val(),
        });
      });
      setCompanies(companiesList);
    });

    const unsubscribeLocations = onValue(locationsRef, (snapshot) => {
      const locationsList = [];
      snapshot.forEach((childSnapshot) => {
        locationsList.push({
          id: childSnapshot.key,
          ...childSnapshot.val(),
        });
      });
      setLocations(locationsList);
    });

    return () => {
      unsubscribeUsers();
      unsubscribeCompanies();
      unsubscribeLocations();
    };
  }, [currentUser]);

  // Filter data based on selected company, location, and search query
  useEffect(() => {
    const filtered = data.filter((user) => {
      const matchesCompany =
        !selectedCompany || user.companyId === selectedCompany;
      const matchesLocation =
        !selectedLocation || user.locationId === selectedLocation;
      const matchesSearchQuery =
        !searchQuery ||
        user.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase()) || user.id.toLowerCase().includes(searchQuery.toLocaleLowerCase());
      return matchesCompany && matchesLocation && matchesSearchQuery;
    });
    setFilteredData(filtered);
  }, [data, selectedCompany, selectedLocation, searchQuery]);

  const handleDelete = async (id) => {
    try {
      const userRef = ref(database, `users/${id}`);
      const snapshot = await get(userRef);
      if (!snapshot.exists()) return;

      const userToDelete = snapshot.val();

      if (
        currentUser.role === 1 ||
        (currentUser.role === 2 &&
          currentUser.companyId === userToDelete.companyId)
      ) {
        await remove(userRef);
        setData((prevData) => prevData.filter((item) => item.id !== id));
      } else {
        console.log("Access denied: insufficient permissions.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div className="cellAction">
          <Link
            to={`/users/${params.row.id}`}
            style={{ textDecoration: "none" }}
          >
            <div className="viewButton">View</div>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        User List
        {currentUser.role !== 3 && (
          <Link to="/users/new" className="link">
            Add User
          </Link>
        )}
      </div>

      <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="company-filter-label">Company</InputLabel>
          <Select
            labelId="company-filter-label"
            value={selectedCompany}
            label="Company"
            onChange={(e) => setSelectedCompany(e.target.value)}
          >
            <MenuItem value="">
              <em>All Companies</em>
            </MenuItem>
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="location-filter-label">Location</InputLabel>
          <Select
            labelId="location-filter-label"
            value={selectedLocation}
            label="Location"
            onChange={(e) => setSelectedLocation(e.target.value)}
            disabled={!selectedCompany}
          >
            <MenuItem value="">
              <em>All Locations</em>
            </MenuItem>
            {locations
              .filter(
                (location) =>
                  !selectedCompany || location.companyId === selectedCompany
              )
              .map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.locationName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {/* Search filter input */}
        <TextField
          label="Search by ID, Name or Email"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: 300 }}
        />
      </Box>

      <DataGrid
        className="datagrid"
        rows={filteredData}
        columns={userColumns.concat(actionColumn)}
        pageSize={19}
        rowsPerPageOptions={[19]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
