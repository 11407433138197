import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./widget.scss";
import {
  PersonOutlined as PersonOutlinedIcon,
  Watch as WatchIcon,
  Router as RouterIcon,
  NotificationImportant as NotificationImportantIcon,
} from "@mui/icons-material";
import { ref, get } from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";

const Widget = ({ type }) => {
  const [count, setCount] = useState(0);
  const [timeFilter, setTimeFilter] = useState("today"); // state for the time filter dropdown
  const [diff] = useState(20);
  const navigate = useNavigate();
  let data;

  const { currentUser } = useContext(AuthContext);
  const userRole = currentUser.role;
  const companyId = currentUser.companyId;

  // Helper function to filter by time range
  const filterByTime = (timestamp) => {
    const now = Date.now(); // current time in milliseconds
    const todayStart = new Date(now).setHours(0, 0, 0, 0); // Start of today (midnight)
    const weekStart = new Date(now - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0); // Start of last week
    const monthStart = new Date(now - 30 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0); // Start of last month

    switch (timeFilter) {
      case "today":
        return timestamp >= todayStart;
      case "week":
        return timestamp >= weekStart;
      case "month":
        return timestamp >= monthStart;
      case "all":
      default:
        return true; // no filtering
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let dataRef;

      switch (type) {
        case "user":
          dataRef = ref(database, "users");
          break;
        case "order":
          dataRef = ref(database, "Watches");
          break;
        case "earning":
          dataRef = ref(database, "RelayBoards");
          break;
        case "balance":
          dataRef = ref(database, "logs/alarm");
          break;
        default:
          return;
      }

      try {
        const snapshot = await get(dataRef);
        let filteredCount = 0;

        if (type === "balance") {
          // For alarms, we only count those that don't have alarmCode "C" and match the time filter
          snapshot.forEach((childSnapshot) => {
            const alarmData = childSnapshot.val();
            const timestamp = alarmData.timestamp; // Assuming you have a timestamp field

            if (alarmData.alarmCode !== "C" && filterByTime(timestamp)) {
              filteredCount++;
            }
          });
          setCount(filteredCount);
        } else if (type === "earning") {
          snapshot.forEach((relayBoardSnapshot) => {
            const relayBoardId = relayBoardSnapshot.key;
            const confData = relayBoardSnapshot.child("conf").val();
            const boardCompanyId = confData?.co_idn;

            if (userRole !== 2 && userRole !== 3 || boardCompanyId === companyId) {
              filteredCount++;
            }
          });
          setCount(filteredCount);
        } else {
          snapshot.forEach((childSnapshot) => {
            const childCompanyId = childSnapshot.val().companyId;
            const childRole = Number(childSnapshot.val().role);

            if (userRole === 2 || userRole === 3) {
              if (childCompanyId === companyId) {
                filteredCount++;
              }
            } else {
              filteredCount++;
            }
          });
          setCount(filteredCount);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [type, userRole, companyId, timeFilter]); // Re-run whenever the filter changes

  // Handle change in the time filter dropdown
  const handleFilterChange = (event) => {
    setTimeFilter(event.target.value);
  };

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "See all users",
        route: "/users",
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "Watches",
        isMoney: false,
        link: "View all watches",
        route: "/watches",
        icon: (
          <WatchIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "Relay Boards",
        isMoney: false,
        link: "View all Relay Boards",
        route: "/relayboards",
        icon: (
          <RouterIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "Alarms Triggered",
        isMoney: false,
        link: "See Logs",
        route: "/logs",
        icon: (
          <NotificationImportantIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <div className="counter-dropdown-container">
    <span className="counter">{count}</span>
    
    {/* Dropdown Filter */}
    {type === "balance" && (
      <select value={timeFilter} onChange={handleFilterChange} className="time-filter-dropdown">
        <option value="all">All Time</option>
        <option value="today">Today</option>
        <option value="week">Last Week</option>
        <option value="month">Last Month</option>
      </select>
    )}
  </div>
        
        <span className="link" style={{ cursor: "pointer", color: "black" }}  onClick={() => navigate(data.route)}>
          {data.link}
        </span>
      </div>
      <div className="right">
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
