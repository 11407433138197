import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext } from "react";
import { ref, get, update, remove } from "firebase/database"; // Import update for updating data
import { database } from "../../firebase";
import CompanyWatches from "../../components/table/CompanyWatchesTable";
import CompanyWatchesMap from "../../components/chart/CompanyWatchesMap";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import useCountryList from "react-select-country-list"; // Import country list library

const SingleCompany = () => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyId1, setCompanyId1] = useState(null);
  const [newCompanyName, setNewCompanyName] = useState("");
  const [companyImage, setCompanyImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate(); // Initialize navigate for redirection
  const [alarmReceiverConfig, setAlarmReceiverConfig] = useState({
    en: false,
    server: {
      primary: { host: "", port: "" },
      backup: { host: "", port: "" },
      hb: "",
      reconnect: "",
    },
  });

  const { currentUser } = useContext(AuthContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const [countdown, setCountdown] = useState(10); // 10-second countdown timer
  const [isDisabled, setIsDisabled] = useState(true);
  const countryOptions = useCountryList().getData();
  // Toggle modal visibility
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  useEffect(() => {
    let timer;
    if (showDeleteModal) {
      setCountdown(10); // Reset countdown every time the modal opens
      setIsDisabled(true); // Disable button initially

      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsDisabled(false); // Enable button when countdown reaches zero
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Clear timer on component unmount or when modal closes
  }, [showDeleteModal]);

  // Handle company deletion
  const handleDeleteCompany = async () => {
    try {
      // Step 1: Remove the company from the "Companies" database
      await remove(ref(database, `Companies/${companyId1}`));

      // Step 2: Find and delete all locations that match the companyId
      const locationsRef = ref(database, "locations");
      const locationsSnapshot = await get(locationsRef);
      if (locationsSnapshot.exists()) {
        const locationsToDelete = [];
        locationsSnapshot.forEach((childSnapshot) => {
          const location = childSnapshot.val();
          if (location.companyId === companyId1) {
            locationsToDelete.push(childSnapshot.key); // Store the location keys to delete
          }
        });
        // Delete locations
        for (const locationId of locationsToDelete) {
          await remove(ref(database, `locations/${locationId}`));
        }
      }

      // Step 3: Find and delete users with matching companyId
      const usersRef = ref(database, "users");
      const usersSnapshot = await get(usersRef);
      if (usersSnapshot.exists()) {
        const usersToDelete = [];
        usersSnapshot.forEach((childSnapshot) => {
          const user = childSnapshot.val();
          if (user.companyId === companyId1) {
            usersToDelete.push(childSnapshot.key); // Store the user keys to delete
          }
        });
        // Delete users
        for (const userId of usersToDelete) {
          await remove(ref(database, `users/${userId}`));
        }
      }

      // Step 4: Find watches with matching companyId and clear their companyId and locationId fields
      const watchesRef = ref(database, "Watches");
      const watchesSnapshot = await get(watchesRef);
      if (watchesSnapshot.exists()) {
        const watchUpdates = {};
        watchesSnapshot.forEach((childSnapshot) => {
          const watch = childSnapshot.val();
          if (watch.companyId === companyId1) {
            watchUpdates[`Watches/${childSnapshot.key}/companyId`] = ""; // Clear companyId
            watchUpdates[`Watches/${childSnapshot.key}/locationId`] = ""; // Clear locationId
            watchUpdates[`Watches/${childSnapshot.key}/companyName`] = ""; // Clear locationId
            watchUpdates[`Watches/${childSnapshot.key}/locationName`] = ""; // Clear locationId
            watchUpdates[`Watches/${childSnapshot.key}/displayName`] = ""; // Clear locationId
            watchUpdates[`Watches/${childSnapshot.key}/userId`] = ""; // Clear userId
            watchUpdates[`Watches/${childSnapshot.key}/isActive`] = false; // Clear userId
          }
        });
        if (Object.keys(watchUpdates).length > 0) {
          await update(ref(database), watchUpdates); // Apply watch updates
        }
      }

      // Step 5: Find and clear RelayBoards with matching companyId
      const relayBoardsRef = ref(database, "RelayBoards");
      const relayBoardsSnapshot = await get(relayBoardsRef);
      if (relayBoardsSnapshot.exists()) {
        const relayBoardUpdates = {};
        relayBoardsSnapshot.forEach((childSnapshot) => {
          const relayBoard = childSnapshot.val();
          if (relayBoard.conf?.cid === companyId1) {
            relayBoardUpdates[`RelayBoards/${childSnapshot.key}/conf/cid`] = ""; // Clear companyId in RelayBoard
            relayBoardUpdates[`RelayBoards/${childSnapshot.key}/conf/lid`] = ""; // Clear locationId in RelayBoard
          }
        });
        if (Object.keys(relayBoardUpdates).length > 0) {
          await update(ref(database), relayBoardUpdates); // Apply relayBoard updates
        }
      }

      alert("Company and associated data deleted successfully.");
      setShowDeleteModal(false); // Close the modal
      navigate("/companies"); // Redirect to companies list
    } catch (error) {
      console.error("Error deleting company:", error);
      alert("An error occurred while deleting the company.");
    }
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const path = window.location.pathname;
        const id = path.split("/").pop();
        setCompanyId1(id);

        const companyRef = ref(database, `Companies/${id}`);
        const snapshot = await get(companyRef);

        if (snapshot.exists()) {
          const data = snapshot.val();
          setCompanyDetails(data);
          setNewCompanyName(data.companyName);
          setImageUrl(data.companyImage || "");
          setAlarmReceiverConfig(data.ar || alarmReceiverConfig);

          if (currentUser.userRole === 3 && currentUser.companyId !== data.id) {
            navigate("/unauthorized");
          } else if (
            currentUser.userRole === 2 &&
            currentUser.companyId !== data.id
          ) {
            navigate("/unauthorized");
          }
        } else {
          console.log("No data available");
          navigate("/not-found");
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
        navigate("/error");
      }
    };

    fetchCompanyDetails();
  }, []);

  const handleToggleEn = () => {
    setAlarmReceiverConfig((prevConfig) => ({
      ...prevConfig,
      en: !prevConfig.en,
    }));
  };

  const handleAlarmReceiverChange = (field, subField, value) => {
    setAlarmReceiverConfig((prevConfig) => ({
      ...prevConfig,
      server: {
        ...prevConfig.server,
        [field]: {
          ...prevConfig.server[field],
          [subField]: value,
        },
      },
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Check file type (allow only PNG)
      if (file.type !== "image/png") {
        alert("Only PNG files are allowed.");
        return;
      }

      // Check file size (limit to 1MB)
      const maxSizeInBytes = 1 * 1024 * 1024; // 1MB in bytes
      if (file.size > maxSizeInBytes) {
        alert("File size must be less than 1MB.");
        return;
      }

      const img = new Image();
      img.onload = () => {
        // Check image dimensions and orientation (landscape)
        const isLandscape = img.width > img.height;
        const minWidth = 800; // example minimum width
        const minHeight = 450; // example minimum height

        if (!isLandscape || img.width < minWidth || img.height < minHeight) {
          alert(
            `Image must be landscape (width > height) and at least ${minWidth}px wide by ${minHeight}px tall.`
          );
          return;
        }

        // Proceed with setting the image if it meets all conditions
        const reader = new FileReader();
        reader.onloadend = () => {
          setCompanyImage(reader.result);
        };
        reader.readAsDataURL(file);
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleSaveAlarmReceiverConfig = async () => {
    try {
      // Step 1: Push the initial alarm receiver configuration to Firebase
      await update(
        ref(database, `Companies/${companyId1}/ar`),
        alarmReceiverConfig
      );

      alert("Alarm receiver configuration updated successfully!");

      // Step 2: Set 'en' to false in Firebase
      console.log("Setting 'en' to false...");
      await update(ref(database, `Companies/${companyId1}/ar`), {
        ...alarmReceiverConfig,
        en: false,
      });

      // Update local state to reflect 'false' temporarily
      setAlarmReceiverConfig((prevConfig) => ({ ...prevConfig, en: false }));

      // Delay to ensure Firebase registers the false state
      await new Promise((resolve) => setTimeout(resolve, 500)); // Increased delay to 500ms

      // Step 3: Set 'en' back to true in Firebase
      console.log("Setting 'en' back to true...");
      await update(ref(database, `Companies/${companyId1}/ar`), {
        ...alarmReceiverConfig,
        en: true,
      });

      // Update local state to reflect 'true' again
      setAlarmReceiverConfig((prevConfig) => ({ ...prevConfig, en: true }));

      console.log(
        "Alarm receiver 'en' successfully toggled from false to true."
      );
    } catch (error) {
      console.error("Error updating alarm receiver configuration:", error);
      alert("Failed to update alarm receiver configuration.");
    }
  };

  const handleUpdate = async () => {
    try {
      const updatedData = {
        companyName: newCompanyName,
        companyImage: companyImage,
        address: companyDetails.address, // Add address
        city: companyDetails.city, // Add city
        state: companyDetails.state, // Add state
        postalCode: companyDetails.postalCode, // Add postal code
        country: companyDetails.country, // Add country
        phone: companyDetails.phone, // Add phone
      };

      await update(ref(database, `Companies/${companyId1}`), updatedData);
      alert("Company details updated successfully");
    } catch (error) {
      console.error("Error updating company details:", error);
      alert("Failed to update company details.");
    }
  };

  const handleFieldChange = (field, value) => {
    setCompanyDetails((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  if (!companyDetails) return <div>Loading...</div>;

  const { address, city, state, postalCode, country, phone } = companyDetails;

  const isRole3 =
    currentUser.role === 3 ||
    currentUser.role === undefined ||
    currentUser.role === null ||
    currentUser.role === "";

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            {imageUrl && (
              <div className="detailItem">
                {/* <span className="itemKey">Company Image:</span> */}
                <img
                  src={imageUrl}
                  alt="Company"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            )}
            <h1 className="title">Company Information</h1>
            <div className="item">
              <div className="details">
                <h1 className="itemTitle">{companyDetails.companyName}</h1>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{address}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">City:</span>
                  <span className="itemValue">{city}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">State:</span>
                  <span className="itemValue">{state}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Postal Code:</span>
                  <span className="itemValue">{postalCode}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{country}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{phone}</span>
                </div>
                {/* Display the company image if it exists */}
              </div>
            </div>
          </div>
          <div className="right">
            <CompanyWatchesMap
              aspect={3 / 1}
              title="Devices"
              companyId={companyId1}
            />
          </div>
        </div>
        <div className="bottom">
          <div
            className="editSection"
            style={{
              position: "relative",
              maxHeight: "400px", // Set a fixed height (adjust as necessary)
              overflowY: "auto", // Enable vertical scrolling
              border: "1px solid #ccc", // Optional: Add border for aesthetics
              padding: "20px", // Optional: Add padding for aesthetics
              backgroundColor: "#f9f9f9", // Optional: Change background color
            }}
          >
            <div className="editForm">
              <h2
                style={{
                  fontWeight: "300",
                }}
              >
                Edit Company Details
              </h2>
              <div className="editForm">
                <label>
                  Company Name:
                  <input
                    type="text"
                    value={newCompanyName}
                    onChange={(e) => setNewCompanyName(e.target.value)}
                    disabled={isRole3} // Disable for role 3
                    maxLength={40}
                  />
                </label>

                <label>
                  Address:
                  <input
                    type="text"
                    value={companyDetails.address || ""} // Pre-fill with existing address
                    onChange={(e) =>
                      handleFieldChange("address", e.target.value)
                    } // Correctly use handleFieldChange
                    disabled={isRole3} // Disable for role 3
                    maxLength={60}
                  />
                </label>

                <label>
                  City:
                  <input
                    type="text"
                    value={companyDetails.city || ""} // Pre-fill with existing address
                    onChange={(e) =>
                      setCompanyDetails((prev) => ({
                        ...prev,
                        city: e.target.value,
                      }))
                    } // Update address in state
                    disabled={isRole3} // Disable for role 3
                    maxLength={20}
                  />
                </label>

                <label>
                  Postal Code:
                  <input
                    type="text"
                    value={companyDetails.postalCode || ""} // Pre-fill with existing address
                    onChange={(e) =>
                      setCompanyDetails((prev) => ({
                        ...prev,
                        postalCode: e.target.value,
                      }))
                    } // Update address in state
                    disabled={isRole3} // Disable for role 3
                    maxLength={14}
                  />
                </label>

                <label>
                  State:
                  <input
                    type="text"
                    value={companyDetails.state || ""} // Pre-fill with existing address
                    onChange={(e) =>
                      setCompanyDetails((prev) => ({
                        ...prev,
                        state: e.target.value,
                      }))
                    } // Update address in state
                    disabled={isRole3} // Disable for role 3
                    maxLength={20}
                  />
                </label>

                <label>
                  Country:
                  <select
                    value={companyDetails.country || ""} // Pre-fill with existing country or empty string
                    onChange={(e) =>
                      setCompanyDetails((prev) => ({
                        ...prev,
                        country: e.target.value, // Update the country in state when selected
                      }))
                    }
                    disabled={isRole3} // Disable for role 3
                    style={{
                      height: "40px",
                      border: "1px solid gray",
                    }}
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    {countryOptions.map((country) => (
                      <option key={country.value} value={country.label}>
                        {country.label}
                      </option>
                    ))}
                  </select>
                </label>

                <label>
                  Office Phone Number:
                  <input
                    type="text"
                    value={companyDetails.phone || ""} // Pre-fill with existing address
                    onChange={(e) =>
                      setCompanyDetails((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    } // Update address in state
                    disabled={isRole3} // Disable for role 3
                    maxLength={20}
                  />
                </label>
              </div>
              <div className="editField">
                <label>
                  Upload Company Image:
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    disabled={isRole3} // Disable for role 3
                  />
                  <p style={{ fontSize: "0.7em", color: "red" }}>
                    Please upload a landscape image with a file size under 1MB
                    and minimum dimensions of 800x450 pixels.
                  </p>
                </label>
                {/* {companyDetails.companyImage && (
                  <button onClick={() => setCompanyImage("") }>
                    Remove Company Image
                  </button>
                )} */}
              </div>

              {companyImage && (
                <div>
                  <h3>Preview:</h3>
                  <img
                    src={companyImage}
                    alt="Preview"
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              )}

              <button onClick={handleUpdate}>Update Company</button>
              <button
                style={{
                  color: "red",
                }}
                onClick={handleDeleteClick}
              >
                Delete Company
              </button>
              <br />

              {(currentUser.role === 1 || currentUser.role === 2) && (
                <>
                  <h2>Alarm Receiver Configuration</h2>
                  {/* <label>Enable Alarm Receiver:</label> */}
                  {/* <input
                type="checkbox"
                checked={alarmReceiverConfig.en}
                onChange={handleToggleEn}
              /> */}
                  {/* <div className="toggleContainer">
                    <label
                      className="toggleText"
                      style={{
                        color: alarmReceiverConfig.en ? "green" : "red",
                      }}
                    >
                      {alarmReceiverConfig.en ? "Enabled" : "Disabled"}
                    </label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={alarmReceiverConfig.en}
                        onChange={handleToggleEn}
                      />
                      <span className="slider"></span>
                    </label>
                  </div> */}
                  <div className="editField">
                    <label>
                      Primary Server Host:
                      <input
                        type="text"
                        value={alarmReceiverConfig.server.primary.host}
                        onChange={(e) =>
                          handleAlarmReceiverChange(
                            "primary",
                            "host",
                            e.target.value
                          )
                        }
                        maxLength={30}
                      />
                    </label>
                  </div>
                  <div className="editField">
                    <label>
                      Primary Server Port:
                      <input
                        type="text"
                        value={alarmReceiverConfig.server.primary.port}
                        onChange={(e) =>
                          handleAlarmReceiverChange(
                            "primary",
                            "port",
                            e.target.value
                          )
                        }
                        maxLength={30}
                      />
                    </label>
                  </div>
                  <div className="editField">
                    <label>
                      Backup Server Host:
                      <input
                        type="text"
                        value={alarmReceiverConfig.server.backup.host}
                        onChange={(e) =>
                          handleAlarmReceiverChange(
                            "backup",
                            "host",
                            e.target.value
                          )
                        }
                        maxLength={30}
                      />
                    </label>
                  </div>
                  <div className="editField">
                    <label>
                      Backup Server Port:
                      <input
                        type="text"
                        value={alarmReceiverConfig.server.backup.port}
                        onChange={(e) =>
                          handleAlarmReceiverChange(
                            "backup",
                            "port",
                            e.target.value
                          )
                        }
                        maxLength={30}
                      />
                    </label>
                  </div>
                  <button onClick={handleSaveAlarmReceiverConfig}>
                    Save Alarm Receiver Configuration
                  </button>
                </>
              )}
            </div>
            {showDeleteModal && (
              <div className="deleteModal">
                <div className="deleteModalContent">
                  <p>Are you sure you want to delete this Company?</p>

                  <p
                    style={{
                      color: "gray",
                      fontSize: "12px",
                    }}
                  >
                    Deleting this Company will also delete all the locations and
                    Users associated with it and clear all the Watches and
                    RelayBoards associated with it
                  </p>

                  <p style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    Please be aware: Deleting a Company is irreversible and
                    cannot be undone.{" "}
                  </p>

                  {countdown > 0 ? (
                    <p
                      style={{
                        color: "gray",
                        fontSize: "9px",
                      }}
                    >
                      Please wait before confirming deletion: {countdown}{" "}
                      seconds remaining
                    </p>
                  ) : (
                    <p
                      style={{
                        color: "gray",
                        fontSize: "10px",
                      }}
                    >
                      You may now proceed with deleting the company.
                    </p>
                  )}

                  <div className="">
                    <button
                      className="cancel"
                      onClick={() => {
                        handleDeleteCompany();
                        alert("Company Deleted");
                      }}
                      disabled={isDisabled}
                    >
                      Yes
                    </button>

                    <button
                      className="confirm"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
            <style jsx>{`
              .deleteModal {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1000;
              }

              .deleteModalContent {
                background-color: white;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
                width: 300px;
                text-align: center;
              }

              .deleteModal p {
                font-size: 18px;
                margin-bottom: 20px;
                color: #333; /* Dark text for better readability */
              }

              .deleteModal button {
                margin: 5px;
                padding: 10px 20px;
                font-size: 16px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
              }

              .deleteModal button.confirm {
                background-color: #e74c3c;
                color: white;
              }

              .deleteModal button.cancel {
                background-color: #95a5a6;
                color: white;
              }

              .deleteModalButtons {
                display: flex;
                justify-content: space-between;
              }
            `}</style>
          </div>
        </div>
        {(currentUser.role === 1 || currentUser.role === 2) && (
          <div className="bottom">
            <h1 className="title">Associated Devices</h1>
            <CompanyWatches companyId={companyId1} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleCompany;
