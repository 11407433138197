import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState, useContext } from "react";
import {
  ref,
  get,
  query,
  orderByChild,
  equalTo,
  onValue,
  update,
  push,
  remove,
} from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const LocationWatches = ({ companyId, onWatchSelect }) => {
  const [watches, setWatches] = useState([]);
  const [data, setData] = useState([]);
  const { currentUser } = useContext(AuthContext);

  const handleDelete = async (id) => {
    try {
      const watchRef = ref(database, `Watches/${id}`);
      await remove(watchRef);
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  const handleRowClick = (watch) => {
    if (onWatchSelect) {
      onWatchSelect({
        latitude: watch.watchLatitude, // Make sure this field matches your data structure
        longitude: watch.watchLongitude, // Make sure this field matches your data structure
      });
    }
  };

  useEffect(() => {
    const fetchWatches = () => {
      try {
        const watchesRef = ref(database, "Watches");
        const q = query(
          watchesRef,
          orderByChild("locationId"),
          equalTo(companyId)
        );

        // Use onValue to listen for real-time updates
        const unsubscribe = onValue(
          q,
          (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val();
              const watchesList = Object.keys(data).map((key) => ({
                id: key,
                ...data[key],
              }));
              setWatches(watchesList);
            } else {
              console.log("No watches found");
            }
          },
          (error) => {
            console.error("Error fetching watches:", error);
          }
        );

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching watches:", error);
      }
    };

    if (companyId) {
      fetchWatches();
    }
  }, [companyId]);

  const alarmCodeButtons = {
    C: "Cancel",
    G: "Green",
    O: "Orange",
    R: "Red",
  };

  const alarmCodeStyles = {
    C: { color: "#3C3D37", label: "Cancel" },
    G: { color: "#185519", label: "Green" },
    O: { color: "#CD5C08", label: "Orange" },
    R: { color: "#7D0A0A", label: "Red" },
  };

  const getDeviceInfo = async () => {
    const userAgent = navigator.userAgent;
    const isPC = /Windows|Mac|Linux/.test(userAgent); // Detect if it's a PC
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    let connectivity = connection ? connection.effectiveType : "Unknown";

    // If the device is a PC and the connection type is "4g", assume it's Wi-Fi
    if (isPC && connectivity === "4g") {
      connectivity = "Wi-Fi";
    }

    const browser = (() => {
      const ua = userAgent;
      if (ua.includes("Firefox")) return "Firefox";
      if (ua.includes("Edg")) return "Edge";
      if (ua.includes("Chrome")) return "Chrome";
      if (ua.includes("Safari") && !ua.includes("Chrome")) return "Safari";
      return "Unknown";
    })();

    const deviceBrand = /Mobile/.test(userAgent) ? "Mobile Device" : "PC";
    let batteryLevel = "(PC)";

    // If it's a mobile device, attempt to get battery info
    if (!isPC && navigator.getBattery) {
      const battery = await navigator.getBattery();
      batteryLevel = `${Math.round(battery.level * 100)}%`;
    }

    return {
      connectivityMode: connectivity, // Return the modified connectivity status
      deviceModel: deviceBrand,
      browser,
      batteryLevel,
    };
  };

  const logUpdate = async (watchId, logDetails, locationId) => {
    try {
      const deviceInfo = await getDeviceInfo();

      const locationRef = ref(database, `locations/${locationId}`);
      const locationSnapshot = await new Promise((resolve, reject) => {
        onValue(
          locationRef,
          (snapshot) => {
            resolve(snapshot.val());
          },
          {
            onlyOnce: true,
          }
        );
      });

      if (!locationSnapshot) {
        console.error(`Location with ID ${locationId} not found.`);
        return;
      }

      const { locationName, locationCode, companyName, latitude, longitude } =
        locationSnapshot;

      const extendedLogDetails = {
        ...logDetails,
        locationId,
        locationName: locationName || "Unknown",
        locationCode: locationCode || "Unknown",
        companyName: companyName || "Unknown",
        locationLatitude: latitude || "Unknown",
        locationLongitude: longitude || "Unknown",
        deviceInfo, // Include device information
      };

      // Log the alarm details inside the specific watch's log entry
      const alarmLogRef = ref(database, `Watches/${watchId}/logs/alarm`);
      await push(alarmLogRef, {
        ...extendedLogDetails, // Include all the details in the alarm log
      });

      // Log globally in the "logs" table under "logs/alarm"
      const globalLogRef = ref(database, `logs/alarm`);
      const globalLog = {
        ...extendedLogDetails,
        watchId, // Add the watchId to the global log for tracking
      };
      await push(globalLogRef, globalLog);
    } catch (error) {
      console.error("Error logging update:", error);
    }
  };

  const handleAlarmCodeChange = async (
    watchId,
    code,
    groupCode,
    companyId,
    locationId
  ) => {
    try {
      const watchesRef = ref(database, "Watches");

      // Fetch the snapshot of all watches
      const snapshot = await new Promise((resolve, reject) => {
        onValue(
          watchesRef,
          (snapshot) => {
            resolve(snapshot);
          },
          { onlyOnce: true } // Fetch data only once
        );
      });

      let updates = {};
      const logDetails = {
        alarmCode: code,
        latitude:
          data.find((item) => item.id === watchId)?.watchLatitude || "Unknown",
        longitude:
          data.find((item) => item.id === watchId)?.watchLongitude || "Unknown",
        timestamp: Date.now(),
        userId: currentUser?.uid || "Unknown ID",
        userEmail: currentUser?.email || "Unknown Email",
      };

      // Iterate through the watches to apply updates and logs
      snapshot.forEach((childSnapshot) => {
        const watch = childSnapshot.val();
        if (
          watch.groupCode === groupCode &&
          watch.companyId === companyId &&
          watch.locationId === locationId
        ) {
          // Update the alarm code for matching watches
          updates[`Watches/${childSnapshot.key}/alarmCode`] = code;

          // Log the update for each matching watch
          logUpdate(childSnapshot.key, logDetails, locationId); // Logs both globally and under the specific watch
        }
      });

      // Apply the updates to the database
      if (Object.keys(updates).length > 0) {
        await update(ref(database), updates);
        console.log(
          `Alarm code updated to ${code} for watches with groupCode: ${groupCode}`
        );
      } else {
        console.log("No matching watches found.");
      }
    } catch (error) {
      console.error("Error updating alarm code for group:", error);
    }
  };

  const confirmAlarmChange = (
    watchId,
    code,
    groupCode,
    companyId,
    locationId
  ) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to change the alarm code?"
    );
    if (isConfirmed) {
      handleAlarmCodeChange(watchId, code, groupCode, companyId, locationId);
    }
  };

  const actionColumn = [
    {
      field: "alarmCode",
      headerName: "Alarm Status",
      width: 200,
      renderCell: (params) => {
        const { alarmCode } = params.row;
        const { color, label } = alarmCodeStyles[alarmCode] || {
          color: "gray",
          label: "Unknown",
        };

        return (
          <div
            style={{
              backgroundColor: color,
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              textAlign: "center",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minWidth: "100px",
            }}
          >
            {label}
          </div>
        );
      },
    },

    {
      field: "alarm Actions",
      headerName: "Alarm Actions",
      width: 330,
      renderCell: (params) => {
        const { id, groupCode, companyId, locationId } = params.row;

        return (
          <div className="alarmCodeButtons">
            {Object.entries(alarmCodeButtons).map(([code, label]) => (
              <button
                key={code}
                className={`alarmButton ${code.toLowerCase()}`}
                onClick={() =>
                  confirmAlarmChange(id, code, groupCode, companyId, locationId)
                }
              >
                {label}
              </button>
            ))}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/watches/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            {/* <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div> */}
          </div>
        );
      },
    },
  ];

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Watch Id</TableCell>
            <TableCell className="tableCell">Watch Owner</TableCell>

            <TableCell className="tableCell">Group Code</TableCell>

            {actionColumn.map((col) => (
              <TableCell key={col.field} className="tableCell">
                {col.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {watches.map((watch) => (
            <TableRow
              key={watch.id}
              onClick={() => handleRowClick(watch)}
              style={{ cursor: "pointer" }}
            >
              <TableCell className="tableCell">{watch.id}</TableCell>
              <TableCell className="tableCell">{watch.displayName}</TableCell>

              <TableCell className="tableCell">{watch.groupCode}</TableCell>
              {actionColumn.map((col) => (
                <TableCell key={col.field} className="tableCell">
                  {col.renderCell({ row: watch })}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LocationWatches;
