import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { relayBoardLogsColumn } from "../../datatablesource";
import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../firebase";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom";

const SingleRelayBoardDataTable = () => {
  const { cid, lid } = useParams(); // Get cid and lid from the URL
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  // Function to map alarm codes to their descriptions
  const getAlarmStatus = (code) => {
    switch (code) {
      case "C":
        return "Cancelled";
      case "G":
        return "Green";
      case "R":
        return "Red";
      case "O":
        return "Orange";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    const logsRef = ref(database, `/logs/boards`);

    // LISTEN (REALTIME)
    const unsub = onValue(logsRef, (snapshot) => {
      let list = [];
      snapshot.forEach((childSnapshot) => {
        const logData = childSnapshot.val();
        const flattenedData = {
          id: childSnapshot.key,
          companyName: logData.companyName || cid,
          alarmCode: getAlarmStatus(logData.alarmCode), // Map alarm code to status
          deviceInfo: logData.deviceInfo || "N/A",
          locationCode: logData.locationCode || "N/A",
          locationLatitude: logData.locationLatitude || "Unknown",
          locationLongitude: logData.locationLongitude || "Unknown",
          locationName: logData.locationName || "N/A",
          timestamp: new Date(logData.timestamp).toLocaleString(), // Format timestamp
          companyId: logData.companyID,
          locationId: logData.locationID,
          deviceId: logData.deviceID,
          alert: logData.alert,
          type: logData.type || "N/A",
        };
        list.push(flattenedData);
      });
      list.sort((a, b) => b.timestamp - a.timestamp);
      setData(list);

      console.log(list);
    });

    return () => {
      unsub();
    };
  }, [cid, lid]); // Depend on cid and lid

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Logs Report", 20, 10);
    doc.setFontSize(12);

    const yStart = 20;
    const lineHeight = 10;
    const maxLinesPerPage = Math.floor(
      (doc.internal.pageSize.height - yStart) / lineHeight
    );

    let y = yStart;

    data.forEach((log) => {
      if (y + 4 * lineHeight > doc.internal.pageSize.height) {
        doc.addPage();
        y = yStart;
      }

      doc.setFontSize(10);
      doc.text(`Log ID: ${log.id}`, 20, y);
      y += lineHeight;
      doc.text(`Company Name: ${log.companyName}`, 20, y);
      y += lineHeight;
      doc.text(`Alarm Code: ${log.alarmCode}`, 20, y);
      y += lineHeight;
      doc.text(`Device Info: ${log.deviceInfo}`, 20, y);
      y += lineHeight;
      doc.text(`Location Code: ${log.locationCode}`, 20, y);
      y += lineHeight;
      doc.text(`Location Latitude: ${log.locationLatitude}`, 20, y);
      y += lineHeight;
      doc.text(`Location Longitude: ${log.locationLongitude}`, 20, y);
      y += lineHeight;
      doc.text(`Location Name: ${log.locationName}`, 20, y);
      y += lineHeight;
      doc.text(`Timestamp: ${log.timestamp}`, 20, y);
      y += lineHeight * 2; // Add extra space after each log entry
    });

    doc.save("logs_report.pdf");
    handleMenuClose();
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Logs");
    XLSX.writeFile(wb, "logs_report.xlsx");
    handleMenuClose();
  };

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Data Log
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="primary"
        >
          <DownloadIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={downloadPDF}>Download as PDF</MenuItem>
          <MenuItem onClick={downloadExcel}>Download as Excel</MenuItem>
        </Menu>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={relayBoardLogsColumn}
        pageSize={30}
        rowsPerPageOptions={[30]}
        checkboxSelection
        sortModel={[
          {
            field: "timestamp", // Field name that you want to sort
            sort: "desc", // Sort direction (descending)
          },
        ]}
      />
    </div>
  );
};

export default SingleRelayBoardDataTable;
