import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect, useContext } from "react";
import {
  getDatabase,
  ref,
  set,
  onValue,
  serverTimestamp,
  update,
} from "firebase/database";
import { database } from "../../firebase";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const NewWatch = ({ inputs, title }) => {
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  const [watches, setWatches] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredWatches, setFilteredWatches] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Fetch Users
    const userRef = ref(database, "users");
    onValue(userRef, (snapshot) => {
      let userList = [];
      snapshot.forEach((childSnapshot) => {
        userList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setUsers(userList);
    });

    // Fetch Watches
    const watchRef = ref(database, "Watches");
    onValue(watchRef, (snapshot) => {
      let watchList = [];
      snapshot.forEach((childSnapshot) => {
        watchList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setWatches(watchList);
    });

    const companyRef = ref(database, "Companies");
    onValue(companyRef, (snapshot) => {
      let companyList = [];
      snapshot.forEach((childSnapshot) => {
        companyList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setCompanies(companyList);
    });

    // Fetch Locations
    const locationRef = ref(database, "locations");
    onValue(locationRef, (snapshot) => {
      let locationList = [];
      snapshot.forEach((childSnapshot) => {
        locationList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setLocations(locationList);

      // Auto-filter locations if role is 2
      if (currentUser.role === 2) {
        const companyId = currentUser.companyId;

        // Filter locations based on the current user's companyId
        const matchedLocations = locationList.filter(
          (location) => location.companyId === companyId
        );
        setFilteredLocations(matchedLocations);
      } else if (currentUser.role === 1) {
        // // If role is not 2, set filteredLocations to all locations
        // setFilteredLocations(locationList);
        const matchedLocations = locationList.filter(
          (location) => location.companyId === selectedCompany
        );
        setFilteredLocations(matchedLocations);
      }
    });
  }, []);

  useEffect(() => {
    // Filter users based on selected companyId and locationId
    const { companyId } = selectedCompany;
    const { locationId } = data;

    if (companyId && locationId) {
      const matchedUsers = users.filter(
        (user) => user.companyId === companyId && user.locationId === locationId
      );
      setFilteredUsers(matchedUsers);
    } else {
      setFilteredUsers([]);
    }

    // Filter watches based on selected companyId and the condition for isActive
    if (companyId) {
      const matchedWatches = watches.filter((watch) => {
        // Check if the watch belongs to the selected company
        const belongsToCompany =
          watch.companyId === companyId || !watch.companyId;
        // Check if the watch's isActive field is false or if isActive is not present
        const isInactiveOrNoIsActive =
          !("isActive" in watch) || watch.isActive === false;

        return belongsToCompany && isInactiveOrNoIsActive;
      });
      setFilteredWatches(matchedWatches);
    } else {
      setFilteredWatches([]);
    }
  }, [selectedCompany, data.locationId, users, watches]);

  useEffect(() => {
    if (data.watchId) {
      // Monitor isActive status of the selected watch
      const watchRef = ref(database, `Watches/${data.watchId}/isActive`);
      onValue(watchRef, (snapshot) => {
        const activeStatus = snapshot.val();
        if (activeStatus) {
          setIsActive(true);
          alert("Watch successfully activated");
          navigate("/watches");
        }
      });
    }
  }, [data.watchId]);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "locationId") {
      const locationName = filteredLocations.find(
        (location) => location.id === value
      )?.locationName;
      setData({ ...data, [id]: value, locationName });
    } else if (id === "displayName") {
      const selectedUser = users.find((user) => user.displayName === value);
      setData({
        ...data,
        [id]: value, // Set the displayName as selected
        userId: selectedUser ? selectedUser.id : null, // Set the corresponding userId
      });
    } else {
      setData({ ...data, [id]: value, alarmCode: "C" });
    }
  };

  const handleWatchChange = async (e) => {
    const watchId = e.target.value;

    // Generate a new random two-digit verification code
    const verificationCode = Math.floor(10 + Math.random() * 90);

    // Get the selected user's ID based on the selected displayName in the form
    const selectedUser = users.find(
      (user) => user.displayName === data.displayName
    );
    const userId = selectedUser ? selectedUser.id : null;

    setData((prevData) => ({
      ...prevData,
      watchId,
      userId,
      isActive: false,
      verificationCode,
    }));

    // Set the current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setData((prevData) => ({
            ...prevData,
            watchLatitude: latitude,
            watchLongitude: longitude,
          }));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    const companyName = companies.find(
      (company) => company.id === companyId
    )?.companyName;

    setData((prevData) => ({ ...prevData, companyId, companyName }));
    setSelectedCompany({ companyId, companyName });

    // Filter locations based on the selected company
    const matchedLocations = locations.filter(
      (location) => location.companyId === companyId
    );
    setFilteredLocations(matchedLocations);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      const {
        watchId,
        locationId,
        locationName,
        companyId,
        companyName,
        userId,
        watchLatitude,
        watchLongitude,
        ...otherData
      } = data;

      const watchRef = ref(database, `Watches/${watchId}`);

      // Update the data in the structured format
      await set(watchRef, {
        companyId,
        companyName,
        locationId,
        locationName,
        watchLatitude,
        watchLongitude,
        ...otherData,
        timeStamp: serverTimestamp(),
      });

      setShowModal(true);
      console.log("Watch updated successfully with ID:", watchId);

      // Update the user with the selected watchId
      if (userId) {
        const userRef = ref(database, `users/${userId}`);
        await update(userRef, {
          watchId,
        });
        console.log("User updated with watchId:", watchId);
      }

      // Check if the watch is active and navigate if it is
      if (isActive) {
        navigate("/watches"); // Navigate to the watches page
      } else {
        alert(
          "The watch is not active yet. Please activate it before proceeding."
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div
            className="right"
            style={{
              display: "flex",
              justifyContent: "left",
              width: "100%",
              alignItems: "flex-start",
            }}
          >
            <form onSubmit={handleEdit}>
              {/* Company Dropdown */}
              <div className="formInput">
                <label>Company *</label>
                <select
                  id="companyId"
                  onChange={(e) => {
                    if (e.target.value === "addCompany") {
                      navigate("/companies/new");
                    } else {
                      handleCompanyChange(e);
                    }
                  }}
                  disabled={currentUser.role !== 1} // Disable dropdown if user has role 2 or 3
                  value={
                    currentUser.role !== 1
                      ? currentUser.companyId
                      : data.companyId || ""
                  } // Automatically set to currentUser.companyId if role is not 1
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  required
                >
                  <option value="">Select Company</option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                  <option value="addCompany" hidden={currentUser.role !== 1}>
                    Add Company
                  </option>
                </select>
              </div>

              {/* Location Dropdown */}
              <div className="formInput">
                <label>Location *</label>
                <select
                  id="locationId"
                  onChange={(e) => {
                    if (e.target.value === "addLocation") {
                      navigate("/locations/new");
                    } else {
                      handleInput(e);
                    }
                  }}
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  required
                  disabled={!data.companyId} // Disable if no company is selected
                >
                  <option value="">
                    {data.companyId
                      ? "Select Location"
                      : "Select a company first"}
                  </option>
                  {filteredLocations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.locationName}
                    </option>
                  ))}
                  {(currentUser.role === 1 || currentUser.role === 2) && (
                    <option
                      value="addLocation"
                      hidden={
                        !(currentUser.role === 1 || currentUser.role === 2)
                      }
                    >
                      Add Location
                    </option>
                  )}
                </select>
              </div>

              {/* Watch Owner Dropdown */}
              <div className="formInput">
                <label>Watch Owner</label>
                <select
                  id="displayName"
                  value={data.displayName || ""}
                  onChange={(e) => {
                    if (e.target.value === "addUser") {
                      navigate("/users/new");
                    } else {
                      handleInput(e);
                    }
                  }}
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  disabled={!data.companyId || !data.locationId}
                >
                  <option value="">
                    {!data.companyId
                      ? "Select a Company and Location first"
                      : !data.locationId
                      ? "Pick a Location first"
                      : "Select a User"}
                  </option>
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => (
                      <option key={user.id} value={user.displayName}>
                        {user.displayName}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No users found
                    </option>
                  )}
                  {(currentUser.role === 1 || currentUser.role === 2) && (
                    <option value="addUser">Add User</option>
                  )}
                </select>
              </div>

              {/* Watch ID Dropdown */}
              <div className="formInput">
                <label>Watch ID *</label>
                <select
                  id="watchId"
                  onChange={handleWatchChange}
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  required
                  disabled={!data.companyId} // Disable if no company is selected
                >
                  <option value="">
                    {!data.companyId
                      ? "Choose a Company first"
                      : "Select Watch"}
                  </option>
                  {filteredWatches.length > 0 ? (
                    filteredWatches.map((watch) => (
                      <option key={watch.id} value={watch.id}>
                        {watch.id}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No watches available
                    </option>
                  )}
                </select>
              </div>

              {/* Render remaining inputs */}
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                    required={input.required}
                    maxLength={input.maxLength}
                  />
                </div>
              ))}
              <span
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "50px",
                  color: "gray",
                  fontSize: "10px",
                }}
              >
                Please Fill all the required fields (*)
              </span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "50px",
                }}
              >
                <button type="submit">Assign Watch</button>
                <Modal open={showModal} onClose={() => setShowModal(false)}>
                  <div className="modal-overlay">
                    <div className="modal" style={{}}>
                      <p>
                        Please Select this Verification Code on your Smart Watch
                        to Activate your Watch
                      </p>
                      <br />
                      <h1>{data.verificationCode}</h1>
                    </div>
                  </div>
                </Modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewWatch;
