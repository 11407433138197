import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ref, onValue, query, orderByChild, equalTo } from "firebase/database";
import { database } from "../../firebase";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

const BillingDataTable = () => {
  const { currentUser } = useContext(AuthContext);

  // Declare all hooks before returning anything to avoid conditional hooks
  const [companyList, setCompanyList] = useState([]);
  const [watchList, setWatchList] = useState([]); // To store all fetched watches
  const [watchCountByCompany, setWatchCountByCompany] = useState({}); // Store watch counts by company
  const [companyBills, setCompanyBills] = useState({}); // Store total bills per company
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [error, setError] = useState("");

  // Only proceed with fetching data if the user's role is 1
  useEffect(() => {
    if (currentUser.role !== 1) return; // Exit early if the role is not 1

    const fetchCompanies = async () => {
      try {
        let companyRef = ref(database, "Companies");
        if (currentUser.role === 2 || currentUser.role === 3) {
          companyRef = query(
            companyRef,
            orderByChild("companyId"),
            equalTo(currentUser.companyId)
          );
        }
        onValue(companyRef, (snapshot) => {
          const list = [];
          snapshot.forEach((childSnapshot) => {
            list.push({
              id: childSnapshot.key,
              ...childSnapshot.val(),
              billingDiscount: parseFloat(
                childSnapshot.val().billingDiscount || 0
              ), // Default to 0 if not set
            });
          });
          setCompanyList(list);
        });
      } catch (err) {
        console.error("Error fetching companies:", err);
        setError("Failed to fetch companies. Please try again later.");
      }
    };
    fetchCompanies();
  }, [currentUser]);

  // Fetch watches and calculate bills and counts
  useEffect(() => {
    if (currentUser.role !== 1) return; // Exit early if the role is not 1

    const fetchWatchesAndUpdateData = async () => {
      try {
        let watchRef = ref(database, "Watches");

        if (currentUser.role === 1) {
          watchRef = ref(database, "Watches");
        } else if (currentUser.role === 2 || currentUser.role === 3) {
          watchRef = query(
            ref(database, "Watches"),
            orderByChild("companyId"),
            equalTo(currentUser.companyId)
          );
        }

        onValue(watchRef, (snapshot) => {
          const watchListData = [];
          const watchCount = {};
          const bills = {};

          snapshot.forEach((childSnapshot) => {
            const watch = { id: childSnapshot.key, ...childSnapshot.val() };
            watchListData.push(watch);

            const companyId = watch.companyId;
            if (companyId) {
              watchCount[companyId] = (watchCount[companyId] || 0) + 1;
            }

            if (companyId && watch.bill) {
              const billAmount = parseFloat(watch.bill) || 0;
              bills[companyId] = (bills[companyId] || 0) + billAmount;
            }
          });

          // Subtract billingDiscount from company bills
          const adjustedBills = {};
          companyList.forEach((company) => {
            const totalBill = bills[company.id] || 0;
            const discount = company.billingDiscount || 0;
            adjustedBills[company.id] = totalBill - discount;
          });

          setWatchList(watchListData);
          setWatchCountByCompany(watchCount);
          setCompanyBills(adjustedBills);
        });
      } catch (err) {
        console.error("Error fetching watches:", err);
        setError("Failed to fetch watches. Please try again later.");
      }
    };

    fetchWatchesAndUpdateData();
  }, [currentUser, companyList]);

  const companyColumns = [
    { field: "id", headerName: "Company ID", width: 150 },
    { field: "companyName", headerName: "Company Name", width: 300 },
    {
      field: "Watches",
      headerName: "Number of Watches",
      width: 200,
      valueGetter: (params) => watchCountByCompany[params.row.companyId] || 0,
    },
    {
      field: "companyBills",
      headerName: "Total Bill",
      width: 200,
      valueGetter: (params) => {
        const total = companyBills[params.row.companyId]?.toFixed(0) || 0;
        return `$${total}`;
      },
    },
    
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <div className="cellAction">
          <Link
            onClick={() => setSelectedCompany(params.row)}
            className="cellAction"
            style={{ textDecoration: "none" }}
            to={`/billing/${params.row.id}`}
          >
            <div className="viewButton">View Watches</div>
          </Link>
        </div>
      ),
    },
  ];

  // Only render the content if the user's role is 1
  if (currentUser.role !== 1) {
    return null; // Prevent rendering the component if role is not 1
  }

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Billing Table
        <div style={{ gap: "10px", display: "flex" }}>
          <Link to="/billing/new" className="link">
            Add New Plan
          </Link>
        </div>
      </div>

      {error && <div className="error">{error}</div>}

      {!error && (
        <>
          <div className="datatableTitle">Companies</div>
          <DataGrid
            rows={companyList}
            columns={companyColumns}
            pageSize={20}
            rowsPerPageOptions={[20, 40, 60]}
            autoHeight
            onRowClick={(params) => setSelectedCompany(params.row)}
          />
        </>
      )}
    </div>
  );
};

export default BillingDataTable;
