import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext } from "react";
import { ref, get, update, remove, set } from "firebase/database";
import { database } from "../../firebase";
import CompanyWatches from "../../components/table/CompanyWatchesTable";
import Chart from "../../components/chart/Chart";
import CompanyWatchesMap from "../../components/chart/CompanyWatchesMap";
import LocationWatches from "../../components/table/LocationWatches";
import LocationWatchesMap from "../../components/chart/LocationWatches";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {
  Modal,
  Button,
  Box,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material"; // Assuming you want to use Material UI for the modal
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import cancelledIconUrl from "../../Images/4.png";
import L from "leaflet"; // Import Leaflet to create custom icons
import useCountryList from "react-select-country-list"; // Import country list library

const SingleLocation = ({}) => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [selectedWatch, setSelectedWatch] = useState(null);
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [locationNotes, setLocationNotes] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const [countdown, setCountdown] = useState(10); // 10-second countdown timer
  const [isDisabled, setIsDisabled] = useState(true);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [mapLocation, setMapLocation] = useState({ lat: 0, lng: 0 });
  const countryOptions = useCountryList().getData();

  const customIcon = new L.Icon({
    iconUrl: cancelledIconUrl, // Path to the image you want to use
    iconSize: [32, 32], // Set the size of the icon
    iconAnchor: [16, 32], // Anchor the icon to the center
    popupAnchor: [0, -32], // Adjust the popup position
  });

  const closeMap = () => {
    setIsMapOpen(false);
  };
  const openMap = (lat, lng) => {
    setMapLocation({ lat, lng });
    setIsMapOpen(true);
  };

  // Toggle modal visibility
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  useEffect(() => {
    let timer;
    if (showDeleteModal) {
      setCountdown(10); // Reset countdown every time the modal opens
      setIsDisabled(true); // Disable button initially

      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setIsDisabled(false); // Enable button when countdown reaches zero
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer); // Clear timer on component unmount or when modal closes
  }, [showDeleteModal]);

  const handleDeleteLocation = async () => {
    try {
      // Step 1: Find and delete all locations that match the locationId
      const locationsRef = ref(database, "locations");
      const locationsSnapshot = await get(locationsRef);
      if (locationsSnapshot.exists()) {
        const locationsToDelete = [];
        locationsSnapshot.forEach((childSnapshot) => {
          const location = childSnapshot.val();
          if (location.locationId === locationId) {
            // Matching locationId
            locationsToDelete.push(childSnapshot.key); // Store the location keys to delete
          }
        });
        // Delete locations
        for (const locationId of locationsToDelete) {
          await remove(ref(database, `locations/${locationId}`));
        }
      }

      // Step 2: Find and clear users with matching locationId (set locationId to "")
      const usersRef = ref(database, "users");
      const usersSnapshot = await get(usersRef);
      if (usersSnapshot.exists()) {
        const usersToUpdate = {};
        usersSnapshot.forEach((childSnapshot) => {
          const user = childSnapshot.val();
          if (user.locationId === locationId) {
            usersToUpdate[`users/${childSnapshot.key}/locationId`] = ""; // Clear locationId for user
            usersToUpdate[`users/${childSnapshot.key}/locationName`] = ""; // Clear locationId for user
          }
        });
        if (Object.keys(usersToUpdate).length > 0) {
          await update(ref(database), usersToUpdate); // Apply user updates
        }
      }

      // Step 3: Find watches with matching locationId and clear their locationId field
      const watchesRef = ref(database, "Watches");
      const watchesSnapshot = await get(watchesRef);
      if (watchesSnapshot.exists()) {
        const watchUpdates = {};
        watchesSnapshot.forEach((childSnapshot) => {
          const watch = childSnapshot.val();
          if (watch.locationId === locationId) {
            watchUpdates[`Watches/${childSnapshot.key}/locationId`] = ""; // Clear locationId for watch
            watchUpdates[`Watches/${childSnapshot.key}/locationName`] = ""; // Clear locationId for watch
          }
        });
        if (Object.keys(watchUpdates).length > 0) {
          await update(ref(database), watchUpdates); // Apply watch updates
        }
      }

      // Step 4: Find and clear RelayBoards with matching locationId (set lid to "")
      const relayBoardsRef = ref(database, "RelayBoards");
      const relayBoardsSnapshot = await get(relayBoardsRef);
      if (relayBoardsSnapshot.exists()) {
        const relayBoardUpdates = {};
        relayBoardsSnapshot.forEach((childSnapshot) => {
          const relayBoard = childSnapshot.val();
          if (relayBoard.conf?.lid === locationId) {
            relayBoardUpdates[`RelayBoards/${childSnapshot.key}/conf/lid`] = ""; // Clear locationId in RelayBoard
          }
        });
        if (Object.keys(relayBoardUpdates).length > 0) {
          await update(ref(database), relayBoardUpdates); // Apply relayBoard updates
        }
      }

      alert("Location and associated data cleared successfully.");
      setShowDeleteModal(false); // Close the modal
      navigate("/locations"); // Redirect to locations list
    } catch (error) {
      console.error("Error deleting location:", error);
      alert("An error occurred while deleting the location.");
    }
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const path = window.location.pathname;
        const locationId = path.split("/").pop(); // Extract locationId from URL path
        setLocationId(locationId);

        // Fetch location details
        const locationRef = ref(database, `locations/${locationId}`);
        const locationSnapshot = await get(locationRef);

        if (locationSnapshot.exists()) {
          const locationData = locationSnapshot.val();
          setCompanyDetails(locationData);

          // Fetch location notes
          const notesRef = ref(database, `locations/${locationId}/notes`);
          const notesSnapshot = await get(notesRef);

          if (notesSnapshot.exists()) {
            const notesData = notesSnapshot.val();

            // Ensure notesData is an array
            const notesArray = Array.isArray(notesData)
              ? notesData
              : Object.values(notesData);
            setLocationNotes(notesArray);
          } else {
            console.log("No notes found for this location.");
          }

          // Fetch alarm config details from the company's path
          const alarmConfigRef = ref(
            database,
            `Companies/${locationData.companyId}/ar/location/${locationId}`
          );
          const alarmConfigSnapshot = await get(alarmConfigRef);

          if (alarmConfigSnapshot.exists()) {
            const alarmConfigData = alarmConfigSnapshot.val();
            setCompanyDetails((prev) => ({
              ...prev,
              ...alarmConfigData,
            }));
          }

          // Check user access based on role
          if (
            currentUser.role === 2 &&
            currentUser.companyId !== locationData.companyId
          ) {
            alert("You do not have access to this location.");
            navigate("/");
            return;
          } else if (
            currentUser.role === 3 &&
            currentUser.locationId !== locationId
          ) {
            alert("You do not have access to this location.");
            navigate("/");
            return;
          }
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    fetchCompanyDetails();
  }, [currentUser, navigate]);

  const handleUpdateLocationDetails = async () => {
    try {
      // Retrieve the current location data to check for existing properties
      const locationRef = ref(database, `locations/${locationId}`);
      const locationSnapshot = await get(locationRef);
      const locationData = locationSnapshot.val();

      // Prepare the updated data, checking if fields already exist
      const updatedData = {
        locationName: companyDetails?.locationName ?? "", // Fallback to empty string if undefined
        guidelines: {
          policeNumber: companyDetails?.policeNumber ?? "", // Fallback to empty string if undefined
          ambulanceNumber: companyDetails?.guidelines?.ambulanceNumber ?? "",
          instructionsG: companyDetails?.guidelines?.instructionsG ?? "",
          instructionsO: companyDetails?.guidelines?.instructionsO ?? "",
          instructionsR: companyDetails?.guidelines?.instructionsR ?? "",
        },
        notes: companyDetails?.notes ?? (locationData?.notes || ""), // Add if missing
        address: companyDetails?.address ?? (locationData?.address || ""),
        city: companyDetails?.city ?? (locationData?.city || ""),
        state: companyDetails?.state ?? (locationData?.state || ""),
        postalCode:
          companyDetails?.postalCode ?? (locationData?.postalCode || ""),
        country: companyDetails?.country ?? (locationData?.country || ""),
      };

      // Ensure that no undefined values are being set
      // If a value is undefined, it is replaced by an empty string or fallback value
      for (let key in updatedData) {
        if (updatedData[key] === undefined) {
          updatedData[key] = "";
        }
      }

      // Use update instead of set to only update the specified fields
      await update(locationRef, updatedData);

      alert("Location details updated successfully");
    } catch (error) {
      console.error("Error updating location details:", error);
    }
  };

  const handleUpdateAlarmConfig = async () => {
    try {
      // Prepare alarm config data
      const alarmConfigData = {
        account_number: companyDetails?.account_number || "",
        en: companyDetails?.en,
        users: companyDetails?.users || "",
      };

      // Step 1: Update the initial alarm receiver configuration in Firebase
      // await update(ref(database, `Companies/${companyDetails.companyId}/ar`), alarmConfigData);
      // alert("Alarm receiver configuration updated successfully!");

      // Step 2: Temporarily set 'en' to false in Firebase

      // Step 4: Update the alarm config for the specific location
      await update(
        ref(
          database,
          `Companies/${companyDetails.companyId}/ar/location/${locationId}`
        ),
        alarmConfigData
      );
      alert("Alarm monitoring configuration updated successfully");

      console.log("Setting 'en' to false...");
      await update(ref(database, `Companies/${companyDetails.companyId}/ar`), {
        en: false,
      });

      // Delay to ensure Firebase registers the false state
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Step 3: Set 'en' back to true in Firebase
      console.log("Setting 'en' back to true...");
      await update(ref(database, `Companies/${companyDetails.companyId}/ar`), {
        en: true,
      });
      console.log(
        "Alarm receiver 'en' successfully toggled from false to true."
      );

      // Step 1: Fetch all watches with the same locationId (locationId)
      const watchesRef = ref(database, `Watches`);
      const snapshot = await get(watchesRef);
      if (snapshot.exists()) {
        const watches = snapshot.val();

        // Step 2: Loop through the watches and check if the locationId matches
        for (let watchId in watches) {
          const watch = watches[watchId];
          if (watch.locationId === locationId) {
            // Step 3: Update the branchAccountNumber in the matching watches
            const updatedWatchData = {
              branchAccountNumber: companyDetails?.account_number || "",
            };
            await update(ref(database, `Watches/${watchId}`), updatedWatchData);
          }
        }
        alert("All watches with the same locationId have been updated");
      } else {
        console.log("No watches found");
      }

      // Step 4: Fetch all relay boards with the same locationId (locationId)
      // const relayBoardsRef = ref(database, `RelayBoards`);
      // const relayBoardsSnapshot = await get(relayBoardsRef);

      // if (relayBoardsSnapshot.exists()) {
      //   const relayBoards = relayBoardsSnapshot.val();

      //   // Step 5: Loop through the relay boards and check if the locationId matches
      //   const updatePromises = Object.keys(relayBoards).map(async (relayBoardId) => {
      //     const relayBoard = relayBoards[relayBoardId];

      //     console.log(relayBoardId)

      //     // Check if the locationId (lo_idn) matches the locationId
      //     if (relayBoard.lo_idn === locationId) {
      //       const updatedRelayBoardData = {
      //         conf: {

      //           ban: companyDetails?.account_number || "", // Update branchAccountNumber (ban)
      //         },
      //       };

      //       // Step 6: Update the RelayBoard in the database
      //       await update(ref(database, `RelayBoards/${relayBoardId}/conf`), updatedRelayBoardData.conf);
      //       console.log(`RelayBoard ${relayBoardId} updated with branchAccountNumber: ${companyDetails?.account_number || ""}`);
      //     }
      //   });

      //   // Wait for all updates to finish
      //   await Promise.all(updatePromises);
      //   alert("All RelayBoards with the same locationId have been updated");
      // } else {
      //   console.log("No RelayBoards found");
      // }

      const relayBoardsRef = ref(database, `RelayBoards`);
      const relayBoardsSnapshot = await get(relayBoardsRef);

      if (relayBoardsSnapshot.exists()) {
        const relayBoards = relayBoardsSnapshot.val();

        const updatePromises = Object.keys(relayBoards).map(
          async (relayBoardId) => {
            const relayBoard = relayBoards[relayBoardId];

            // Check if lo_idn matches locationId
            if (relayBoard.conf.lo_idn === locationId) {
              // Update only the 'ban' field within 'conf'
              await update(ref(database, `RelayBoards/${relayBoardId}/conf`), {
                ban: companyDetails?.account_number || "",
              });
              console.log(
                `RelayBoard ${relayBoardId} updated with ban: ${
                  companyDetails?.account_number || ""
                }`
              );
            }
          }
        );

        // Wait for all updates to finish
        await Promise.all(updatePromises);
        alert("All RelayBoards with the same locationId have been updated");
      } else {
        console.log("No RelayBoards found");
      }
    } catch (error) {
      console.error(
        "Error updating alarm configuration, watches, and relay boards:",
        error
      );
    }
  };

  // State for filters
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [createdBy, setCreatedBy] = useState("");

  // Utility function to normalize date to 'yyyy-mm-dd' format
  const normalizeDate = (date) => {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      // Return a default value or handle the error case
      console.error("Invalid date value:", date);
      return null; // You could return a default value like new Date() or a placeholder string
    }
    return parsedDate.toISOString(); // Only call toISOString() if the date is valid
  };

  // Filter notes based on the selected filters
  const filteredNotes = locationNotes
    .filter((note) => {
      // Normalize the timestamp to 'yyyy-mm-dd'
      const noteDate = normalizeDate(note.timestamp);

      // Filter by "Created By"
      if (
        createdBy &&
        !note.createdBy.toLowerCase().includes(createdBy.toLowerCase())
      ) {
        return false;
      }

      // Filter by "From Date"
      if (fromDate && noteDate < normalizeDate(fromDate)) {
        return false;
      }

      // Filter by "To Date"
      if (toDate && noteDate > normalizeDate(toDate)) {
        return false;
      }

      return true;
    })
    .sort((a, b) => {
      const timestampA = new Date(a.timestamp).getTime(); // Convert timestamp to a number
      const timestampB = new Date(b.timestamp).getTime(); // Convert timestamp to a number
      return timestampB - timestampA; // Sort from latest to oldest
    });

  if (!companyDetails) return <div>Loading...</div>;

  const {
    address,
    city,
    locationName,
    country,
    phone,
    postalCode,
    state,
    account_number,
    en,
    users,
  } = companyDetails;

  const isRole3 =
    currentUser.role === 3 ||
    currentUser.role === undefined ||
    currentUser.role === null ||
    currentUser.role === "";

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Location Information</h1>
            <div className="item">
              <div className="details">
                <h1 className="itemTitle">{locationName}</h1>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{address}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">City:</span>
                  <span className="itemValue">{city}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">State:</span>
                  <span className="itemValue">{state}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Postal Code:</span>
                  <span className="itemValue">{postalCode}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{country}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <LocationWatchesMap
              aspect={3 / 1}
              title="Devices"
              companyId={locationId}
              selectedWatch={selectedWatch}
            />
          </div>
        </div>
        <div className="bottom">
          <div
            className="editSection"
            style={{
              position: "relative",
              maxHeight: "400px",
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: "20px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <h1 className="title">Edit Location Details</h1>
            <div className="editForm">
              <label>
                Location Name *
                <input
                  type="text"
                  name="locationName"
                  value={locationName}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      locationName: e.target.value,
                    })
                  }
                  disabled={isRole3} // Disable for role 3
                  required
                  maxLength={40}
                />
              </label>
              <label>
                City *
                <input
                  type="text"
                  name="city"
                  value={city}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      city: e.target.value,
                    })
                  }
                  disabled={isRole3} // Disable for role 3
                  required
                  maxLength={30}
                />
              </label>
              <label>
                State *
                <input
                  type="text"
                  name="state"
                  value={state}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      state: e.target.value,
                    })
                  }
                  disabled={isRole3} // Disable for role 3
                  required
                  maxLength={30}
                />
              </label>
              <label>
                Country: *
                <select
                  value={companyDetails.country || ""} // Pre-fill with existing country or empty string
                  onChange={(e) =>
                    setCompanyDetails((prev) => ({
                      ...prev,
                      country: e.target.value, // Update the country in state when selected
                    }))
                  }
                  disabled={isRole3} // Disable for role 3
                  style={{
                    height: "40px",
                    border: "1px solid gray",
                  }}
                  required
                >
                  <option value="" disabled>
                    Select Country
                  </option>
                  {countryOptions.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Police Phone Number
                <input
                  type="text"
                  name="policeNumber"
                  value={companyDetails?.guidelines?.policeNumber}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      policeNumber: e.target.value,
                    })
                  }
                  disabled={isRole3} // Disable for role 3
                />
              </label>
              <label>
                Ambulance Phone Number
                <input
                  type="text"
                  name="ambulanceNumber"
                  value={companyDetails?.guidelines?.ambulanceNumber}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      guidelines: {
                        ...companyDetails.guidelines,
                        ambulanceNumber: e.target.value,
                      },
                    })
                  }
                  disabled={isRole3} // Disable for role 3
                  maxLength={18}
                />
              </label>
              <label>
                Guidelines Green Alarm
                <input
                  type="text"
                  name="instructionsG"
                  value={companyDetails?.guidelines?.instructionsG}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      guidelines: {
                        ...companyDetails.guidelines,
                        instructionsG: e.target.value,
                      },
                    })
                  }
                  disabled={isRole3} // Disable for role 3
                  maxLength={120}
                />
              </label>
              <label>
                Guidelines Orange Alarm
                <input
                  type="text"
                  name="instructionsO"
                  value={companyDetails?.guidelines?.instructionsO}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      guidelines: {
                        ...companyDetails.guidelines,
                        instructionsO: e.target.value,
                      },
                    })
                  }
                  disabled={isRole3} // Disable for role 3
                  maxLength={120}
                />
              </label>
              <label>
                Guidelines Red Alarm
                <input
                  type="text"
                  name="instructionsR"
                  value={companyDetails?.guidelines?.instructionsR}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      guidelines: {
                        ...companyDetails.guidelines,
                        instructionsR: e.target.value,
                      },
                    })
                  }
                  disabled={isRole3} // Disable for role 3
                  maxLength={120}
                />
              </label>
              {/* <label>
                Notes
                <textarea
                  name="notes"
                  value={companyDetails?.notes}
                  onChange={(e) =>
                    setCompanyDetails({
                      ...companyDetails,
                      notes: e.target.value,
                    })
                  }
                />
              </label> */}
            </div>

            <button
              className="saveButton"
              onClick={handleUpdateLocationDetails}
              style={{
                width: "100%",
                marginBottom: "5px",
              }}
            >
              Update Location
            </button>
            <button
              style={{
                color: "red",
                width: "100%",
              }}
              onClick={handleDeleteClick}
            >
              Delete Location
            </button>
          </div>
          {showDeleteModal && (
            <div className="deleteModal">
              <div className="deleteModalContent">
                <p>Are you sure you want to delete this Location?</p>

                <p
                  style={{
                    color: "gray",
                    fontSize: "12px",
                  }}
                >
                  Deleting this Location will also clear all the Users, Watches
                  and RelayBoards associated with it
                </p>

                <p style={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  Please be aware: Deleting a Location is irreversible and
                  cannot be undone.{" "}
                </p>

                {countdown > 0 ? (
                  <p
                    style={{
                      color: "gray",
                      fontSize: "9px",
                    }}
                  >
                    Please wait before confirming deletion: {countdown} seconds
                    remaining
                  </p>
                ) : (
                  <p
                    style={{
                      color: "gray",
                      fontSize: "10px",
                    }}
                  >
                    You may now proceed with deleting the Location.
                  </p>
                )}

                <div className="">
                  <button
                    className="cancel"
                    onClick={() => {
                      handleDeleteLocation();
                      alert("Location Deleted");
                    }}
                    disabled={isDisabled}
                  >
                    Yes
                  </button>

                  <button
                    className="confirm"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
          <style jsx>{`
            .deleteModal {
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              background: rgba(0, 0, 0, 0.5);
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1000;
            }

            .deleteModalContent {
              background-color: white;
              padding: 20px;
              border-radius: 8px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
              width: 300px;
              text-align: center;
            }

            .deleteModal p {
              font-size: 18px;
              margin-bottom: 20px;
              color: #333; /* Dark text for better readability */
            }

            .deleteModal button {
              margin: 5px;
              padding: 10px 20px;
              font-size: 16px;
              border: none;
              border-radius: 5px;
              cursor: pointer;
            }

            .deleteModal button.confirm {
              background-color: #e74c3c;
              color: white;
            }

            .deleteModal button.cancel {
              background-color: #95a5a6;
              color: white;
            }

            .deleteModalButtons {
              display: flex;
              justify-content: space-between;
            }
          `}</style>
          <div className="editSection">
            <div className="editForm">
              <h1 className="title">Alarm Monitoring Configuration</h1>
              <div
                className="alarmConfig"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="toggleContainer">
                  <label
                    className="toggleText"
                    style={{
                      color: companyDetails?.en ? "green" : "red",
                    }}
                  >
                    {companyDetails?.en ? "Enabled" : "Disabled"}
                  </label>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={companyDetails?.en || false}
                      onChange={(e) =>
                        setCompanyDetails({
                          ...companyDetails,
                          en: e.target.checked,
                        })
                      }
                    />
                    <span className="slider"></span>
                  </label>
                </div>

                <br />

                <label>
                  Branch Account Number:
                  <input
                    type="text"
                    value={account_number || ""}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        account_number: e.target.value,
                      })
                    }
                    maxLength={40}
                  />
                </label>

                <label>
                  Users:
                  <input
                    type="text"
                    value={users || ""}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        users: e.target.value,
                      })
                    }
                    maxLength={9}
                  />
                </label>
              </div>
              <br />
              <button className="saveButton" onClick={handleUpdateAlarmConfig}>
                Update Alarm Configuration
              </button>
            </div>
          </div>
        </div>
        <div className="bottom">
          <LocationWatches
            companyId={locationId}
            selectedWatch={selectedWatch}
            setSelectedWatch={setSelectedWatch}
          />
        </div>
        <div
          className="bottom"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="notes-container">
            <h1 className="title">Note Logs</h1>
            {/* Filters */}

            <Box display="flex" flexDirection="row" gap={2} mb={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel></InputLabel>
                <TextField
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  label="From Date"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel></InputLabel>
                <TextField
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  label="To Date"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Created By"
                  value={createdBy}
                  onChange={(e) => setCreatedBy(e.target.value)}
                  placeholder="Filter by created by"
                />
              </FormControl>
            </Box>
            {filteredNotes.length > 0 ? (
              filteredNotes.map((note, index) => (
                <div key={index} className="note">
                  <p>
                    <strong className="title">Note:</strong> {note.note}
                  </p>
                  <p>
                    <strong className="title">Created By:</strong>{" "}
                    {note.createdBy}
                  </p>
                  <p>
                    <strong className="title">Date Created:</strong>{" "}
                    {new Date(note.timestamp).toLocaleString()}
                  </p>

                  {/* Display latitude and longitude side by side */}
                  <p>
                    <strong className="title">Coordinates:</strong>
                    <span
                      onClick={() =>
                        openMap(note.userLatitude, note.userLongitude)
                      }
                      style={{ cursor: "pointer", color: "blue" }}
                    >
                      {note.userLatitude}, {note.userLongitude}
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <p>No notes available for this location.</p>
            )}
          </div>

          {/* Modal for showing the map */}
          <Modal open={isMapOpen} onClose={closeMap}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400, // Adjust the width here
                height: 300, // Adjust the height here
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 1,
              }}
            >
              <MapContainer
                center={[mapLocation.lat, mapLocation.lng]}
                zoom={13}
                style={{ width: "100%", height: "100%" }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
                />
                <Marker
                  position={[mapLocation.lat, mapLocation.lng]}
                  icon={customIcon}
                >
                  <Popup>
                    <strong>Location</strong>
                    <br />
                    Latitude: {mapLocation.lat}
                    <br />
                    Longitude: {mapLocation.lng}
                  </Popup>
                </Marker>
              </MapContainer>
              {/* <Button
                onClick={closeMap}
                sx={{ position: "absolute", top: 10, right: 10 }}
              >
                Close Map
              </Button> */}
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SingleLocation;
