import React, { useState, useEffect, useContext } from "react";
import {
  ref,
  onValue,
  query,
  orderByChild,
  equalTo,
  set,
  get
} from "firebase/database";
import { database } from "../../firebase"; // Your Firebase setup
import { DataGrid } from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import SubscribeToPlanModal from "../Modals/SubscribeToPlanModal";
import { AuthContext } from "../../context/AuthContext";
import * as XLSX from "xlsx"; // Import the xlsx library
import DownloadIcon from "@mui/icons-material/Download";

const SingleBilling = () => {
  const [watchList, setWatchList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedWatch, setSelectedWatch] = useState(null);

  // State for handling filters
  const [filterActiveWatches, setFilterActiveWatches] = useState(false); // Filter for active watches
  const [selectedBillingMonth, setSelectedBillingMonth] = useState(""); // Filter for month
  const [selectedBillingYear, setSelectedBillingYear] = useState(""); // Filter for year

  // State for handling bill editing and discount application
  const [isEditBillModalOpen, setIsEditBillModalOpen] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [updatedTotalBill, setUpdatedTotalBill] = useState(0);
  const [billingDiscount, setBillingDiscount] = useState(0); // State for storing fetched discount
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [billingLogs, setBillingLogs] = useState([]);
  const [isBillingHistoryModalOpen, setIsBillingHistoryModalOpen] =
    useState(false);

  const { currentUser } = useContext(AuthContext);

  // Handle modal opening
  const handleSubscribeClick = (watch) => {
    setSelectedWatch(watch);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedWatch(null);
    setIsModalOpen(false);
  };

  const handleEditBillClick = () => {
    setIsEditBillModalOpen(true);
  };

  const handleCloseEditBillModal = () => {
    setIsEditBillModalOpen(false);
  };

  useEffect(() => {
    // Fetch the existing discount from Firebase when the component mounts
    get(ref(database, `Companies/${companyId}/billingDiscount`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setDiscountValue(snapshot.val()); // Set the discount value from Firebase
        } else {
          setDiscountValue(0); // Default to 0 if no discount is found
        }
      })
      .catch((err) => {
        console.error("Error fetching discount:", err);
      });
  }, [companyId]); // Dependency array to re-fetch if companyId changes

  const handleApplyDiscount = () => {
    // Validate discount value
    if (discountValue > updatedTotalBill) {
      setErrorMessage("Discount cannot be greater than the total bill.");
      return;
    }

    // Clear error message if discount is valid
    setErrorMessage("");

    // Save discount in the database under Companies/{companyId}/billingDiscount
    set(ref(database, `Companies/${companyId}/billingDiscount`), discountValue)
      .then(() => {
        // After saving, update the local state for the total bill
        setUpdatedTotalBill(updatedTotalBill - discountValue);
        setDiscountValue(0); // Reset discount input
      })
      .catch((err) => {
        console.error("Error applying discount:", err);
      });
  };

  const handleRemoveDiscount = () => {
    // Set the discount value to 0 in the database
    set(ref(database, `Companies/${companyId}/billingDiscount`), 0)
      .then(() => {
        setBillingDiscount(0); // Update the local state to remove the discount
        setUpdatedTotalBill(totalBill); // Recalculate the total bill without discount
      })
      .catch((err) => {
        console.error("Error removing discount:", err);
      });
  };

  useEffect(() => {
    const fetchBillingLogs = () => {
      const logsRef = ref(database, `Billing/billingLogs/${companyId}`);
      onValue(logsRef, (snapshot) => {
        const logs = [];
        snapshot.forEach((childSnapshot) => {
          logs.push({ id: childSnapshot.key, ...childSnapshot.val() });
        });
        setBillingLogs(logs);
      });
    };

    if (companyId) {
      fetchBillingLogs();
    }
  }, [companyId]);

  // Fetch watches
  // Fetch watches with additional filtering
  useEffect(() => {
    const fetchWatches = async () => {
      try {
        const path = window.location.pathname;
        const id = path.split("/").pop();
        setCompanyId(id);
        const watchRef = query(
          ref(database, "Watches"),
          orderByChild("companyId"),
          equalTo(id)
        );
        onValue(watchRef, (snapshot) => {
          const watches = [];
          snapshot.forEach((childSnapshot) => {
            watches.push({ id: childSnapshot.key, ...childSnapshot.val() });
          });
          setWatchList(watches);
        });
      } catch (err) {
        console.error("Error fetching watches:", err);
      }
    };

    fetchWatches();
  }, []);

  // Fetch locations
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const path = window.location.pathname;
        const id = path.split("/").pop();
        const locationRef = query(
          ref(database, "locations"),
          orderByChild("companyId"),
          equalTo(id)
        );
        onValue(locationRef, (snapshot) => {
          const locations = [];
          snapshot.forEach((childSnapshot) => {
            locations.push({ id: childSnapshot.key, ...childSnapshot.val() });
          });
          setLocationList(locations);
        });
      } catch (err) {
        console.error("Error fetching locations:", err);
      }
    };

    fetchLocations();
  }, []);

  // Fetch billingDiscount from database when the component mounts
  useEffect(() => {
    const fetchBillingDiscount = () => {
      const billingDiscountRef = ref(
        database,
        `Companies/${companyId}/billingDiscount`
      );
      onValue(billingDiscountRef, (snapshot) => {
        const discount = snapshot.val() || 0;
        setBillingDiscount(discount); // Update state with fetched discount
      });
    };

    if (companyId) {
      fetchBillingDiscount();
    }
  }, [companyId]); // Re-fetch discount if companyId changes

  // Format date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
  };

  // Filter watches based on selected filters
  const filteredWatches = watchList.filter((watch) => {
    const matchesLocation = selectedLocation
      ? watch.locationId === selectedLocation
      : true;
    const matchesSearch = watch.watchName
      ? watch.watchName.toLowerCase().includes(searchTerm.toLowerCase())
      : true;
    const isActive = filterActiveWatches ? watch.isSubscribed === true : true;

    const watchDate = watch.subscribedDate
      ? new Date(watch.subscribedDate)
      : null;
    const matchesMonth = selectedBillingMonth
      ? watchDate && watchDate.getMonth() === selectedBillingMonth
      : true;
    const matchesYear = selectedBillingYear
      ? watchDate && watchDate.getFullYear() === selectedBillingYear
      : true;

    return (
      matchesLocation &&
      matchesSearch &&
      isActive &&
      matchesMonth &&
      matchesYear
    );
  });

  // Calculate total bill
  const totalBill = filteredWatches.reduce(
    (sum, watch) => sum + (parseFloat(watch.bill) || 0),
    0
  );

  // Update total bill after fetching discount
  useEffect(() => {
    if (totalBill) {
      setUpdatedTotalBill(totalBill - billingDiscount);
    }
  }, [totalBill, billingDiscount]); // Recalculate total bill whenever totalBill or billingDiscount changes

  const watchColumns = [
    { field: "id", headerName: "Watch ID", width: 150 },
    { field: "displayName", headerName: "Watch Owner", width: 200 },
    {
      field: "isSubscribed",
      headerName: "Subscription Status",
      width: 170,
      renderCell: (params) => (params.value ? "Active" : "Inactive"),
    },
    { field: "locationName", headerName: "Location Name", width: 200 },
    {
      field: "planSelected",
      headerName: "Selected Plan",
      width: 150,
      renderCell: (params) =>
        params.row.isSubscribed ? params.value : "Inactive",
    },
    {
      field: "subscribedDate",
      headerName: "Activation Date",
      width: 200,
      renderCell: (params) =>
        params.row.isSubscribed
          ? formatDate(new Date(params.value))
          : "Inactive",
    },
    {
      field: "billingMonth",
      headerName: "Billing Month",
      width: 150,
      renderCell: (params) => {
        if (params.row.isSubscribed) {
          const currentMonth = new Date().toLocaleString("default", {
            month: "long",
          });
          return currentMonth;
        }
        return "Inactive";
      },
      valueGetter: (params) => params.row.subscribedDate, // You can keep this if you still want it to depend on the subscription date
    },
    {
      field: "currentBill",
      headerName: "Current Bill",
      width: 150,
      renderCell: (params) => params.row.bill || 0,
    },
  ];

  const logColumns = [
    { field: "watchId", headerName: "Watch ID", width: 200 },
    { field: "subscriptionPlan", headerName: "Subscription Plan", width: 150 },
    { field: "description", headerName: "Description", width: 250 },

    { field: "price", headerName: "Price", width: 100 },

    {
      field: "timestamp",
      headerName: "Date Activated",
      width: 200,
      renderCell: (params) => new Date(params.value).toLocaleString(), // Convert ISO string to readable format
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (params) => (
        <div className="cellAction">
          <Link
            to={`/watches/${params.row.id}`}
            style={{ textDecoration: "none" }}
          >
            <div className="viewButton">View</div>
          </Link>

          {/* Conditionally render Subscribe Watch button */}
          {!params.row.isSubscribed && (
            <div
              className="viewButton"
              onClick={() => handleSubscribeClick(params.row)}
            >
              Subscribe Watch
            </div>
          )}

          {/* Conditionally render Edit Plan button */}
          {params.row.isSubscribed && (
            <div
              className="viewButton"
              onClick={() => handleSubscribeClick(params.row)}
            >
              Edit Plan
            </div>
          )}
        </div>
      ),
    },
  ];

  const downloadBillingHistory = () => {
    // Prepare the data to be exported (combine rows and columns)
    const worksheet = XLSX.utils.json_to_sheet(billingLogs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Billing History");

    // Write the Excel file and trigger the download
    XLSX.writeFile(workbook, "billing_history.xlsx");
  };

  // Function to export the billing data to Excel

  const exportToExcel = () => {
    // Filter the data to include only the subscribed watches
    const subscribedWatches = watchList.filter((watch) => watch.isSubscribed);
  
    if (subscribedWatches.length === 0) {
      console.error("No subscribed watches found");
      return;
    }
  
    // Calculate the total price (sum of CurrentBill)
    const totalPrice = subscribedWatches.reduce((sum, watch) => sum + (parseFloat(watch.bill) || 0), 0);
  
    // Calculate the total price after applying the discount
    const totalPriceAfterDiscount = totalPrice - discountValue;
  
    // Prepare the data to match the columns format
    const filteredData = subscribedWatches.map((watch) => ({
      WatchID: watch.id,
      WatchOwner: watch.displayName,
      SubscriptionStatus: watch.isSubscribed ? "Active" : "Inactive",
      LocationName: watch.locationName,
      SelectedPlan: watch.isSubscribed ? watch.planSelected : "Inactive",
      ActivationDate: watch.isSubscribed ? formatDate(new Date(watch.subscribedDate)) : "Inactive",
      BillingMonth: watch.isSubscribed ? new Date().toLocaleString("default", { month: "long" }) : "Inactive",
      CurrentBill: parseFloat(watch.bill) || 0, // Convert to number here too
    }));
  
    // Add the totals row to the data (for total price and discount)
    filteredData.push({
      WatchID: "Total",
      WatchOwner: "",
      SubscriptionStatus: "",
      LocationName: "",
      SelectedPlan: "",
      ActivationDate: "",
      BillingMonth: "",
      CurrentBill: `Total Price: ${totalPrice}`,
    });
  
    filteredData.push({
      WatchID: "Discount",
      WatchOwner: "",
      SubscriptionStatus: "",
      LocationName: "",
      SelectedPlan: "",
      ActivationDate: "",
      BillingMonth: "",
      CurrentBill: `Discount: ${discountValue}`,
    });
  
    filteredData.push({
      WatchID: "Total After Discount",
      WatchOwner: "",
      SubscriptionStatus: "",
      LocationName: "",
      SelectedPlan: "",
      ActivationDate: "",
      BillingMonth: "",
      CurrentBill: `Total Price After Discount: ${totalPriceAfterDiscount}`,
    });
  
    // Create a worksheet from the filtered data
    const ws = XLSX.utils.json_to_sheet(filteredData);
  
    // Create a new workbook and append the sheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Subscribed Watches");
  
    // Write the Excel file and trigger the download
    XLSX.writeFile(wb, "Subscribed_Watches.xlsx");
  };
  
  
  
  return (
    <div
      className="datatable"
      style={{
        height: "calc(100vh - 110px)", // Subtracting header height (if applicable)
        overflowY: "auto", // Enable vertical scrolling
        overflowX: "hidden", // Prevent horizontal overflow
      }}
    >
      <div className="datatableTitle">
        Billing Table
        <div style={{ gap: "10px", display: "flex" }}>
          {currentUser.role === 1 && (
            <Link to="/billing/new" className="link">
              Add New Plan
            </Link>
          )}

          {/* <Link to="/billing/subscribe-to-plan" className="link">
            Subscribe to New Plan
          </Link> */}
        </div>
      </div>

      <Box display="flex" alignItems="center" marginBottom="10px">
        <FormControl fullWidth margin="dense" sx={{ minWidth: 150 }}>
          <InputLabel>Location</InputLabel>
          <Select
            value={selectedLocation || ""}
            onChange={(e) => setSelectedLocation(e.target.value)}
            label="Location"
          >
            {locationList.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.locationName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Search by Id"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          margin="dense"
          sx={{ minWidth: 150 }}
        />
      </Box>

      <Box display="flex" alignItems="center" marginBottom="10px">
        {/* Active watches filter */}
        <FormControl fullWidth margin="dense" sx={{ minWidth: 150 }}>
          <InputLabel>Active Watches</InputLabel>
          <Select
            value={filterActiveWatches ? "active" : "all"}
            onChange={(e) =>
              setFilterActiveWatches(e.target.value === "active")
            }
            label="Active Watches"
          >
            <MenuItem value="all">All Watches</MenuItem>
            <MenuItem value="active">Active Watches</MenuItem>
          </Select>
        </FormControl>

        {/* Billing Month Filter */}
        <FormControl fullWidth margin="dense" sx={{ minWidth: 150 }}>
          <InputLabel>Billing Month</InputLabel>
          <Select
            value={selectedBillingMonth}
            onChange={(e) => setSelectedBillingMonth(e.target.value)}
            label="Billing Month"
          >
            {Array.from({ length: 12 }, (_, i) => (
              <MenuItem key={i} value={i}>
                {new Date(0, i).toLocaleString("default", { month: "long" })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Billing Year Filter */}
        <FormControl fullWidth margin="dense" sx={{ minWidth: 150 }}>
          <InputLabel>Billing Year</InputLabel>
          <Select
            value={selectedBillingYear}
            onChange={(e) => setSelectedBillingYear(e.target.value)}
            label="Billing Year"
          >
            {Array.from({ length: 10 }, (_, i) => (
              <MenuItem key={i} value={new Date().getFullYear() - i}>
                {new Date().getFullYear() - i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
        <span>Total Bill: </span>
        <strong>${updatedTotalBill.toFixed(2)}</strong>

        {billingDiscount > 0 && `(-$${billingDiscount} discount applied)`}

        {currentUser.role === 1 && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={handleEditBillClick}
          >
            Edit Bill
          </Button>
        )}

        {/* Download Bill Button */}
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: "10px" }}
          onClick={exportToExcel} // Call the export function
          startIcon={<DownloadIcon />} // Add download icon
        >
          Download Billing Report
        </Button>

        {/* View Billing History Button */}
        <Button
          variant="outlined"
          color="secondary"
          style={{ marginLeft: "10px" }}
          onClick={() => setIsBillingHistoryModalOpen(true)} // Open billing history modal
        >
          View Billing History
        </Button>
      </div>

      <DataGrid
        rows={filteredWatches}
        columns={watchColumns.concat(actionColumn)}
        pageSize={20}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
      />

      {/* Edit Bill Modal */}
      <Modal
        open={isEditBillModalOpen}
        onClose={handleCloseEditBillModal}
        aria-labelledby="edit-bill-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "300px",
          }}
        >
          <Typography variant="h6">Apply Discount</Typography>
          <TextField
            label="Discount Amount"
            type="number"
            value={discountValue}
            onChange={(e) => setDiscountValue(parseFloat(e.target.value))}
            fullWidth
            margin="normal"
          />
          <Button onClick={handleApplyDiscount} fullWidth>
            Apply Discount
          </Button>
          {errorMessage && (
            <Typography color="error" style={{ marginTop: "10px" }}>
              {errorMessage}
            </Typography>
          )}
          {billingDiscount > 0 && (
            <div style={{ marginTop: "10px" }}>
              <span>Current Discount: ${billingDiscount}</span>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRemoveDiscount}
                style={{ marginLeft: "10px" }}
              >
                Remove Discount
              </Button>
            </div>
          )}
          <Button
            onClick={handleCloseEditBillModal}
            fullWidth
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </Box>
      </Modal>

      {isModalOpen && (
        <SubscribeToPlanModal
          watchId={selectedWatch?.id}
          watchCompanyId={selectedWatch?.companyId}
          watchLocationId={selectedWatch?.locationId}
          watchPlan={selectedWatch?.planSelected}
          watchIsSubscribed={selectedWatch?.isSubscribed}
          onClose={handleCloseModal}
        />
      )}

      {/* Billing History Modal */}
      <Modal
        open={isBillingHistoryModalOpen}
        onClose={() => setIsBillingHistoryModalOpen(false)} // Close the modal
        aria-labelledby="billing-history-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "80%",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6">Billing History</Typography>
          <DataGrid
            rows={billingLogs}
            columns={logColumns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            autoHeight
          />

          {/* Computation for Total Price, Discounted Price, and Total after Discount */}
          <Box sx={{ marginTop: "20px" }}>
            {/* Compute total price */}
            {billingLogs.length > 0 && (
              <>
                {/* Compute Total Price */}
                <Typography variant="body1">
                  Total Price: $
                  {billingLogs
                    .reduce((total, log) => {
                      const price = parseFloat(log.price);
                      return isNaN(price) ? total : total + price;
                    }, 0)
                    .toFixed(2)}
                </Typography>

                {/* Compute Fixed Discount */}
                {/* <Typography variant="body1"> */}
                {/* Discount: ${billingDiscount.toFixed(2)}{" "} */}
                {/* This is the fixed discount */}
                {/* </Typography> */}

                {/* Compute Total After Discount */}
                {/* <Typography variant="body1" fontWeight="bold"> */}
                {/* Total After Discount: $ */}
                {/* {( */}
                {/* billingLogs.reduce((total, log) => { */}
                {/* const price = parseFloat(log.price); */}
                {/* return isNaN(price) ? total : total + price; */}
                {/* }, 0) - billingDiscount */}
                {/* ).toFixed(2)} */}
                {/* </Typography> */}
              </>
            )}
          </Box>

          {/* Button to Download Table as Excel */}
          <Button
            onClick={() => downloadBillingHistory()}
            fullWidth
            style={{ marginTop: "10px" }}
          >
            Download Billing History
          </Button>

          <Button
            onClick={() => setIsBillingHistoryModalOpen(false)} // Close the modal
            fullWidth
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default SingleBilling;
