import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import WatchIcon from "@mui/icons-material/Watch";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RouterIcon from "@mui/icons-material/Router";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import ReorderIcon from "@mui/icons-material/Reorder";
import WatchGuardianLogo2 from "../../Images/WatchGuardianLogo2.png";
import PaymentIcon from "@mui/icons-material/AttachMoney";

const Sidebar = () => {
  const navigate = useNavigate();
  const { currentUser, dispatch: authDispatch } = useContext(AuthContext);

  const handleLogout = (e) => {
    e.preventDefault();
    authDispatch({ type: "LOGOUT" });
    navigate("/login");
  };

  const location = useLocation();

  const isUsersPage =
    location.pathname === "/users" || location.pathname === "/users/add";
  const isWatchesPage =
    location.pathname === "/watches" || location.pathname === "/watches/add";
  const isRelayBoardsPage =
    location.pathname === "/relayboards" ||
    location.pathname === "/relayboards/add";
  const isCompaniesPage =
    location.pathname === "/companies" ||
    location.pathname === "/companies/add";
  const isLocationsPage =
    location.pathname === "/locations" ||
    location.pathname === "/locations/add";
  const isLogsPage = location.pathname === "/logs";
  const isBillingPage = location.pathname === "/billing";

  const { dispatch } = useContext(DarkModeContext);

  const handleProfileClick = () => {
    if (currentUser && currentUser.uid) {
      navigate(`/users/${currentUser.uid}`);
    }
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <img
            src={WatchGuardianLogo2}
            alt="Watch Portal Logo"
            className="logo"
          />
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon
                className="icon"
                style={{ color: isUsersPage ? "#E8E8E8" : "normal" }}
              />
              <span
                style={{
                  fontWeight: isUsersPage ? "600" : "normal",
                  color: isUsersPage ? "#E8E8E8" : "normal",
                }}
              >
                Users
              </span>
            </li>
          </Link>
          <Link to="/watches" style={{ textDecoration: "none" }}>
            <li>
              <WatchIcon
                className="icon"
                style={{ color: isWatchesPage ? "#E8E8E8" : "normal" }}
              />
              <span
                style={{
                  fontWeight: isWatchesPage ? "600" : "normal",
                  color: isWatchesPage ? "#E8E8E8" : "normal",
                }}
              >
                Watches
              </span>
            </li>
          </Link>
          <Link to="/relayboards" style={{ textDecoration: "none" }}>
            <li>
              <RouterIcon
                className="icon"
                style={{ color: isRelayBoardsPage ? "#E8E8E8" : "normal" }}
              />
              <span
                style={{
                  fontWeight: isRelayBoardsPage ? "600" : "normal",
                  color: isRelayBoardsPage ? "#E8E8E8" : "normal",
                }}
              >
                Relay Boards
              </span>
            </li>
          </Link>
          {/* Conditional link for Companies */}
          <Link
            to={
              currentUser.role !== 1
                ? `/companies/${currentUser.companyId}`
                : "/companies"
            }
            style={{ textDecoration: "none" }}
          >
            <li>
              <ApartmentIcon
                className="icon"
                style={{ color: isCompaniesPage ? "#E8E8E8" : "normal" }}
              />
              <span
                style={{
                  fontWeight: isCompaniesPage ? "600" : "normal",
                  color: isCompaniesPage ? "#E8E8E8" : "normal",
                }}
              >
                {currentUser.role !== 1 ? "Company" : "Companies"}
              </span>
            </li>
          </Link>

          <Link to="/locations" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon
                className="icon"
                style={{ color: isLocationsPage ? "#E8E8E8" : "normal" }}
              />
              <span
                style={{
                  fontWeight: isLocationsPage ? "600" : "normal",
                  color: isLocationsPage ? "#E8E8E8" : "normal",
                }}
              >
                Locations
              </span>
            </li>
          </Link>
          <p className="title">SERVICE</p>
          <Link to="/logs" style={{ textDecoration: "none" }}>
            <li>
              <ReorderIcon className="icon" />
              <span
                style={{
                  fontWeight: isLogsPage ? "600" : "normal",
                  color: isLogsPage ? "#E8E8E8" : "normal",
                }}
              >
                Logs
              </span>
            </li>
          </Link>

          {/* <Link to="/billing" style={{ textDecoration: "none" }}> */}
          <Link
           to=
           {currentUser.role !==1 
           ? `/billing/${currentUser.companyId}`
            : "/billing"} 
              style={{
                textDecoration:"none"
              }}
            >
            <li>
              <PaymentIcon className="icon" />
              <span
                style={{
                  fontWeight: isBillingPage ? "600" : "normal",
                  color: isBillingPage ? "#E8E8E8" : "normal",
                }}
              >
                Billing
              </span>
            </li>
          </Link>
          <p className="title">USER</p>
          <li onClick={handleProfileClick}>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <li onClick={handleLogout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      {/* <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div> */}
    </div>
  );
};

export default Sidebar;
