import React, { useState, useEffect } from "react";
import { ref, query, orderByChild, equalTo, onValue, update } from "firebase/database";
import { database } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import SubscribeToPlanModal from "../Modals/SubscribeToPlanModal"; // Import the modal

const SingleBilling = () => {
  const [watchList, setWatchList] = useState([]);
  const [selectedWatch, setSelectedWatch] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch watches
  useEffect(() => {
    const fetchWatches = async () => {
      try {
        const path = window.location.pathname;
        const id = path.split("/").pop();
        const watchRef = query(
          ref(database, "Watches"),
          orderByChild("companyId"),
          equalTo(id)
        );
        onValue(watchRef, (snapshot) => {
          const watches = [];
          snapshot.forEach((childSnapshot) => {
            watches.push({ id: childSnapshot.key, ...childSnapshot.val() });
          });
          setWatchList(watches);
        });
      } catch (err) {
        console.error("Error fetching watches:", err);
      }
    };
    
    fetchWatches();
  }, []);

  // Filter watches where isSubscribed is false
  const unSubscribedWatches = watchList.filter((watch) => !watch.isSubscribed);

  // Handle modal opening
  const handleSubscribeClick = (watch) => {
    setSelectedWatch(watch);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedWatch(null);
    setIsModalOpen(false);
  };

  const columns = [
    { field: "id", headerName: "Watch ID", width: 150 },
    { field: "displayName", headerName: "Watch Owner", width: 200 },
    { field: "locationName", headerName: "Location Name", width: 200 },
    {
      field: "planSelected",
      headerName: "Selected Plan",
      width: 150,
      renderCell: (params) => (params.row.isSubscribed ? params.value : "Inactive"),
    },
    {
      field: "subscribedDate",
      headerName: "Activation Date",
      width: 200,
      renderCell: (params) => (params.row.isSubscribed ? new Date(params.value).toLocaleDateString() : "Inactive"),
    },
    {
      field: "currentBill",
      headerName: "Current Bill",
      width: 150,
      renderCell: (params) => params.row.bill || 0,
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <div className="cellAction">
          <Button
            variant="contained"
            onClick={() => handleSubscribeClick(params.row)}
            disabled={params.row.isSubscribed}
          >
            {params.row.isSubscribed ? "Subscribed" : "Subscribe"}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">Unsubscribed Watches</div>

      <DataGrid
        rows={unSubscribedWatches}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[5, 10, 20]}
        autoHeight
      />

      {/* Subscription Plan Modal */}
      {isModalOpen && selectedWatch && (
        <SubscribeToPlanModal
          watchId={selectedWatch.id}
          watchCompanyId={selectedWatch.companyId}
          watchLocationId={selectedWatch.locationId}
          watchPlan={selectedWatch.planSelected}
          watchIsSubscribed={selectedWatch.isSubscribed}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default SingleBilling;
