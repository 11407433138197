import "./table.scss";
import { useEffect, useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  ref,
  query,
  orderByChild,
  limitToLast,
  onValue,
} from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";

const List = () => {
  const [rows, setRows] = useState([]);

  const { currentUser } = useContext(AuthContext);

  const userRole = currentUser.role;
  const userCompanyId = currentUser.companyId;

  useEffect(() => {
    const usersRef = ref(database, "users");
    const q = query(usersRef, orderByChild("timeStamp"), limitToLast(10));

    const unsub = onValue(q, (snapshot) => {
      let usersList = [];
      snapshot.forEach((childSnapshot) => {
        const userData = { id: childSnapshot.key, ...childSnapshot.val() };

        // Log the current user data for debugging
        console.log("Current User:", currentUser);
        console.log("User Data:", userData);

        // Apply filtering based on userRole
        if (userRole === 1) {
          // Admin can see all users
          usersList.push(userData);
        } else if (userRole === 2 || userRole === 3) {
          // Company Admin and User can see only their own company's users
          if (userData.companyId === userCompanyId) {
            usersList.push(userData);
          }
        }
      });

      // Log the usersList after filtering
      console.log("Filtered Users List:", usersList);
      setRows(usersList.reverse()); // Reverse to show latest users on top
    });

    // Cleanup subscription on component unmount
    return () => {
      unsub();
    };
  }, [currentUser]); // Use currentUser as a dependency

  // Map role to description
  const getRoleDescription = (role) => {
    switch (role) {
      case 1:
        return "Super Admin";
      case 2:
        return "Company Administrator";
      case 3:
        return "Standard User";
      case 4:
        return "Watch User";
      default:
        return "Unknown";
    }
  };

  // Map active status to string
  const getStatus = (isActive) => (isActive ? "Active" : "Inactive");

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell className="tableCell">ID</TableCell> */}

            <TableCell className="tableCell">Name</TableCell>
            <TableCell className="tableCell">Email</TableCell>
            <TableCell className="tableCell">Watch Id</TableCell>
            <TableCell className="tableCell">Company</TableCell>
            <TableCell className="tableCell">Phone</TableCell>
            <TableCell className="tableCell">Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((user) => (
            <TableRow key={user.id}>
              {/* <TableCell className="tableCell">{user.id}</TableCell> */}
              <TableCell className="tableCell">{user.displayName}</TableCell>
              <TableCell className="tableCell">{user.email}</TableCell>
              <TableCell className="tableCell">{user.watchId}</TableCell>
              <TableCell className="tableCell">{user.companyName}</TableCell>
              <TableCell className="tableCell">{user.phoneNumber}</TableCell>
              <TableCell className="tableCell">
                {getRoleDescription(user.role)}
              </TableCell>
              {/* <TableCell className="tableCell">
                <span className={`status ${user.isActive ? "active" : "inactive"}`}>
                  {getStatus(user.isActive)}
                </span>
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
