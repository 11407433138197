import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ref, set, serverTimestamp, push } from "firebase/database";
import { database } from "../../firebase";
import { billingInput } from "../../formSource";
import { AuthContext } from "../../context/AuthContext";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const NewBilling = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});

  // Restrict access based on user role
  useEffect(() => {
    if (currentUser.role !== 1) {
      navigate("/"); // Redirect if user role is not 1
    }
  }, [currentUser.role, navigate]);

  // If the role is not 1, return early to prevent rendering the rest of the component
  if (currentUser.role !== 1) {
    return null; // You can return a loading spinner or nothing if you prefer
  }

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const newPlanRef = push(ref(database, "Billing/Plans"));
      await set(newPlanRef, {
        ...data,
        timeStamp: serverTimestamp(), // Add a timestamp
      });

      alert("Subscription plan added successfully!");
      navigate("/billing"); // Redirect to the billing page
    } catch (error) {
      console.error("Error adding subscription plan:", error);
      alert("An error occurred while adding the subscription plan.");
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add New Billing Plan</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              {billingInput.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={input.placeholder}
                    onChange={handleInput}
                    required={input.required}
                    maxLength={input.maxLength}
                  />
                </div>
              ))}
              <div
                style={{ display: "flex", width: "100%", marginLeft: "50px" }}
              >
                <button type="submit">Add Plan</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBilling;
