import "./chart.scss";
import { useEffect, useState, useContext } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { ref, onValue, push } from "firebase/database";
import { database } from "../../firebase";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import mapIconUrl from "../../Images/mapIcon.png";
import warningIconUrl from "../../Images/warningIcon.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Modal from "react-modal";
import redWatchIconUrl from "../../Images/1.png";
import orangeWatchIconUrl from "../../Images/2.png";
import greenIconUrl from "../../Images/3.png";
import cancelledIconUrl from "../../Images/4.png";

const ChangeMapView = ({ coords, bounds }) => {
  const map = useMap();
const [mapInitialized, setMapInitialized] = useState(false); // Track if map adjustment has been done

useEffect(() => {
  if (map && (coords || bounds)) {
    if (coords) {
      map.setView(coords, 15); // Set initial zoom level
    } else if (bounds) {
      map.fitBounds(bounds); // Fit bounds for all markers
    }
    setMapInitialized(true); // Mark map as initialized
  }
}, [coords, bounds, map]); // Only depends on coords, bounds, and map

return null;
}

const Chart = ({ selectedWatchId }) => {
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [selectedCoords, setSelectedCoords] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [note, setNote] = useState(""); // State to store user input for notes
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState({});
  const [companyImage, setCompanyImage] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCoords, setModalCoords] = useState([0, 0]);

  // Function to open the modal with specific coordinates
  const openModalWithCoords = (lat, lng) => {
    setModalCoords([lat, lng]);
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const modalMapIcon = L.icon({
    iconUrl: cancelledIconUrl,
    iconSize: [45, 45], // Adjust the size as needed
    iconAnchor: [22.5, 45], // Adjust to align the icon properly
    popupAnchor: [0, -45], // Adjust popup position if you add popups
  });

  useEffect(() => {
    const watchRef = ref(database, "Watches");

    const unsub = onValue(watchRef, (snapshot) => {
      let list = [];
      snapshot.forEach((childSnapshot) => {
        const watchData = { id: childSnapshot.key, ...childSnapshot.val() };
        // Check user role and filter based on companyId if necessary
        if (
          currentUser.role === 1 ||
          watchData.companyId === currentUser.companyId
        ) {
          list.push(watchData);
        }
      });
      setData(list);

      if (list.length > 0) {
        const newBounds = L.latLngBounds(
          list.map((watch) =>
            L.latLng(watch.watchLatitude, watch.watchLongitude)
          )
        );
        setBounds(newBounds);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    data.forEach((watch) => {
      if (watch.locationId && !locationData[watch.locationId]) {
        fetchLocationData(watch.locationId);
      }
    });
  }, [data]);

  const fetchLocationData = (locationId) => {
    const locationRef = ref(database, `locations/${locationId}/guidelines`);
    onValue(locationRef, (snapshot) => {
      if (snapshot.exists()) {
        setLocationData((prev) => ({
          ...prev,
          [locationId]: snapshot.val(),
        }));
      }
    });

    // Fetch notes
    const notesRef = ref(database, `locations/${locationId}/notes`);
    onValue(notesRef, (snapshot) => {
      let notesList = [];
      snapshot.forEach((childSnapshot) => {
        notesList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      // Store the latest 3 notes
      setLocationData((prev) => ({
        ...prev,
        [locationId]: {
          ...prev[locationId],
          latestNotes: notesList.slice(-3).reverse(),
        },
      }));
    });
  };

  const fetchCompanyData = (companyId) => {
    const companyRef = ref(database, `Companies/${companyId}`);
    onValue(companyRef, (snapshot) => {
      if (snapshot.exists()) {
        const companyInfo = snapshot.val();
        setCompanyData((prev) => ({
          ...prev,
          [companyId]: {
            ...companyInfo,
            companyImage: companyInfo.companyImage || null,
          },
        }));
      }
    });
  };

  const handleSendNote = async (watch) => {
    if (!note.trim()) {
      alert("Please enter a note.");
      return;
    }

    try {
      const { coords } = await getUserLocation(); // Get user location

      const noteRef = ref(database, `locations/${watch.locationId}/notes`);
      await push(noteRef, {
        note,
        timestamp: new Date().toISOString(),
        createdBy: currentUser.email,
        userLatitude: coords.latitude,
        userLongitude: coords.longitude,
      });

      setNote(""); // Clear the note input after sending
      alert("Note added successfully!");
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  };

  // const fetchCompanyData = (companyId) => {
  //   const companyRef = ref(database, companies/${companyId});
  //   console.log(companyId)
  //   onValue(companyRef, (snapshot) => {
  //     if (snapshot.exists()) {
  //       const companyInfo = snapshot.val();
  //       setCompanyData((prev) => ({
  //         ...prev,
  //         [companyId]: companyInfo,
  //       }));

  //       // Set the company image if available
  //       if (companyInfo.companyImage) {
  //         setCompanyImage(companyInfo.companyImage);
  //       }
  //     }
  //   });
  // };

  useEffect(() => {
    if (selectedWatchId) {
      const selectedWatch = data.find((watch) => watch.id === selectedWatchId);
      if (selectedWatch) {
        setSelectedCoords([
          selectedWatch.watchLatitude,
          selectedWatch.watchLongitude,
        ]);
      }
    }
  }, [selectedWatchId, data]);

  const handleWatchClick = (watchId) => {
    navigate(`/watches/${watchId}`);
  };

  const mapIcon = L.icon({
    iconUrl: mapIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const warningIcon = L.icon({
    iconUrl: warningIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const getIcon = (alarmCode) => {
    const iconUrl =
      {
        C: cancelledIconUrl, // Cancelled/Default
        G: greenIconUrl, // Green
        O: orangeWatchIconUrl, // Orange
        R: redWatchIconUrl, // Red
      }[alarmCode] || ""; // Default to empty string if alarmCode doesn't match

    return L.icon({
      iconUrl,
      iconSize: [45, 45], // Adjust icon size if necessary
      iconAnchor: [22.5, 45], // Adjust anchor if necessary
      popupAnchor: [0, -45], // Adjust popup anchor if necessary
    });
  };

  const getZIndexOffset = (alarmCode) => {
    return (
      {
        C: 0, // Cancelled/Default
        G: 10, // Green
        O: 20, // Orange
        R: 40, // Red
      }[alarmCode] || 0
    ); // Default to 0 if alarmCode doesn't match
  };

  return (
    <div className="chart">
      <h3 style={{ fontWeight: "400" }}>Watch Locations</h3>
      <MapContainer
        center={[0, 0]}
        zoom={2}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {data.map((watch) => {
          const icon = getIcon(watch.alarmCode || "C");
          const zIndexOffset = getZIndexOffset(watch.alarmCode || "C");

          const locationGuidelines = locationData[watch.locationId] || {};

          // Fetch company data if it hasn't been fetched yet
          if (watch.companyId && !companyData[watch.companyId]) {
            fetchCompanyData(watch.companyId);
          }

          const companyInfo = companyData[watch.companyId] || {};
          const companyImage = companyInfo.companyImage || null;

          // Determine which instructions to display based on alarmCode
          let instructions = locationGuidelines.instructions || "N/A";
          if (watch.alarmCode === "R")
            instructions = locationGuidelines.instructionsR || "N/A";
          if (watch.alarmCode === "G")
            instructions = locationGuidelines.instructionsG || "N/A";
          if (watch.alarmCode === "O")
            instructions = locationGuidelines.instructionsO || "N/A";

          return (
            <Marker
              key={watch.id}
              position={[watch.watchLatitude || "", watch.watchLongitude || ""]}
              icon={icon}
              zIndexOffset={zIndexOffset}
            >
              <Popup>
                {companyImage && (
                  <div className="company-image-container">
                    <img
                      src={companyImage}
                      alt="Company Logo"
                      style={{
                        width: "100px",
                        height: "auto",
                        margin: "0 auto",
                        display: "block",
                      }}
                    />
                  </div>
                )}
                <b>{watch.displayName}</b> <br />
                WatchId:{" "}
                <a
                  onClick={() => handleWatchClick(watch.id)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {watch.id}
                </a>{" "}
                <br />
                {watch.phoneNumber && (
                  <div>
                    Phone Number: +{watch.phoneNumber} <br />
                  </div>
                )}
                Alarm Code: {watch.alarmCode} <br />
                Company: {watch.companyName} <br />
                Location: {watch.locationName} <br />
                Coordinates: {watch.watchLatitude},{watch.watchLongitude} <br />
                <br />
                <br />
                <b>Response Required:</b> <br />
                {instructions} <br />
                <br />
                Police Number: {locationGuidelines.policeNumber || "N/A"} <br />
                Ambulance Number: {locationGuidelines.ambulanceNumber ||
                  "N/A"}{" "}
                <br />
                <br />
                <textarea
                  style={{
                    width: "100%",
                  }}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Add a note"
                ></textarea>
                <button
                  style={{
                    background: "black",
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={() => handleSendNote(watch)}
                >
                  Send Note
                </button>
                <br />
                <br />
                <b>Recent Notes:</b> <br />
                {locationGuidelines.latestNotes &&
                locationGuidelines.latestNotes.length > 0 ? (
                  <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                    {" "}
                    {/* Set fixed height and scroll */}
                    {locationGuidelines.latestNotes.map((noteItem) => (
                      <div
                        key={noteItem.id}
                        style={{
                          padding: "5px",
                          border: "0.5px solid black",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: "none",
                        }}
                      >
                        <div>Note: {noteItem.note}</div>
                        <div>By: {noteItem.createdBy}</div>
                        <div>
                          Date: {new Date(noteItem.timestamp).toLocaleString()}
                        </div>
                        Location:{" "}
                        <a
                          href="#"
                          onClick={() =>
                            openModalWithCoords(
                              noteItem.userLatitude,
                              noteItem.userLongitude
                            )
                          }
                        >
                          {noteItem.userLatitude}, {noteItem.userLongitude}
                        </a>
                        <br />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No notes available</div>
                )}
              </Popup>
            </Marker>
          );
        })}
        <ChangeMapView coords={selectedCoords} bounds={bounds} />
      </MapContainer>

      {/* Modal for displaying the map */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Map Modal"
        style={{
          overlay: {
            zIndex: 1000, // Set a high z-index for the overlay
            backgroundColor: "rgba(0, 0, 0, 0.75)", // Darken the background
          },
          content: {
            width: "600px",
            height: "400px",
            margin: "auto",
            padding: "0",
            border: "none",
            borderRadius: "10px",
          },
        }}
      >
        {/* Close button for modal */}
        <button
          onClick={closeModal}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "black",
            color: "white",
            border: "none",
            padding: "5px 10px",
            cursor: "pointer",
          }}
        >
          Close
        </button>

        {/* Leaflet Map in Modal */}
        <MapContainer
          center={modalCoords}
          zoom={15}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={modalCoords} icon={modalMapIcon} />
        </MapContainer>
      </Modal>
    </div>
  );
};

export default Chart;



