import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import CompanyDataTable from "../../components/datatable/CompanyTable"

const CompanyList = () => {
  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <CompanyDataTable/>
      </div>
    </div>
  )
}

export default CompanyList