import React, { useState, useEffect, useCallback } from "react";
import { database } from "../../firebase";
import { ref, get, push, update } from "firebase/database";
import "./../Modals/Modal.css";

const SubscribeToPlanModal = ({
  watchId,
  onClose,
  watchCompanyId,
  watchLocationId,
  watchPlan,
  watchIsSubscribed,
  fetchWatchDetail2
}) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch the available plans from the database
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const plansRef = ref(database, "Billing/Plans");
        const snapshot = await get(plansRef);
        if (snapshot.exists()) {
          const plansList = Object.keys(snapshot.val()).map((key) => ({
            id: key,
            ...snapshot.val()[key],
          }));
          setPlans(plansList);
        } else {
          console.log("No plans found");
        }
      } catch (error) {
        console.error("Error fetching plans: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleSubscribe = async () => {
    if (selectedPlan) {
      const billingLogsRef = ref(
        database,
        `Billing/billingLogs/${watchCompanyId}`
      );

      const newLog = {
        watchId,
        subscriptionPlan: selectedPlan.subscriptionPlan,
        price: selectedPlan.price,
        description: selectedPlan.description,
        timestamp: new Date().toISOString(),
      };

      try {
        setLoading(true);
        await push(billingLogsRef, newLog);

        const watchRef = ref(database, `Watches/${watchId}`);

        const subscribedDate = new Date().toISOString();
        const subscriptionEndDate = new Date();
        subscriptionEndDate.setMonth(subscriptionEndDate.getMonth() + 1);

        const updatedWatchData = {
          planSelected: selectedPlan.subscriptionPlan,
          isSubscribed: true,
          bill: selectedPlan.price,
          subscribedDate: subscribedDate,
          subscriptionEndDate: subscriptionEndDate.toISOString(),
        };

        await update(watchRef, updatedWatchData);
        onClose();

        // Call the passed fetchWatchDetail2 function after subscription
        fetchWatchDetail2();
      } catch (error) {
        console.error("Error adding billing log or updating watch: ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const watchRef = ref(database, `Watches/${watchId}`);

      const updatedWatchData = {
        isSubscribed: false,
        planSelected: null,
        bill: null,
        subscribedDate: null,
        subscriptionEndDate: null,
      };

      await update(watchRef, updatedWatchData);
      console.log(`Cancelled subscription for Watch ${watchId}`);
      onClose();

      // Call the passed fetchWatchDetail2 function after cancelling the subscription
      fetchWatchDetail2();
    } catch (error) {
      console.error("Error canceling subscription: ", error);
    }
  };

  // Filter out the selected plan from the list of available plans
  const filteredPlans = plans.filter(
    (plan) => plan.subscriptionPlan !== watchPlan
  );

  return (
    <div className="modal">
      <div className="modalContent">
        <h2 style={{ fontWeight: "300", fontSize: "20px" }}>
          {watchIsSubscribed
            ? `Update Watch ${watchId}`
            : `Subscribe to a Plan for Watch ${watchId}`}
        </h2>

        {filteredPlans.length > 0 ? (
          <div>
            <h3 style={{ fontWeight: "300", fontSize: "16px" }}>
              Select a Plan:
            </h3>
            <ul>
              {filteredPlans.map((plan) => (
                <li
                  key={plan.id}
                  style={{
                    padding: "10px",
                    margin: "5px 0",
                    backgroundColor:
                      selectedPlan?.id === plan.id ? "#d3eafc" : "white",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelectPlan(plan)}
                >
                  <h4>{plan.subscriptionPlan}</h4>
                  <p>Price: ${plan.price}</p>
                  <p>{plan.description}</p>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>No Available Plans</p>
        )}

        <button onClick={handleSubscribe} disabled={!selectedPlan || loading}>
          {loading
            ? "Processing..."
            : watchIsSubscribed
            ? "Update Watch"
            : "Subscribe to Plan"}
        </button>

        {/* Cancel subscription button */}
        {watchIsSubscribed && !loading && (
          <button onClick={handleCancelSubscription}>
            Cancel Subscription
          </button>
        )}

        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SubscribeToPlanModal;
