import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect, useContext } from "react";
import {
  getDatabase,
  ref,
  set,
  onValue,
  serverTimestamp,
} from "firebase/database";
// import { auth } from "../../firebase"; // Ensure Firebase is correctly initialized
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  createUserWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import PhoneInput from "react-phone-input-2";
import { database } from "../../firebase";
import "react-phone-input-2/lib/style.css";
import { sendEmailVerification } from "firebase/auth"; // Add this import
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { userInputs } from "../../formSource";
import useCountryList from "react-select-country-list"; // Import country list library
import Flag from "react-world-flags"; // Import the flag component

const New = ({ inputs, title }) => {
  const [data, setData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [watches, setWatches] = useState([]);
  const [companyIdDisabled, setCompanyIdDisabled] = useState(false); // New state for disabling company dropdown
  const [selectedCompany, setSelectedCompany] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const { currentUser } = useContext(AuthContext);
  const countryOptions = useCountryList().getData();
  const isRole4Selected = useState(false);

  const auth = getAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser.role !== 1 && currentUser.role !== 2) {
      navigate("/unauthorized"); // Redirect to an unauthorized page or another appropriate route
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    // Fetch Companies
    setupRecaptcha();
    const companyRef = ref(database, "Companies");
    onValue(companyRef, (snapshot) => {
      let companyList = [];
      snapshot.forEach((childSnapshot) => {
        companyList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setCompanies(companyList);
    });

    // Fetch Locations
    const locationRef = ref(database, "locations");
    onValue(locationRef, (snapshot) => {
      let locationList = [];
      snapshot.forEach((childSnapshot) => {
        locationList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      setLocations(locationList);

      // Auto-filter locations if role is 2
      if (currentUser.role === 2) {
        const companyId = currentUser.companyId;

        // Filter locations based on the current user's companyId
        const matchedLocations = locationList.filter(
          (location) => location.companyId === companyId
        );
        setFilteredLocations(matchedLocations);
      } else if (currentUser.role === 1) {
        // // If role is not 2, set filteredLocations to all locations
        // setFilteredLocations(locationList);
        const matchedLocations = locationList.filter(
          (location) => location.companyId === selectedCompany
        );
        setFilteredLocations(matchedLocations);
      }
    });
    // Fetch Watches and filter based on user role and companyId
    const watchesRef = ref(database, "Watches");
    onValue(watchesRef, (snapshot) => {
      let watchList = [];
      snapshot.forEach((childSnapshot) => {
        const watch = { id: childSnapshot.key, ...childSnapshot.val() };

        // Ensure that both roles 1 and 2 can only see watches under their company
        if (
          (currentUser.role === 1 || // Role 1: Limited to user's company
            (currentUser.role === 2 &&
              (watch.companyId === currentUser.companyId ||
                !watch.companyId))) && // Role 2: Match company or blank companyId
          (!watch.hasOwnProperty("isActive") || watch.isActive === false) // Check if isActive is false or doesn't exist
        ) {
          watchList.push(watch);
        }
      });
      setWatches(watchList);
    });
  }, [currentUser, selectedCompany]);

  useEffect(() => {
    if (currentUser.role === 2) {
      // Auto-select current user's company and disable the dropdown for role 2
      setData((prevData) => ({
        ...prevData,
        companyId: currentUser.companyId,
        companyName: currentUser.companyName,
      }));
      setCompanyIdDisabled(true); // Disable the dropdown
    }
  }, [currentUser]);

  const handleInput = (e) => {
    const { id, value } = e.target;
    const inputConfig = userInputs.find((input) => input.id === id);

    // Check if the field has a validation rule
    if (inputConfig && inputConfig.validate) {
      setErrors((prev) => ({
        ...prev,
        [id]: inputConfig.validate(value)
          ? ""
          : "Please enter a valid password.",
      }));
    }

    // Update state based on the input field
    setData((prev) => ({ ...prev, [id]: value }));
  };

  const handleRoleChange = (e) => {
    const role = parseInt(e.target.value);
    handleInput({ target: { id: "role", value: role } });

    // Modify the `userInputs` dynamically for role 4
    userInputs.forEach((input) => {
      if (input.id === "password") {
        input.required = role !== 4; // Set required to false for role 4
      }
    });
  };

  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompany(companyId);
    const companyName = companies.find(
      (company) => company.id === companyId
    )?.companyName;
    setData((prevData) => ({ ...prevData, companyId, companyName }));

    // Filter locations based on the selected company
    const matchedLocations = locations.filter(
      (location) =>
        location.companyId === companyId ||
        selectedCompany ||
        currentUser.companyId
    );
    console.log("this is companyId", companyId);
    setFilteredLocations(matchedLocations);
  };

  console.log("this is locations", filteredLocations);

  const handleLocationChange = (e) => {
    const locationId = e.target.value;
    const locationName = filteredLocations.find(
      (location) => location.id === locationId
    )?.locationName;
    setData((prevData) => ({ ...prevData, locationId, locationName }));
  };

  // Initialize the reCAPTCHA verifier
  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible", // or 'normal' if you want it to be visible
        callback: (response) => {
          // reCAPTCHA solved - will proceed with submit function
          sendVerificationCode();
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          alert("Reponse expired, Solve reCAPTCHA again");
        },
        auth,
      }
    );
  };

  const sendVerificationCode = async () => {
    const fullPhoneNumber = `+${phoneNumber}`; // E.164 format
    const appVerifier = window.recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        fullPhoneNumber,
        appVerifier
      );
      setConfirmationResult(confirmationResult);
      alert(`Verification code sent to ${fullPhoneNumber}`);
    } catch (error) {
      console.error("Error sending verification code:", error, fullPhoneNumber);
      // alert("Failed to send verification code. Please try again.");
    }
  };

  const verifyPhoneNumber = async () => {
    try {
      await confirmationResult.confirm(verificationCode);
      setIsPhoneNumberVerified(true);
      alert("Phone number verified successfully!");
    } catch (error) {
      console.error("Error verifying phone number:", error);
      alert("Incorrect verification code. Please try again.");
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    // Skip phone number verification and password requirement for role 4
    if (data.role !== 4) {
      // Check if phone number is already verified
      if (!confirmationResult) {
        alert("Please input and verify your phone number.");
        return;
      }

      // Verify the phone number with the verification code
      try {
        await confirmationResult.confirm(verificationCode); // Check the code
        setIsPhoneNumberVerified(true);
        alert("Phone number verified successfully.");
      } catch (error) {
        console.log(error);
        alert("Invalid verification code. Please try again.");
        return; // Stop if phone number is not verified
      }

      // Ensure the password is provided for roles other than 4
      if (!data.password) {
        alert("Password is required for this role.");
        return;
      }
    }

    // After any required validations, proceed to create the user
    try {
      // If role 4, no password is provided, otherwise use the entered password
      const res = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.role === 4 ? "defaultPassword" : data.password
      );

      // Send email verification
      await sendEmailVerification(res.user);
      alert("User Created. Please verify your email to activate your account.");

      // Save user data to the Firebase database
      const userRef = ref(database, `users/${res.user.uid}`);
      await set(userRef, {
        ...data,
        phoneNumber: phoneNumber, // Skip phone number for role 4
        timeStamp: serverTimestamp(),
      });
      console.log("User added successfully with ID:", userRef.key);

      navigate("/users"); // Redirect to the /users page
    } catch (error) {
      console.log(error);
      alert("Error registering user. Please try again.");
    }
  };

  if (currentUser.role !== 1 && currentUser.role !== 2) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <p>You do not have access to this page, please contact your admin.</p>
        <br />
        <Link
          to="/"
          style={{
            textDecoration: "underline",
            color: "black",
            fontWeight: "bold",
          }}
        >
          Go Back
        </Link>
      </div>
    );
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              {/* Company Dropdown */}
              <div className="formInput">
                <label>Company *</label>
                <select
                  id="companyId"
                  onChange={(e) => {
                    if (e.target.value === "addCompany") {
                      navigate("/companies/new"); // Navigate to Add Company page
                    } else {
                      handleCompanyChange(e); // Normal company selection
                    }
                  }}
                  disabled={companyIdDisabled} // Disable dropdown if user has role 2 or 3
                  value={data.companyId || ""}
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  required
                >
                  <option value="">Select Company</option>
                  {companies.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                  {currentUser.role === 1 && (
                    <option value="addCompany">Add Company</option>
                  )}
                </select>
              </div>
              {/* Location Dropdown */}
              <div className="formInput">
                <label>Location *</label>
                <select
                  id="locationId"
                  onChange={(e) => {
                    if (e.target.value === "addLocation") {
                      navigate("/locations/new"); // Navigate to Add Location page
                    } else {
                      handleLocationChange(e); // Normal location selection
                    }
                  }}
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  required
                  disabled={!data.companyId} // Disable if no company is selected
                >
                  <option value="">
                    {data.companyId
                      ? "Select Location"
                      : "Select a company first"}{" "}
                    {/* Prompt if no company is selected */}
                  </option>
                  {filteredLocations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.locationName}
                    </option>
                  ))}
                  {(currentUser.role === 1 || currentUser.role === 2) && (
                    <option value="addLocation">Add Location</option>
                  )}
                </select>
              </div>
              {/* Role Dropdown */}
              <div className="formInput">
                <label>Role *</label>
                <select
                  id="role"
                  // onChange={(e) =>
                  //   handleInput({
                  //     target: {
                  //       id: e.target.id,
                  //       value: parseInt(e.target.value),
                  //     },
                  //   })
                  // }
                  onChange={handleRoleChange}
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  required
                >
                  <option value="">Select Role</option>
                  {currentUser.role === 1 && (
                    <option value="1">Super Admin</option>
                  )}
                  <option value="2">Company Administrator </option>
                  <option value="3">Standard User</option>
                  <option value="4">Watch User</option>
                </select>
              </div>
              {/* Render remaining inputs */}
              {userInputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    placeholder={
                      input.id === "password" && data.role === 4
                        ? "Password is not needed for Watch User role"
                        : input.placeholder
                    }
                    maxlength={input.maxLength}
                    onChange={handleInput}
                    required={
                      (input.required && input.id !== "password") ||
                      data.role !== 4
                    } // Skip required for password in role 4
                    disabled={input.id === "password" && data.role === 4} // Disable for role 4
                   
                  />
                  {input.required && errors[input.id] && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors[input.id]}
                    </span>
                  )}
                </div>
              ))}
              {/* Country Dropdown */}
              <div className="formInput">
                <label htmlFor="country">Country *</label>
                <select
                  id="country"
                  onChange={handleInput} // Use handleInput for changes
                  required
                  style={{
                    height: "30px",
                    width: "250px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  <option value="">Select a Country</option>
                  {countryOptions.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
              {/* Phone Number Input */}
              <div className="formInput1">
                <label>Phone Number *</label>
                <PhoneInput
                  country={"au"}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  required
                  maxlength={15} // or whatever limit you want
                />
                {parseInt(data.role) !== 4 && ( // Only render button if role is not 4
                  <button
                    type="button"
                    onClick={sendVerificationCode}
                    style={{
                      height: "40px",
                      width: "250px",
                      fontSize: "12px",
                    }}
                  >
                    Send Verification Code
                  </button>
                )}
              </div>

              {/* Verification Code Input */}
              {confirmationResult && (
                <div className="formInput">
                  <label>Enter Verification Code</label>
                  <input
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    maxlength={12}
                  />
                  {/* <button type="button" onClick={verifyPhoneNumber}>
                    Verify Phone Number
                  </button> */}
                </div>
              )}
              <div className="formInput">
                <label>Watch ID</label>
                <select
                  id="watchId"
                  onChange={(e) => {
                    if (e.target.value === "addWatch") {
                      navigate("/watches/new"); // Navigate to Add Watch page
                    } else {
                      handleInput(e); // Normal watch selection
                    }
                  }}
                  style={{
                    height: "30px",
                    width: "100%",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                  disabled={!data.companyId || !data.locationId} // Disable if no company or location is selected
                >
                  <option value="">Select Watch</option>
                  {watches.map((watch) => (
                    <option key={watch.id} value={watch.id}>
                      {watch.id} {/* Assuming watches have an id field */}
                    </option>
                  ))}
                  {(currentUser.role === 1 || currentUser.role === 2) && (
                    <option value="addWatch">Add Watch</option>
                  )}
                </select>
              </div>
              <span
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "50px",
                  color: "gray",
                  fontSize: "10px",
                }}
              >
                Please Fill all the required fields (*)
              </span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "50px",
                }}
              >
                <button type="submit">Register User</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Add an element for reCAPTCHA */}
      <div id="recaptcha-container"></div>
    </div>
  );
};

export default New;
