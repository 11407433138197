import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { locationColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { ref, onValue, remove } from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import {
  Box,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const LocationDataTable = () => {
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]); // State to store companies
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(""); // State for selected company
  const { currentUser } = useContext(AuthContext);

  // Fetch locations
  useEffect(() => {
    const locationRef = ref(database, "locations");

    const unsub = onValue(locationRef, (snapshot) => {
      const list = [];
      snapshot.forEach((childSnapshot) => {
        const locationData = { id: childSnapshot.key, ...childSnapshot.val() };

        // Filter locations based on user role and companyId
        if (
          currentUser.role === 1 ||
          locationData.companyId === currentUser.companyId
        ) {
          list.push(locationData);
        }
      });
      setData(list);
    });

    return () => {
      unsub();
    };
  }, [currentUser]);

  // Fetch companies if role is 1
  useEffect(() => {
    if (currentUser.role === 1) {
      const companyRef = ref(database, "Companies");

      onValue(companyRef, (snapshot) => {
        const companyList = [];
        snapshot.forEach((childSnapshot) => {
          const companyData = { id: childSnapshot.key, ...childSnapshot.val() };
          companyList.push(companyData);
        });
        setCompanies(companyList);
      });
    }
  }, [currentUser]);

  const handleDelete = async (id) => {
    try {
      const locationRef = ref(database, `locations/${id}`);
      await remove(locationRef);
      setData(data.filter((item) => item.id !== id));
    } catch (err) {
      console.log(err);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/locations/${params.row.id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];

  // Filter data based on search query and selected company
  const filteredData = data.filter((location) => {
    const matchesSearchQuery =
      location.locationName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      location.id?.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCompany = selectedCompany
      ? location.companyId === selectedCompany
      : true; // Show all if no company is selected
    return matchesSearchQuery && matchesCompany;
  });

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Location
        {(currentUser.role === 1 || currentUser.role === 2) && (
          <Link to="/locations/new" className="link">
            Add New
          </Link>
        )}
      </div>
      <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
        {currentUser.role === 1 && (
          <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel>Company</InputLabel>
            <Select
              label="Company"
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
            >
              <MenuItem value="">All Companies</MenuItem>
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          label="Search by ID or Location Name"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: 300 }}
        />
      </Box>
      <DataGrid
        className="datagrid"
        rows={filteredData} // Pass the filtered data to DataGrid
        columns={locationColumns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default LocationDataTable;
