import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useEffect, useState } from "react";
import { ref, onValue, off } from "firebase/database";
import { database } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";

const Featured = ({ onSelectWatch }) => {
  // Add callback prop
  const [todayCount, setTodayCount] = useState(0);
  const [weekCount, setWeekCount] = useState(0);
  const [monthCount, setMonthCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentAlarms, setCurrentAlarms] = useState([]);

  useEffect(() => {
    const logsRef = ref(database, "logs/alarm"); // Moved inside the effect
    const watchesRef = ref(database, "Watches"); // Moved inside the effect

    const fetchAlarms = () => {
      onValue(logsRef, (snapshot) => {
        if (snapshot.exists()) {
          const alarms = snapshot.val();
          const now = Date.now();
          const todayStart = new Date(now).setHours(0, 0, 0, 0);
          const weekStart = new Date(now - 7 * 24 * 60 * 60 * 1000).setHours(
            0,
            0,
            0,
            0
          );
          const monthStart = new Date(now - 30 * 24 * 60 * 60 * 1000).setHours(
            0,
            0,
            0,
            0
          );

          let todayAlarms = 0;
          let weekAlarms = 0;
          let monthAlarms = 0;
          let totalAlarms = 0;

          Object.values(alarms).forEach((alarm) => {
            const alarmCode = alarm.alarmCode;
            const timestamp = alarm.timestamp;

            if (alarmCode !== "C") {
              totalAlarms++;

              if (timestamp >= todayStart) {
                todayAlarms++;
              }
              if (timestamp >= weekStart) {
                weekAlarms++;
              }
              if (timestamp >= monthStart) {
                monthAlarms++;
              }
            }
          });

          setTodayCount(todayAlarms);
          setWeekCount(weekAlarms);
          setMonthCount(monthAlarms);
          setTotalCount(totalAlarms);
        }
      });
    };

    const fetchWatches = () => {
      onValue(watchesRef, (snapshot) => {
        if (snapshot.exists()) {
          const watches = snapshot.val();

          const filteredWatches = Object.entries(watches).filter(
            ([key, watch]) => watch.alarmCode !== "C"
          );

          const mappedAlarms = filteredWatches.map(([key, watch]) => ({
            id: key, // Use the Firebase autogenerated key as the ID
            watchCode: watch.alarmCode,
            watchOwner: watch.displayName || "Unknown Owner",
            watchLocation: watch.locationName || "Unknown Location",
          }));

          setCurrentAlarms(mappedAlarms);
        }
      });
    };

    fetchAlarms();
    fetchWatches();

    // Clean up the listeners when the component unmounts
    return () => {
      off(logsRef); // Remove listener for logs/alarm
      off(watchesRef); // Remove listener for Watches
    };
  }, []);

  const handleRowClick = (row) => {
    onSelectWatch(row.id); // Pass the selected watch ID to parent
  };

  // Define columns for the DataGrid
  const columns = [
    {
      field: "watchCode",
      headerName: "Alarm Code",
      width: 125,
      renderCell: (params) => {
        switch (params.value) {
          case "G":
            return <span className="alarm-green-text">Green</span>;
          case "R":
            return <span className="alarm-red-text">Red</span>;
          case "O":
            return <span className="alarm-orange-text">Orange</span>;
          default:
            return <span>{params.value}</span>; // Fallback to original value
        }
      },
      cellClassName: (params) => {
        switch (params.value) {
          case "G":
            return "alarm-green";
          case "R":
            return "alarm-red";
          case "O":
            return "alarm-orange";
          default:
            return "";
        }
      },
    },
    { field: "watchOwner", headerName: "Owner", width: 150 },
    { field: "watchLocation", headerName: "Location", width: 150 },
  ];

  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Alarms</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        {/* <div className="featuredChart">
          <CircularProgressbar
            value={totalCount > 0 ? (todayCount / totalCount) * 100 : 0}
            text={${todayCount} / ${totalCount}}
            strokeWidth={5}
          />
        </div> */}
        <p className="title">Alarms Triggered Today</p>
        <p className="amount">{todayCount}</p>
        <p className="desc">Compared to previous periods</p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Today</div>
            <div className="itemResult negative">
              <KeyboardArrowDownIcon fontSize="small" />
              <div className="resultAmount">{todayCount}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount">{weekCount}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount">{monthCount}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="currentAlarmsTable"
        style={{ height: 300, width: "100%" }}
      >
        <p className="title" style={{ color: "black", textAlign: "center" }}>
          Current Alarms
        </p>
        <DataGrid
          rows={currentAlarms} // Pass the data
          columns={columns} // Pass the column configuration
          pageSize={5} // Show 5 rows per page
          rowsPerPageOptions={[5, 10, 15]} // Allow pagination options
          getRowId={(row) => row.id} // Specify custom id field
          onRowClick={(params) => handleRowClick(params.row)} // Handle row click
          sx={{
            fontSize: 12, // Set text size smaller
            "& .MuiDataGrid-columnHeaders": {
              fontSize: 14, // Adjust column header font size
            },
            "& .MuiDataGrid-row": {
              fontSize: 12, // Adjust row text size
            },
            "& .MuiDataGrid-cell": {
              padding: "8px", // Adjust cell padding
            },
          }}
        />
      </div>
    </div>
  );
};

export default Featured;
