import {
  ref,
  onValue,
  remove,
  get,
  update,
  serverTimestamp,
  push,
} from "firebase/database";
import { database } from "../../firebase";
import { useEffect, useState, useMemo, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import "./datatable.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";

const RelayBoardDataTable = () => {
  const { currentUser } = useContext(AuthContext);
  const userRole = currentUser.role;
  const userCompanyId = currentUser.companyId;

  const [data, setData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query

  useEffect(() => {
    const companiesRef = ref(database, "Companies");
    const relayBoardsRef = ref(database, "RelayBoards");
    const locationsRef = ref(database, "locations");

    // Fetch companies, relay boards, and locations in parallel
    const unsubscribeCompanies = onValue(companiesRef, (snapshot) => {
      const companiesList = [];
      snapshot.forEach((childSnapshot) => {
        companiesList.push({
          id: childSnapshot.key,
          ...childSnapshot.val(),
        });
      });
      setCompanies(companiesList);
    });

    const unsubscribeRelayBoards = onValue(relayBoardsRef, (snapshot) => {
      const relayBoardsList = [];
      snapshot.forEach((boardSnapshot) => {
        const boardId = boardSnapshot.key;
        const boardData = boardSnapshot.val();
        relayBoardsList.push({
          boardId,
          ...boardData.conf, // Assuming board configuration is stored in 'conf'
        });
      });
      fetchAlertStatuses(relayBoardsList).then(setData);
    });

    const unsubscribeLocations = onValue(locationsRef, (snapshot) => {
      const locationsList = [];
      snapshot.forEach((childSnapshot) => {
        locationsList.push({
          id: childSnapshot.key,
          ...childSnapshot.val(),
        });
      });
      setLocations(locationsList);
    });

    return () => {
      // Clean up listeners on unmount
      unsubscribeCompanies();
      unsubscribeRelayBoards();
      unsubscribeLocations();
    };
  }, []);

  // Fetch alert statuses for all relay boards
  const fetchAlertStatuses = async (relayBoards) => {
    try {
      const relayBoardsWithStatus = await Promise.all(
        relayBoards.map(async (board) => {
          const statusRef = ref(
            database,
            `RelayBoards/${board.boardId}/status/alert`
          );
          const statusSnapshot = await get(statusRef);

          if (statusSnapshot.exists()) {
            const alertString = statusSnapshot.val();
            const alarmCode = alertString?.charAt(0);
            const alarmStatusMap = {
              C: "Idle",
              G: "Green",
              O: "Orange",
              R: "Red",
            };

            return {
              ...board,
              alarmStatus: alarmStatusMap[alarmCode] || "Unknown",
            };
          }
          return { ...board, alarmStatus: "Unknown" };
        })
      );
      return relayBoardsWithStatus;
    } catch (error) {
      console.error("Error fetching alert statuses:", error);
      return relayBoards;
    }
  };

  // Delete handler
  const handleDelete = async (id, cid, lid) => {
    try {
      const relayBoardRef = ref(
        database,
        `RelayBoards/${cid}/${lid}/boards/${id}`
      );
      await remove(relayBoardRef);
      setData((prevData) => prevData.filter((item) => item.id !== id)); // Remove deleted item from state
    } catch (err) {
      console.log(err);
    }
  };

  const filteredData = useMemo(() => {
    // First, apply company and location filters
    const filteredByCompanyAndLocation = data
      .filter((item) => {
        const matchesCompany =
          userRole !== 1
            ? item.co_idn === userCompanyId
            : selectedCompany
            ? item.co_idn === selectedCompany
            : true;
        const matchesLocation = selectedLocation
          ? item.lo_idn === selectedLocation
          : true;
        return matchesCompany && matchesLocation;
      })
      .map((item) => {
        const companyName =
          companies.find((company) => company.id === item.co_idn)
            ?.companyName || "Unknown";
        const locationName =
          locations.find((location) => location.id === item.lo_idn)
            ?.locationName || "Unknown";
        return {
          ...item,
          companyName,
          locationName,
        };
      });

    // Then, apply the search query filter on the resulting data
    return filteredByCompanyAndLocation.filter((item) => {
      const itemId = item.boardId || ""; // Ensure `boardId` is safely accessed
      return (
        !searchQuery || itemId.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [
    data,
    selectedCompany,
    selectedLocation,
    companies,
    locations,
    userRole,
    userCompanyId,
    searchQuery,
  ]);

  const getCommaSeparatedDigits = (str) => {
    // Ensure that we only work with digits
    if (!str || isNaN(str)) return str; // If it's not a number or is empty, return it as is
    return str.split("").join(","); // Split and join with commas
  };

  const columns = [
    { field: "boardId", headerName: "Board ID", width: 200 },
    { field: "companyName", headerName: "Company Name", width: 200 },
    { field: "locationName", headerName: "Location Name", width: 200 },
    { field: "ban", headerName: "Branch Account Number", width: 200 },
    { field: "sta_ip", headerName: "Static IP", width: 150 },
    {
      field: "grc_gc",
      headerName: "Green Group Code",
      width: 100,
      renderCell: (params) => getCommaSeparatedDigits(params.row.grc_gc), // Apply the transformation here
    },
    {
      field: "org_gc",
      headerName: "Orange Group Code",
      width: 100,
      renderCell: (params) => getCommaSeparatedDigits(params.row.org_gc), // Apply the transformation here
    },
    {
      field: "red_gc",
      headerName: "Red Group Code",
      width: 100,
      renderCell: (params) => getCommaSeparatedDigits(params.row.red_gc), // Apply the transformation here
    },
    {
      field: "alarmStatus",
      headerName: "Alarm Status",
      width: 200,
      renderCell: (params) => {
        const alarmStatus = params.row.alarmStatus || "Unknown";
        const colorMap = {
          Idle: "#939196",
          Green: "#185519",
          Orange: "#CD5C08",
          Red: "#7D0A0A",
        };
        const backgroundColor = colorMap[alarmStatus] || "gray";
        return (
          <div
            style={{
              backgroundColor,
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              textAlign: "center",
              minWidth: "100px",
            }}
          >
            {alarmStatus}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        const { boardId, companyId, locationId } = params.row;
        return (
          <div className="cellAction">
            <Link
              to={`/relayboards/${boardId}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            {/* <div
              className="deleteButton"
              onClick={() => handleDelete(boardId, companyId, locationId)}
            >
              Delete
            </div> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Relay Boards
        {(currentUser.role === 1 || currentUser.role === 2) && (
  <Link to="/relayboards/new" className="link">
    Add New
  </Link>
)}

      </div>

      {/* Filter Selects */}
      <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="company-filter-label">Company</InputLabel>
          <Select
            labelId="company-filter-label"
            value={selectedCompany}
            label="Company"
            onChange={(e) => setSelectedCompany(e.target.value)}
          >
            <MenuItem value="">
              <em>All Companies</em>
            </MenuItem>
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                {company.companyName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }} size="small">
          <InputLabel id="location-filter-label">Location</InputLabel>
          <Select
            labelId="location-filter-label"
            value={selectedLocation}
            label="Location"
            onChange={(e) => setSelectedLocation(e.target.value)}
            disabled={!selectedCompany}
          >
            <MenuItem value="">
              <em>All Locations</em>
            </MenuItem>
            {locations
              .filter(
                (location) =>
                  !selectedCompany || location.companyId === selectedCompany
              )
              .map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.locationName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label="Search by ID"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: 300 }}
        />
      </Box>

      <DataGrid
        className="datagrid"
        rows={filteredData.map((item, index) => ({ id: index + 1, ...item }))}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default RelayBoardDataTable;
