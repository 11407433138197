import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, Snackbar, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ref, onValue, update } from "firebase/database";
import "../../components/datatable/datatable.scss";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const NewWatchCompany = () => {
  const [watches, setWatches] = useState([]);
  const [filteredWatches, setFilteredWatches] = useState([]);
  const [selectedWatches, setSelectedWatches] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [watchSearch, setWatchSearch] = useState(""); // State for watch search
  const [companySearch, setCompanySearch] = useState(""); // State for company search

  const { currentUser } = useContext(AuthContext);

  const columns = [{ field: "id", headerName: "Watch ID", width: 500 }];

  useEffect(() => {
    const fetchWatches = async () => {
      const watchesRef = ref(database, "Watches");
      onValue(watchesRef, (snapshot) => {
        const allWatches = [];
        snapshot.forEach((childSnapshot) => {
          const watchData = childSnapshot.val();
          allWatches.push({ id: childSnapshot.key, ...watchData });
        });
        const filteredWatches = allWatches.filter((watch) => !watch.companyId);
        setWatches(filteredWatches);
        setFilteredWatches(filteredWatches); // Set initial filtered watches
      });
    };

    const fetchCompanies = async () => {
      const companiesRef = ref(database, "Companies");
      onValue(companiesRef, (snapshot) => {
        const allCompanies = [];
        snapshot.forEach((childSnapshot) => {
          const companyData = childSnapshot.val();
          allCompanies.push({ id: childSnapshot.key, ...companyData });
        });
        setCompanies(allCompanies);
        setFilteredCompanies(allCompanies); // Set initial filtered companies
      });
    };

    fetchWatches();
    fetchCompanies();
  }, [database]);

  useEffect(() => {
    // Filter watches based on search input
    const results = watches.filter((watch) =>
      watch.id
        ? watch.id.toLowerCase().includes(watchSearch.toLowerCase())
        : false
    );
    setFilteredWatches(results);
  }, [watchSearch, watches]);

  useEffect(() => {
    // Filter companies based on search input
    const results = companies.filter((company) =>
      company.companyName
        ? company.companyName
            .toLowerCase()
            .includes(companySearch.toLowerCase())
        : false
    );
    setFilteredCompanies(results);
  }, [companySearch, companies]);

  const handleAssignClick = () => {
    setOpenModal(true);
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
  };

  const confirmAssignment = async () => {
    const updates = {};
    selectedWatches.forEach((id) => {
      updates[`Watches/${id}/companyId`] = selectedCompany.id;
      updates[`Watches/${id}/companyName`] = selectedCompany.companyName;
    });

    try {
      await update(ref(database), updates);
      setSnackbarMessage(
        `Assigned ${selectedWatches.length} watch(es) to ${selectedCompany.companyName}`
      );
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating watches: ", error);
      setSnackbarMessage("Failed to assign watches. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setOpenModal(false);
      setSelectedWatches([]);
      setSelectedCompany(null);
    }
  };

  if (currentUser.role !== 1) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <p>You do not have access to this page, please contact your admin.</p>
        <br />
        <Link
          to="/"
          style={{
            textDecoration: "underline",
            color: "black",
            fontWeight: "bold",
          }}
        >
          Go Back
        </Link>
      </div>
    );
  }

  return (
    <div>
      <div className="datatable">
        <div className="datatableTitle">
          <div>
            <div
              style={{
                marginBottom: "15px",
              }}
            >
              Assign Watches to a Company
            </div>

            <TextField
              variant="outlined"
              placeholder="Search Watches by ID"
              value={watchSearch}
              onChange={(e) => setWatchSearch(e.target.value)} // Update search input
              style={{ marginBottom: "10px", width: "300px" }}
              InputProps={{
                style: {
                  height: "35px", // Adjust height to your preference
                },
              }}
            />
          </div>

          <Button
            onClick={handleAssignClick}
            disabled={selectedWatches.length === 0}
          >
            Assign Watches
          </Button>
        </div>
        <DataGrid
          className="datagrid"
          rows={filteredWatches} // Use filtered watches
          columns={columns}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
          onSelectionModelChange={(newSelection) => {
            setSelectedWatches(newSelection);
          }}
        />
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div
          style={{
            padding: 20,
            backgroundColor: "white",
            borderRadius: 8,
            margin: "auto",
            maxWidth: 400,
            width: "80%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            className="datatableTitle"
            style={{
              display: "flex",
              alignItems: "center", // Align items vertically center
              borderBottom: "1px solid gray",
              padding: "5px",
              color: "black",
              marginBottom: "5px",
            }}
          >
            <span style={{ marginRight: "10px" }}>Select Company</span>
            <TextField
              variant="outlined"
              placeholder="Search Companies by Name"
              value={companySearch}
              onChange={(e) => setCompanySearch(e.target.value)} // Update search input
              style={{ width: "100%" }} // Adjust width to suit layout
              InputProps={{
                style: {
                  height: "35px", // Adjust height to your preference
                },
              }}
            />
          </div>

          <div
            style={{
              maxHeight: "200px",
              overflowY: "auto",
              scrollbarWidth: "thin",
            }}
          >
            {filteredCompanies.map((company) => (
              <div key={company.id}>
                <Button
                  onClick={() => handleCompanySelect(company)}
                  style={{
                    color: "black",
                    backgroundColor:
                      selectedCompany && selectedCompany.id === company.id
                        ? "darkgray"
                        : "transparent",
                    width: "100%",
                  }}
                  fullWidth
                >
                  {company.companyName}
                </Button>
              </div>
            ))}
          </div>
          <Button
            onClick={confirmAssignment}
            disabled={!selectedCompany}
            style={{
              border: "1px solid black",
              borderRadius: "5px",
              width: "100%",
              marginTop: "15px",
            }}
          >
            Assign watch/es to the selected company
          </Button>
        </div>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

export default NewWatchCompany;
