import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useContext, useEffect, useState } from "react";
import { ref, get, update, onValue, remove } from "firebase/database"; // Import from firebase/database
import { database } from "../../firebase";
import CompanyWatches from "../../components/table/CompanyWatchesTable";
import CompanyWatchesMap from "../../components/chart/CompanyWatchesMap";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import useCountryList from "react-select-country-list"; // Import country list library

import { Visibility, VisibilityOff } from "@mui/icons-material";

const Single = () => {
  const [userData, setUserData] = useState(null);
  const [userId, setUserId] = useState(null);
  const [editData, setEditData] = useState({
    displayName: "",
    email: "",
    password: "", // Handle password securely
    address: "",
    country: "",
    phoneNumber: "",
    watchId: "",
    role: "", // Add role field
    companyId: "",
    companyName: "",
    locationId: "",
    locationName: "",
    postCode: "",
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [locations, setLocations] = useState([]); // New state for locations
  const [filteredLocations, setFilteredLocations] = useState([]); // New state for filtered locations
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const countryOptions = useCountryList().getData();
  const [errors, setErrors] = useState("");

  // Toggle modal visibility
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  // Handle user deletion
  const handleDeleteUser = async () => {
    try {
      // Remove user from 'users' database path
      await remove(ref(database, `users/${userId}`));

      // Find watches that have this userId and clear the userId field
      const watchesRef = ref(database, "Watches");
      const snapshot = await get(watchesRef);
      if (snapshot.exists()) {
        const updates = {};
        snapshot.forEach((childSnapshot) => {
          const watch = childSnapshot.val();
          if (watch.userId === userId) {
            updates[`Watches/${childSnapshot.key}/userId`] = null; // Clear userId
          }
        });
        if (Object.keys(updates).length > 0) {
          await update(ref(database), updates); // Apply updates
        }
      }

      alert("User and associated watch links deleted successfully.");
      setShowDeleteModal(false); // Close modal
      navigate("/users"); // Redirect to users list
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while deleting the user.");
    }
  };

  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize navigate function
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const path = window.location.pathname;
        const id = path.split("/").pop(); // Extract userId from URL path
        setUserId(id);
        const userRef = ref(database, `users/${id}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          const data = snapshot.val();

          // Role-based restrictions
          if (currentUser.role === 3 && currentUser.uid !== id) {
            alert(
              "You do not have access to this page please contact your admin"
            );
            navigate("/users"); // Redirect to /users after alert
            return;
          }

          if (
            currentUser.role === 2 &&
            currentUser.companyId !== data.companyId
          ) {
            alert(
              "You do not have access to this page please contact your admin"
            );
            navigate("/users"); // Redirect to /users after alert
            return;
          }

          setUserData(data);
          setEditData({
            displayName: data.displayName || "",
            email: data.email || "",
            password: data.password || "",
            address: data.address || "",
            country: data.country || "",
            phoneNumber: data.phoneNumber || "",
            watchId: data.watchId || "",
            role: data.role || "",
            companyId: data.companyId || "",
            companyName: data.companyName || "",
            locationId: data.locationId || "",
            locationName: data.locationName || "",
            postCode: data.postCode || "",
          });

          // Set selected company to the user's companyId
          setSelectedCompany(data.companyId);

          // Auto-select location based on company
          const selectedCompanyId = data.companyId;
          const locationsForSelectedCompany = locations.filter(
            (location) => location.companyId === selectedCompanyId
          );

          const defaultLocation = locationsForSelectedCompany.find(
            (location) => location.locationId === data.locationId
          );

          if (defaultLocation) {
            setEditData((prev) => ({
              ...prev,
              locationId: defaultLocation.locationId,
              locationName: defaultLocation.locationName,
            }));
          }

          console.log(userData);
        } else {
          console.log("No user data available");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [currentUser, locations]); // Watch for changes in currentUser and locations

  useEffect(() => {
    if (currentUser && currentUser.role === 1) {
      const companiesRef = ref(database, "Companies");
      onValue(companiesRef, (snapshot) => {
        const companiesData = snapshot.val();
        const companyList = companiesData
          ? Object.keys(companiesData).map((key) => ({
              companyId: key,
              companyName: companiesData[key].companyName,
            }))
          : [];
        setCompanies(companyList);
      });
    }
  }, [currentUser]);

  // Fetch locations and filter based on companyId
  useEffect(() => {
    const fetchLocations = async () => {
      const locationsRef = ref(database, "locations");
      onValue(locationsRef, (snapshot) => {
        const locationsData = snapshot.val();
        const locationList = locationsData
          ? Object.keys(locationsData).map((key) => ({
              locationId: key,
              ...locationsData[key],
            }))
          : [];
        setLocations(locationList);
      });
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    // Filter locations based on the companyId
    const selectedCompanyId =
      currentUser?.role === 1 ? selectedCompany : editData.companyId;
    setFilteredLocations(
      locations.filter((location) => location.companyId === selectedCompanyId)
    );
  }, [selectedCompany, editData.companyId, locations, currentUser]);

  console.log("locations", locations);
  console.log("companies", companies);

  const handleCompanyChange = (e) => {
    const value = e.target.value;
    setSelectedCompany(value); // Set selected company to filter locations
    setEditData((prev) => ({
      ...prev,
      companyId: value, // Update companyId in form values
      companyName:
        companies.find((c) => c.companyId === value)?.companyName || "",
    }));
  };

  const handleLocationChange = (e) => {
    const locationId = e.target.value;
    const location = filteredLocations.find(
      (loc) => loc.locationId === locationId
    );
    setEditData((prevValues) => ({
      ...prevValues,
      locationName: location ? location.locationName : "",
      locationId: locationId,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the field is the password, validate it
    if (name === "password") {
      const passwordValid =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/.test(
          value
        );
      if (!passwordValid) {
        // You can set an error message or handle the invalid password state here
        console.log(
          "Password must be 8-20 characters, with an uppercase letter, a number, and a special character."
        );
        setErrors(
          "Password must be 8-20 characters, with an uppercase letter, a number, and a special character."
        );
      } else {
        setErrors("");
      }
    }

    setEditData({
      ...editData,
      [name]: name === "role" ? parseInt(value) : value,
    });
  };

  const handleUpdate = async () => {
    try {
      // Update user details in the database
      await update(ref(database, `users/${userId}`), editData);
  
      // Update the displayName in the "Watches" section based on watchId
      if (editData.watchId) {
        await update(ref(database, `Watches/${editData.watchId}`), {
          displayName: editData.displayName,
          userId: userId
        });
      }
  
      setUserData(editData); // Update local state
      alert("User details and associated watch updated successfully");
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };
  

  const getRoleString = (role) => {
    switch (role) {
      case 1:
        return "Watch Admin";
      case 2:
        return "Company Admin";
      case 3:
        return "User";
      case 4:
        return "Watch User";
      default:
        return "Unknown Role"; // Fallback for undefined roles
    }
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  if (!userData) return <div>Loading...</div>;

  const isRole3 =
    currentUser.role === 3 ||
    currentUser.role === undefined ||
    currentUser.role === null ||
    currentUser.role === "";

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">User Information</h1>
            <div className="item">
              <div className="details">
                <h1 className="itemTitle">{userData.displayName}</h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{userData.email}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{userData.address}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Postal Code:</span>
                  <span className="itemValue">{userData.postCode}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country:</span>
                  <span className="itemValue">{userData.country}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone:</span>
                  <span className="itemValue">{userData.phoneNumber}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Watch Id:</span>
                  <span className="itemValue">{userData.watchId}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Role:</span>
                  <span className="itemValue">
                    {getRoleString(userData.role)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div
            className="editSection"
            style={{
              position: "relative",
              maxHeight: "400px", // Set a fixed height (adjust as necessary)
              overflowY: "auto", // Enable vertical scrolling
              border: "1px solid #ccc", // Optional: Add border for aesthetics
              padding: "20px", // Optional: Add padding for aesthetics
              backgroundColor: "#f9f9f9", // Optional: Change background color
            }}
          >
            <h1 className="title">Edit User Details</h1>
            <div className="editForm">
              {currentUser.role === 1 && (
                <label>
                  Company:
                  <select
                    value={editData.companyId} // Set default value to formValues.companyId
                    onChange={handleCompanyChange}
                    required
                    disabled={currentUser.role !== 1} // Disable dropdown if user role is not 1
                    style={{
                      height: "40px",
                      border: "1px solid gray",
                    }}
                  >
                    <option value="">Remove User from this Company</option>{" "}
                    {/* Clickable but not valid */}
                    {/* Dissociate option */}
                    {companies.map((company) => (
                      <option key={company.companyId} value={company.companyId}>
                        {company.companyName}
                      </option>
                    ))}
                  </select>
                </label>
              )}

              <label>
                Location:
                <select
                  value={editData.locationId}
                  onChange={handleLocationChange}
                  style={{
                    height: "40px",
                    border: "1px solid gray",
                  }}
                  disabled={isRole3 || editData.companyId === ""} // Disable if role is 3 or company is removed
                >
                  <option value="">
                    {editData.companyId === ""
                      ? "Select Location"
                      : "Remove User from this Location"}
                  </option>

                  {filteredLocations.map((location) => (
                    <option
                      key={location.locationId}
                      value={location.locationId}
                    >
                      {location.locationName}
                    </option>
                  ))}
                </select>
              </label>

              <label>
                Name:
                <input
                  type="text"
                  name="displayName"
                  value={editData.displayName}
                  onChange={handleInputChange}
                  required
                  disabled={isRole3} // Disable for role 3
                  maxLength={40}
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={editData.email}
                  onChange={handleInputChange}
                  required
                  disabled={isRole3} // Disable for role 3
                  maxLength={40}
                />
              </label>
              <label>
                Password:
                <div style={{ position: "relative", display: "inline-block" }}>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    value={editData.password}
                    onChange={handleInputChange}
                    required
                    disabled={isRole3} // Disable for role 3
                    style={{ paddingRight: "30px" }} // Add padding for the icon
                    maxLength={40}
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      left: "175px",
                      top: "60%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    {isPasswordVisible ? (
                      <VisibilityOff style={{ fontSize: "20px" }} />
                    ) : (
                      <Visibility style={{ fontSize: "20px" }} />
                    )}
                  </span>
                  {errors && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {"  "} {errors}
                    </span>
                  )}
                </div>
              </label>
              <label>
                Address:
                <input
                  type="text"
                  name="address"
                  value={editData.address}
                  onChange={handleInputChange}
                  required
                  disabled={isRole3} // Disable for role 3
                  maxLength={60}
                />
              </label>
              <label>
                Postal Code:
                <input
                  type="text"
                  name="postCode"
                  value={editData.postCode}
                  onChange={handleInputChange}
                  required
                  disabled={isRole3} // Disable for role 3
                  maxLength={14}
                />
              </label>
              <label>
                Country:
                <select
                  value={editData.country}
                  onChange={(e) => handleInputChange(e)} // Update the country when selected
                  name="country"
                  style={{
                    height: "40px",
                    border: "1px solid gray",
                  }}
                >
                  <option value="" disabled>
                    Select Country
                  </option>
                  {countryOptions.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </label>

              <label>
                Phone Number:
                <input
                  type="num"
                  name="phoneNumber"
                  value={editData.phoneNumber}
                  onChange={handleInputChange}
                  required
                  disabled={isRole3} // Disable for role 3
                  maxLength={20}
                />
              </label>

              <label>
                Watch Id:
                <input
                  type="text"
                  name="watchId"
                  value={editData.watchId}
                  onChange={handleInputChange}
                  disabled={currentUser.role !== 1} // Disable if role is not 1
                  hidden={currentUser.role !==1}
                />
              </label>

              <label>
                Role:
                <select
                  name="role"
                  value={editData.role}
                  onChange={handleInputChange}
                  required
                  style={{
                    height: "40px",
                  }}
                  disabled={currentUser.role === 3} // Disable if role is 3
                >
                  <option value="">Select Role</option>
                  {currentUser.role === 1 && (
                    <option value="1">Super Admin</option>
                  )}{" "}
                  {/* Only show "Watch Admin" for role 1 */}
                  <option value="2">Company Administrator</option>
                  <option value="3">Standard User</option>
                  <option value="4">Watch User</option>
                </select>
              </label>

              <button onClick={handleUpdate}>Update User</button>
              <button
                style={{
                  color: "red",
                }}
                onClick={handleDeleteClick}
              >
                Delete User
              </button>
            </div>
            {showDeleteModal && (
              <div className="deleteModal">
                <div className="deleteModalContent">
                  <p>Are you sure you want to delete this user?</p>
                  <p
                    style={{
                      color: "gray",
                      fontSize: "12px",
                    }}
                  >
                    Deleting this user will remove the user in its associated
                    watch
                  </p>
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    Please be aware: Deleting a user is irreversible and cannot
                    be undone.{" "}
                  </p>
                  <div className="">
                    <button
                      className="cancel"
                      onClick={() => {
                        handleDeleteUser();
                        alert("User Deleted");
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="confirm"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
            <style jsx>{`
              .deleteModal {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1000;
              }

              .deleteModalContent {
                background-color: white;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
                width: 300px;
                text-align: center;
              }

              .deleteModal p {
                font-size: 18px;
                margin-bottom: 20px;
                color: #333; /* Dark text for better readability */
              }

              .deleteModal button {
                margin: 5px;
                padding: 10px 20px;
                font-size: 16px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
              }

              .deleteModal button.confirm {
                background-color: #e74c3c;
                color: white;
              }

              .deleteModal button.cancel {
                background-color: #95a5a6;
                color: white;
              }

              .deleteModalButtons {
                display: flex;
                justify-content: space-between;
              }
            `}</style>
          </div>
        </div>
        {/* Add additional components as needed, e.g., CompanyWatches or CompanyWatchesMap */}
      </div>
    </div>
  );
};

export default Single;
